// @flow weak
import * as actionConstants from '../actionConstants';

// Reducer
const DEFAULT_STATE = {
  user: {},
  loadingUser: false,
  loadedUser: false,
  feedbackForms: [],
};

export default function (state = DEFAULT_STATE, action) {
  switch (action.type) {
    case actionConstants.LOADING_USER:
      return {
        ...state, loadingUser: true, loadedUser: false
      };
    case actionConstants.LOADED_USER:
      return {
        ...state, loadingUser: false, loadedUser: true, user: action.payload
      };
    case actionConstants.GOT_FEEDBACK_FORMS_BY_USER:
      return {
        ...state, feedbackForms: action.payload
      };
    default:
      return state;
  }
}

// Selectors (mapStateToP rops)
export const getUser = ({
  user, loadingUser, loadedUser, feedbackForms,
}) => ({
  user,
  loadingUser,
  loadedUser,
  feedbackForms,
});
