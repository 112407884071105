export const LOADING_FEEDBACK = 'LOADING_FEEDBACK';
export const LOADED_FEEDBACK = 'LOADED_FEEDBACK';
export const LOADING_FEEDBACK_FAILED = 'LOADING_FEEDBACK_FAILED';
export const UPDATING_FEEDBACK = 'UPDATING_FEEDBACK';
export const UPDATED_FEEDBACK = 'UPDATED_FEEDBACK';
export const GET_LATEST_FEEDBACK_ID = 'GET_LATEST_FEEDBACK_ID';
export const GET_FEEDBACK_PROPERTY = 'GET_FEEDBACK_PROPERTY';

export const LOADING_SHEET = 'LOADING_SHEET';
export const LOADED_SHEET = 'LOADED_SHEET';

export const GENERATING_SMS = 'GENERATING_SMS';
export const GENERATED_SMS = 'GENERATED_SMS';

export const LOADING_PROPERTIES_BY_USER = 'LOADING_PROPERTIES_BY_USER';
export const LOADED_PROPERTIES_BY_USER = 'LOADED_PROPERTIES_BY_USER';
export const LOADING_TEAM_PROPERTIES_BY_USER = 'LOADING_TEAM_PROPERTIES_BY_USER';
export const LOADED_TEAM_PROPERTIES_BY_USER = 'LOADED_TEAM_PROPERTIES_BY_USER';
export const UPDATE_TEAM_AND_COMPANY = 'UPDATE_TEAM_AND_COMPANY';

export const LOADING_USER = 'LOADING_USER';
export const LOADED_USER = 'LOADED_USER';
export const GOT_FEEDBACK_FORMS_BY_USER = 'GOT_FEEDBACK_FORMS_BY_USER';
export const SETTING_AUTH = 'SETTING_AUTH';
export const SET_AUTH = 'SET_AUTH';

export const LOADING_VIEWINGS_BY_PROPERTY = 'LOADING_VIEWINGS_BY_PROPERTY';
export const LOADED_VIEWINGS_BY_PROPERTY = 'LOADED_VIEWINGS_BY_PROPERTY';

export const ADDING_VIEWING_REQUEST = 'ADDING_VIEWING_REQUEST';
export const ADDED_VIEWING_REQUEST = 'ADDED_VIEWING_REQUEST';
export const ADDING_QUALIFICATION_FEEDBACK = 'ADDING_QUALIFICATION_FEEDBACK';
export const ADDED_QUALIFICATION_FEEDBACK = 'ADDED_QUALIFICATION_FEEDBACK';

export const LOADING_STATS = 'LOADING_STATS';
export const LOADED_STATS = 'LOADED_STATS';

export const GETTING_PROPERTY_MEDIA = 'GETTING_PROPERTY_MEDIA';
export const GOT_PROPERTY_MEDIA = 'GOT_PROPERTY_MEDIA';

export const LOADING_SHOWINGS = 'LOADING_SHOWINGS';
export const LOADED_SHOWINGS = 'LOADED_SHOWINGS';
export const LOADING_SHOWINGS_FAILED = 'LOADING_SHOWINGS_FAILED';

export const LOADING_PORTAL_USER = 'LOADING_PORTAL_USER';
export const LOADED_PORTAL_USER = 'LOADED_PORTAL_USER';
export const LOADING_PORTAL_USER_FAILED = 'LOADING_PORTAL_USER_FAILED';

export const LOADING_TOUR = 'LOADING_TOUR';
export const LOADED_TOUR = 'LOADED_TOUR';

export const LOADED_AGENT = 'LOADED_AGENT';

