import firebase from 'firebase';
import * as actionConstants from '../actionConstants';
import {getE164} from "../tools";

/**
 * Load all users stats
 *
 * @returns {function(*, *)}
 */
export const getStats = () => dispatch => {
  dispatch({ type: actionConstants.LOADING_STATS });
  firebase
    .firestore()
    .collection('users')
    .get()
    .then(async querySnapshot => {
      let users = [];
      const promises = querySnapshot.docs.map(async doc => {
        let user = {id: doc.id, ...doc.data()};
        let properties = await firebase.firestore().collection('users').doc(doc.id).collection('properties').get()
          .then(querySnapshot => querySnapshot.size);
        let forms = await firebase.firestore().collection('users').doc(doc.id).collection('feedbackForms').get()
          .then(querySnapshot => querySnapshot.size);
        let {sent, received} = await firebase.firestore().collection('users').doc(doc.id).collection('feedback').get()
          .then(querySnapshot => {
            const sent = querySnapshot.size;
            let received = 0;
            querySnapshot.forEach(doc => {
              if (doc.data() && doc.data().answers) {
                received += 1;
              }
            });
            return { sent, received };
          });
        let contacts = await firebase.firestore().collection('users').doc(doc.id).collection('contacts').get()
          .then(querySnapshot => querySnapshot.size);
        let viewings = await firebase.firestore().collection('users').doc(doc.id).collection('viewings').get()
          .then(querySnapshot => querySnapshot.size);
        user.properties = properties;
        user.forms = forms;
        user.feedbackSent = sent;
        user.feedbackReceived = received;
        user.contacts = contacts;
        user.viewings = viewings;
        users.push(user);
      });
      Promise.all(promises)
        .then(() => {
          dispatch({type: actionConstants.LOADED_STATS, payload: users});
        })
    })
};

/**
 * Load all users data stats
 *
 * @returns {function(*, *)}
 */
export const getDataStats = () => async dispatch => {
  let avatars = 0;
  let colorChange = 0;
  let addProperty = 0;
  let promises = [];
  let propDesc = 0;
  let propId = 0;
  let propOwner = 0;
  let propInstr = 0;
  let propViews = 0;
  let propSettings = 0;
  let mainresult = await firebase
    .firestore()
    .collection('users')
    .orderBy('createdAt', 'desc')
    .limit(50)
    .get()
    .then(querySnapshot => {
      querySnapshot.docs.map(async doc => {
        if (doc.data().avatar && doc.data().avatar.length > 0) {
          avatars = avatars + 1;
        }
        if (doc.data().primaryConsumerColor && doc.data().primaryConsumerColor !== '#000000') {
          colorChange = colorChange + 1;
        }
        promises.push(doc.ref.collection('properties').get().then(queryPropSnapshot => queryPropSnapshot))
      });
      Promise.all(promises)
        .then(results => {
          results.map(snapShot => {
            if (snapShot.size > 0) {
              addProperty = addProperty + snapShot.size;
              snapShot.forEach(doc => {
                console.log(doc.data().address)
                console.log(doc.data().siteVisits)
                if (doc.data().description) {
                  propDesc = propDesc + 1;
                }
                if (doc.data().propertyListingId) {
                  propId = propId + 1;
                }
                if (doc.data().defaultContact) {
                  propOwner = propOwner + 1;
                }
                if (doc.data().viewingDetails) {
                  propInstr = propInstr + 1;
                }
                if (doc.data().siteVisits) {
                  propViews = propViews + doc.data().siteVisits;
                }
                if (doc.data().viewingDaySettings) {
                  propSettings = propSettings + 1;
                }
              })
            }
          });

          console.log('Avatars: ' + avatars);
          console.log('Colour change: ' + colorChange);
          console.log('Property added: ' + addProperty);
          console.log('Property desc: ' + propDesc);
          console.log('Property ID: ' + propId);
          console.log('Property Owner: ' + propOwner);
          console.log('Property Instructions: ' + propInstr);
          console.log('Property Site visits: ' + propViews);
          console.log('Property Settings: ' + propSettings);
        })
    });
};
