import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Stats from './Stats';
import { ActionCreators } from '../../actions/index';
import { getStats } from '../../reducers/rootReducer';

const mapStateToProps = (state) => ({
  ...getStats(state)
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators(ActionCreators, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Stats);
