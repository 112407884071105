import firebase from 'firebase';
import * as actionConstants from '../actionConstants';
import {getE164, getDefaultFeedbackForm, getDefaultFeedbackFormName} from '../tools';

const moment = require('moment');

/**
 * Get properties by user
 *
 * @param userId
 * @returns {Function}
 */
export const getPropertiesByUser = userId => {
  return (dispatch, getState) => {
    dispatch({
      type: actionConstants.LOADING_PROPERTIES_BY_USER,
    });
    firebase.firestore()
      .collection('users')
      .doc(userId)
      .collection('properties')
      .where('available', '==', true)
      .get()
      .then(querySnapshot => {
        let properties = [];
        querySnapshot.forEach(doc => {
          properties.push({
            id: doc.id,
            user: userId,
            ...doc.data(),
          });
        });
        dispatch({
          type: actionConstants.LOADED_PROPERTIES_BY_USER,
          payload: properties,
        });
      });
  };
};

/**
 * Get teams data
 *
 * @param userId
 * @returns {function(*=): Promise<QuerySnapshot>}
 */
export const getTeamsData = userId => {
  return dispatch => {
    return firebase.firestore()
      .collection('teams')
      .where('users', 'array-contains', userId)
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          if (doc.data() && doc.data().users) {
            doc.data().users.map(teamUserId => {
              if (teamUserId !== userId) {
                dispatch(getTeamPropertiesByUser(teamUserId));
              }
            });
            dispatch(
              getCompanyAndTeamInfo(
                doc.data().name,
                doc.data().company,
                doc.data().referralEmail,
              ),
            );
          }
        });
      });
  };
};

/**
 * Get properties from team members
 *
 * @param userId
 * @returns {Function}
 */
export const getTeamPropertiesByUser = userId => {
  return (dispatch, getState) => {
    dispatch({
      type: actionConstants.LOADING_TEAM_PROPERTIES_BY_USER,
    });
    firebase.firestore()
      .collection('users')
      .doc(userId)
      .collection('properties')
      .where('available', '==', true)
      .get()
      .then(querySnapshot => {
        let properties = [];
        querySnapshot.forEach(doc => {
          properties.push({id: doc.id, ...doc.data()});
        });
        let teamProperties = getState().property.teamProperties || [];
        let teamPropertyIndex = teamProperties.map(teamProperty => teamProperty.id).indexOf(userId);
        if (teamPropertyIndex !== -1) {
          teamProperties[teamPropertyIndex] = {
            id: userId,
            properties,
          };
        } else {
          teamProperties.push({
            id: userId,
            properties,
          });
        }
        dispatch({
          type: actionConstants.LOADED_TEAM_PROPERTIES_BY_USER,
          payload: teamProperties,
        });
      });
  };
};

/**
 * Extract the team and company name
 *
 * @param name
 * @param companyId
 * @param referralEmail
 * @returns {function(*): Promise<FirebaseFirestoreTypes.DocumentSnapshot>}
 */
export const getCompanyAndTeamInfo = (name, companyId, referralEmail) => {
  return dispatch => {
    return firebase.firestore()
      .collection('companies')
      .doc(companyId)
      .get()
      .then(doc => {
        dispatch({
          type: actionConstants.UPDATE_TEAM_AND_COMPANY,
          payload: {
            teamName: name,
            companyName: doc.data().name,
            teamReferralEmail: referralEmail,
          },
        });
      });
  };
};

/**
 * Get all viewings by property
 *
 * @param property
 * @returns {Function}
 */
export const getViewingsByProperty = property => dispatch => {
  dispatch({
    type: actionConstants.LOADING_VIEWINGS_BY_PROPERTY,
  });
  firebase.firestore()
    .collection('users')
    .doc(property.user)
    .collection('viewings')
    .where('property', '==', property.id)
    .get()
    .then(querySnapshot => {
      let viewings = [];
      querySnapshot.forEach(doc => {
        if (!doc.data().cancelled) {
          viewings.push({id: doc.id, ...doc.data()});
        }
      });
      dispatch({
        type: actionConstants.LOADED_VIEWINGS_BY_PROPERTY, payload: viewings
      });
    })
};

export const addViewingRequest = (data) => async (dispatch, getState) => {
  dispatch({
    type: actionConstants.ADDING_VIEWING_REQUEST,
  });
  let iso2 = data.iso2;
  firebase.firestore()
    .collection('users')
    .doc(data.selectedProperty.user)
    .collection('contacts')
    .where('phoneNumber', '==', getE164(data.phoneNumber, iso2))
    .get()
    .then(async querySnapshot => {
      let contactType;
      switch (data.agent) {
        case 'agent':
          contactType = 'agent';
          break;
        case 'inspector':
          contactType = 'inspector';
          break;
        case 'broker':
          contactType = 'broker';
          break;
        case 'buyer':
        case 'other':  
          contactType = 'other';
          break;
        default:
          contactType = 'lead';
      }
      let contactId = null;
      if (querySnapshot.empty) {
        let contactData = {
          name: data.name,
          phoneNumber: getE164(data.phoneNumber, iso2),
          contactType: contactType,
          origin: data.selectedProperty.address,
          feedbackSent: 0,
          iso2: iso2,
          new: true,
          createdAt: Date.now(),
          updatedAt: Date.now(),
          email: data.email || null,
          dre: data.dre || null,
        };
        await firebase.firestore()
          .collection('users')
          .doc(data.selectedProperty.user)
          .collection('contacts')
          .add(contactData)
          .then(doc => contactId = doc.id);
      } else {
        let doc = querySnapshot.docs[0];
        let updateData = {};
        updateData['contactType'] = contactType;
        if (data.email) {
          updateData['email'] = data.email;
        }
        if (data.dre) {
          updateData['dre'] = data.dre;
        }
        if (data.name) {
          updateData['name'] = data.name;
        }
        await firebase.firestore()
          .collection('users')
          .doc(data.selectedProperty.user)
          .collection('contacts')
          .doc(doc.id)
          .update(updateData);

        contactId = doc.id
      }
      let visitors = data.visitors;
      visitors.push({
        name: data.name,
        email: data.email,
        dre: data.dre,
        phoneNumber: data.phoneNumber,
        agent: contactType,
        contact: contactId,
      });
      let propertyOwners = [];
      if (data.selectedProperty.defaultContact) {
        propertyOwners.push(data.selectedProperty.defaultContact.phoneNumber)
      }
      if (data.selectedProperty.additionalContact) {
        propertyOwners.push(data.selectedProperty.additionalContact.phoneNumber)
      }
      let collection = 'requestedViewings';
      let viewingCount = 0;
      if (data.selectedProperty.confirmBy && data.selectedProperty.confirmBy === 'none') {
        viewingCount = await firebase
          .firestore()
          .collection('users')
          .doc(data.selectedProperty.user)
          .collection('viewings')
          .where('phoneNumber', '==', getE164(data.phoneNumber, iso2))
          .get()
          .then(querySnapshot => {
            let count = 0;
            querySnapshot.forEach(doc => {
              if (doc.data().property === data.selectedProperty.id) {
                count = count + 1;
              }
            });
            return count;
          });
        collection = 'viewings';
      }
      let userCall = await firebase.firestore().collection('users').doc(data.selectedProperty.user).get().then(doc => doc);
      let userId = userCall.id;
      let userData = userCall.data();
      firebase.firestore()
        .collection('users')
        .doc(data.selectedProperty.user)
        .collection(collection)
        .add({
          property: data.selectedProperty.id,
          contact: contactId,
          contactName: data.name,
          phoneNumber: getE164(data.phoneNumber, iso2),
          viewingDateTime: moment(data.selectedTime).format(),
          propertyAddress: data.selectedProperty.address,
          createdAt: parseInt(moment.utc().format('x')),
          feedbackForm: getDefaultFeedbackForm(getState().user.user, data.selectedProperty, getState().user.feedbackForms, null),
          feedbackFormName: getDefaultFeedbackFormName(getState().user.user, data.selectedProperty, getState().user.feedbackForms, null),
          visitors: visitors,
          peadRequired: getState().user.user.pead || null,
          propertyOwners: propertyOwners,
          sender: userId,
          senderName: userData.name || null,
          senderAvatar: userData.avatar || null,
          email: data.email || null,
          viewingDetailsLink: data.selectedProperty.viewingDetailsLink || null,
          sendSMS: true,
          viewingCount,
          confirmBy: data.selectedProperty.confirmBy || 'agent',
          viewingDetails: data.selectedProperty.viewingDetails || null,
          requestFeedback: data.selectedProperty.requestFeedback === undefined ? true : !!data.selectedProperty.requestFeedback,
        })
        .then(doc => {
          dispatch({
            type: actionConstants.ADDED_VIEWING_REQUEST,
          });
          // if (getState().user.user.pead) {
          //   let promises = [];
          //   visitors.forEach(visitor => {
          //     promises.push(
          //       firebase.firestore()
          //         .collection('pead')
          //         .add({
          //           requestedViewing: doc.id,
          //           property: data.selectedProperty.id,
          //           contact: visitor.contact || null,
          //           service: 'docusign',
          //           name: visitor.name,
          //           email: visitor.email,
          //           title: 'PEAD',
          //           subject: data.selectedProperty.address + ' PEAD signature required',
          //           message: 'Please sign this PEAD document prior to your showing at ' + data.selectedProperty.address,
          //           date: moment(data.selectedTime).format('MM/DD/YYYY'),
          //           propertyAddress: data.selectedProperty.address,
          //           dre: visitor.dre || '',
          //           brokerage: visitor.agent === 'agent' || visitor.agent === 'broker' ? visitor.name : '',
          //           accessToken: getState().user.user.docuSignAuth.accessToken || '',
          //           baseUrl: getState().user.user.docuSignAuth.baseUrl || '',
          //           createdAt: Date.now(),
          //           user: getState().user.user.id,
          //         })
          //     )
          //   });
          //   Promise.all(promises);
          // }
        })
    })
};

/**
 * Add qualification feedback
 *
 * @param user
 * @param data
 * @returns {function(*): Promise<FirebaseFirestoreTypes.DocumentReference>}
 */
export const addQualificationFeedback = (user, data) => {
  return (dispatch, getState) => {
    dispatch({type: actionConstants.ADDING_QUALIFICATION_FEEDBACK});
    let qualificationFormId = data.selectedProperty.qualificationForm || user.qualificationForm;
    let iso2 = data.iso2;
    firebase.firestore()
      .collection('users')
      .doc(data.selectedProperty.user)
      .collection('contacts')
      .where('phoneNumber', '==', getE164(data.phoneNumber, iso2))
      .get()
      .then(async querySnapshot => {
        let contactType;
        switch (data.agent) {
          case 'agent':
            contactType = 'agent';
            break;
          case 'inspector':
            contactType = 'inspector';
            break;
          case 'broker':
            contactType = 'broker';
          case 'buyer':
          case 'other':  
            contactType = 'other';
            break;
          default:
            contactType = 'lead';
        }
        let contactId = null;
        if (querySnapshot.empty) {
          let contactData = {
            name: data.name,
            phoneNumber: getE164(data.phoneNumber, iso2),
            contactType: contactType,
            origin: data.selectedProperty.address,
            feedbackSent: 0,
            iso2: iso2,
            new: true,
            createdAt: Date.now(),
            updatedAt: Date.now(),
            email: data.email || null,
            dre: data.dre || null,
          };
          await firebase.firestore()
            .collection('users')
            .doc(data.selectedProperty.user)
            .collection('contacts')
            .add(contactData)
            .then(doc => contactId = doc.id);
        } else {
          let doc = querySnapshot.docs[0];
          let updateData = {};
          updateData['contactType'] = contactType;
          if (data.email) {
            updateData['email'] = data.email;
          }
          if (data.dre) {
            updateData['dre'] = data.dre;
          }
          await firebase.firestore()
            .collection('users')
            .doc(data.selectedProperty.user)
            .collection('contacts')
            .doc(doc.id)
            .update(updateData);

          contactId = doc.id
        }
        firebase
          .firestore()
          .collection('users')
          .doc(user.id)
          .collection('feedbackForms')
          .doc(qualificationFormId)
          .get()
          .then(doc => {
            let selectedQualificationForm = doc.data();
            let qualificationFeedback = {
              contact: contactId,
              contactName: data.name,
              contactUser: user.id,
              contactEmail: data.email || null,
              // phoneNumber: data.selectedContact.phoneNumber,
              property: data.selectedProperty.id,
              propertyAddress: data.selectedProperty.address,
              propertyUser: data.selectedProperty.user,
              feedbackForm: doc.id,
              feedbackFormName: selectedQualificationForm.name,
              feedbackFormUser: user.id,
              qualification: true,
              questions: selectedQualificationForm.questions,
              questionCount: selectedQualificationForm.questionCount,
              sender: user.id,
              senderName: user.useCompanyNameInComms
                ? user.companyNameToUse
                : user.name,
              senderAvatar: user.avatar || null,
              rating: 0,
              sentOn: Date.now(),
              sendAttempts: 3,
              sendDateTime: Date.now(),
              askBorrowingReferral: false,
              teamName:
                user.teamName || user.name || null,
              companyName:
                user.companyName || user.company || null,
              teamReferralEmail:
                user.teamReferralEmail ||
                user.referralEmail ||
                null,
            }
            Promise.all([
              firebase
                .firestore()
                .collection('users')
                .doc(user.id)
                .collection('feedback')
                .add(qualificationFeedback),
              firebase
                .firestore()
                .collection('users')
                .doc(data.selectedProperty.user)
                .collection('properties')
                .doc(data.selectedProperty.id)
                .update({
                  feedbackSent: firebase.firestore.FieldValue.increment(1),
                  updatedAt: Date.now(),
                }),
              firebase
                .firestore()
                .collection('users')
                .doc(user.id)
                .collection('contacts')
                .doc(contactId)
                .update({
                  feedbackSent: firebase.firestore.FieldValue.increment(1),
                  updatedAt: Date.now(),
                }),
              firebase
                .firestore()
                .collection('users')
                .doc(user.id)
                .collection('feedbackForms')
                .doc(qualificationFormId)
                .update({
                  feedbackSent: firebase.firestore.FieldValue.increment(1),
                }),
            ]).then(([feedbackDoc]) => {
              firebase
                .firestore()
                .collection('sentFeedback')
                .add({
                  feedback: feedbackDoc.id,
                  sender: user.id,
                });
              dispatch({
                type: actionConstants.ADDED_QUALIFICATION_FEEDBACK,
                qualificationFeedback: {id: feedbackDoc.id, ...qualificationFeedback}
              });
            });
          })
      })
  };
};

export const updateQualification = (data, passed) => {
  return (dispatch) => {
    firebase
      .firestore()
      .collection('users')
      .doc(data.selectedProperty.user)
      .collection('feedback')
      .doc(data.feedback.id)
      .update({
        qualificationPassed: passed,
      });
  }
};

export const getPropertyMedia = (userId, propertyId) => {
  return dispatch => {
    dispatch({ type: actionConstants.GETTING_PROPERTY_MEDIA});
    firebase
      .firestore()
      .collection('users')
      .doc(userId)
      .collection('properties')
      .doc(propertyId)
      .collection('media')
      .get()
      .then(querySnapshot => {
        let media = [];
        querySnapshot.forEach(doc => {
          if (doc.exists) {
            media.push({id: doc.id, ...doc.data()})
          }
        });
        dispatch({ type: actionConstants.GOT_PROPERTY_MEDIA, payload: media});
      })
  };
};

export const addVisitRecord = (userId, propertyId, currentVisits) => {
  return (dispatch) => {
    firebase
      .firestore()
      .collection('users')
      .doc(userId)
      .collection('properties')
      .doc(propertyId)
      .update({
        //siteVisits: currentVisits > 0 ? firebase.firestore.FieldValue.increment(1) : 1,
        siteVisits: firebase.firestore.FieldValue.increment(1),
      });
  }
};
