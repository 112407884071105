import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Register from './Register';
import { ActionCreators } from '../../actions/index';
import { getSheet, getFeedback, getUser } from '../../reducers/rootReducer';

const mapStateToProps = (state) => ({
  ...getSheet(state),
  ...getFeedback(state),
  ...getUser(state),
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators(ActionCreators, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Register);
