import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope, faPhone} from "@fortawesome/pro-light-svg-icons";
import {formatPhoneNumber} from "../tools";
import logoColor from '../img/rh-logo-roof.png';


const renderImage = user => {
  if (user.companyLogo) {
    return (<img src={user.companyLogo} alt={'Real Happy'}
                 style={{height: 100, alignSelf: 'center'}}/>)
  } else {
    return (
      <img src={logoColor} alt={'Real Happy'}
           style={{height: 100, alignSelf: 'center'}}/>
    )
  }
};

const renderPhoneNumber = (user, color) => {
  let phoneNumber = null;
  if (!!user.companyPhoneNumber) {
    phoneNumber = formatPhoneNumber(user.companyPhoneNumber, user.iso2);
  }
  if (phoneNumber) {
    return (
      <div>
        <p style={{fontSize: 20}}><a href={'tel:' + phoneNumber} style={{color: '#000000',textDecoration: 'underline'}}>{phoneNumber}</a></p>
      </div>
    )
  }
};

const renderEmail = (user, color) => {
  let email = null;
  if (!!user.companyEmail) {
    email = user.companyEmail;
  }
  if (email) {
    return (
      <div>
        <p style={{fontSize: 20}}><a href={'mailto:' + email} style={{color: '#000000', textDecoration: 'underline'}}>{email}</a></p>
      </div>
    )
  }
};

const CompanyCard = (props) => (
  <div style={{display: 'flex', paddingLeft: 15, paddingRight: 15, paddingTop: 30}}>
    {props.user && props.user.id && (props.user.detailsToShow === 'company' || props.user.detailsToShow === 'both')  && (
      <div style={{width: '100%', paddingTop: 30, paddingBottom: 20, paddingLeft: 16, paddingRight: 16, flexDirection: 'column', display: 'flex', borderRadius: 7, backgroundColor: '#FFFFFF'}}>
        <div style={{textAlign: 'center', width: '100%', marginBottom: 30}}>
          {renderImage(props.user)}
        </div>
        <div style={{textAlign: 'center', width: '100%', marginBottom: 30, fontSize: 26, fontWeight: 'bold'}}>
          {props.user.company}
        </div>
        <div style={{textAlign: 'center', width: '100%', marginBottom: 8, fontSize: 20}}>
          {renderPhoneNumber(props.user, props.color)}
        </div>
        <div style={{textAlign: 'center', width: '100%', marginBottom: 20, fontSize: 20}}>
          {renderEmail(props.user, props.color)}
        </div>
      </div>
    )}
  </div>
);

export default CompanyCard;
