import React, { PureComponent } from 'react';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import 'firebase/firestore';
import rootReducer from './reducers/rootReducer';

/**
The routes.js file includes all the routes in the app
 */
import Routes from './routes';


const middleware = [thunk];
// Logger will only run in development mode __DEV__ is a NPM global constant
if (process.env.NODE_ENV === 'development') {
  const loggerMiddleware = createLogger();
  middleware.push(loggerMiddleware);
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(rootReducer, {}, composeEnhancers(applyMiddleware(...middleware)));

class App extends PureComponent {
  render() {
    return (
      <Provider store={store}>
        <Routes />
      </Provider>
    );
  }
}

export default App;
