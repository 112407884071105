import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ViewFeedback from './ViewFeedback';
import { ActionCreators } from '../../actions/index';
import { getFeedback } from '../../reducers/rootReducer';

const mapStateToProps = (state) => ({
  ...getFeedback(state)
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators(ActionCreators, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewFeedback);
