import React, { PureComponent } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import FeedbackTemplate from './templates/FeedbackTemplate';
import SheetTemplate from './templates/SheetTemplate';
import PortalTemplate from './templates/PortalTemplate';
import Feedback from './containers/Feedback';
import ViewFeedback from "./containers/ViewFeedback";
import Sheet from "./containers/Sheet";
import Register from "./containers/Register";
import BookViewing from "./containers/BookViewing";
import Property from "./containers/Property";
import Stats from "./containers/Stats";
import DataStats from "./containers/DataStats";
import OwnerPortal from "./containers/OwnerPortal";
import Tours from "./containers/Tours";
import BookerPortal from "./containers/BookerPortal";
import DocuSignAuth from "./containers/DocuSignAuth";
import OAuth from "./containers/OAuth";
//import * as Socialio from "./Portal";

/**
 * Routes file for controlling the auth routes and the main dashboard routes, wrapped in the appropriate template
 */
class Routes extends PureComponent {
  render() {
    return (
      <Router>
        <div>
          <Route
            path="/"
            exact
            component={() => {
              window.location.href = 'https://realhappy.net';
              return null;
            }}
          />
          <Switch>
            <Route
              path="/feedback"
              render={() => (
                <FeedbackTemplate>
                  <Route path="/feedback/:key" component={Feedback} />
                </FeedbackTemplate>
              )}
            />
            <Route
              path="/f"
              render={() => (
                <FeedbackTemplate>
                  <Route path="/f/:key" component={Feedback} />
                </FeedbackTemplate>
              )}
            />
            <Route
              path="/view"
              render={() => (
                <FeedbackTemplate>
                  <Route path="/view/:key" component={ViewFeedback} />
                </FeedbackTemplate>
              )}
            />
            <Route
              path="/v"
              render={() => (
                <FeedbackTemplate>
                  <Route path="/v/:key" component={ViewFeedback} />
                </FeedbackTemplate>
              )}
            />
            <Route
              path="/sheet"
              render={() => (
                <SheetTemplate>
                  <Route path="/sheet/:key" component={Sheet} />
                </SheetTemplate>
              )}
            />
            <Route
              path="/s"
              render={() => (
                <SheetTemplate>
                  <Route path="/s/:key" component={Sheet} />
                </SheetTemplate>
              )}
            />
            <Route
              path="/register"
              render={() => (
                <FeedbackTemplate>
                  <Route path="/register/:key" component={Register} />
                </FeedbackTemplate>
              )}
            />
            <Route
              path="/r"
              render={() => (
                <FeedbackTemplate>
                  <Route path="/r/:key" component={Register} />
                </FeedbackTemplate>
              )}
            />
            <Route
              path="/booking/:key/:prop/details"
              render={() => (
                <FeedbackTemplate>
                  <Route path="/booking/:key/:prop/details" component={Property} />
                </FeedbackTemplate>
              )}
            />
            <Route
              path="/b/:key/:prop/details"
              render={() => (
                <FeedbackTemplate>
                  <Route path="/b/:key/:prop/details" component={Property} />
                </FeedbackTemplate>
              )}
            />
            <Route
              path="/booking/:key/:prop/2"
              render={() => (
                <FeedbackTemplate>
                  <Route path="/booking/:key/:prop/2" component={BookViewing} />
                </FeedbackTemplate>
              )}
            />
            <Route
              path="/b/:key/:prop/2"
              render={() => (
                <FeedbackTemplate>
                  <Route path="/b/:key/:prop/2" component={BookViewing} />
                </FeedbackTemplate>
              )}
            />
            <Route
              path="/booking/:key/:prop"
              render={() => (
                <FeedbackTemplate>
                  <Route path="/booking/:key/:prop" component={Property} />
                </FeedbackTemplate>
              )}
            />
            <Route
              path="/b/:key/:prop"
              render={() => (
                <FeedbackTemplate>
                  <Route path="/b/:key/:prop" component={Property} />
                </FeedbackTemplate>
              )}
            />
            <Route
              path="/booking"
              render={() => (
                <FeedbackTemplate>
                  <Route path="/booking/:key" component={BookViewing} />
                </FeedbackTemplate>
              )}
            />
            <Route
              path="/b"
              render={() => (
                <FeedbackTemplate>
                  <Route path="/b/:key" component={BookViewing} />
                </FeedbackTemplate>
              )}
            />
            <Route
              path="/stats"
              render={() => (
                <FeedbackTemplate>
                  <Route path="/stats/:key" component={Stats} />
                </FeedbackTemplate>
              )}
            />
            <Route
              path="/datastats"
              render={() => (
                <FeedbackTemplate>
                  <Route path="/datastats/:key" component={DataStats} />
                </FeedbackTemplate>
              )}
            />
            <Route
              path="/docuSignAuth"
              render={() => (
                <FeedbackTemplate>
                  <Route path="/docuSignAuth" component={DocuSignAuth} />
                </FeedbackTemplate>
              )}
            />
            <Route
              path="/portal"
              render={() => (
                <Route path="/portal/:key" component={OwnerPortal} />
              )}
            />
            <Route
              path="/p"
              render={() => (
                <Route path="/p/:key" component={OwnerPortal} />
              )}
            />
            <Route
              path="/bookerPortal"
              render={() => (
                <Route path="/bookerPortal/:key" component={BookerPortal} />
              )}
            />
            <Route
              path="/bp"
              render={() => (
                <Route path="/bp/:key" component={BookerPortal} />
              )}
            />
            <Route
              path="/tours"
              render={() => (
                <Route path="/tours/:key/:tour" component={Tours} />
              )}
            />
            <Route
              path="/t"
              render={() => (
                <Route path="/t/:key/:tour" component={Tours} />
              )}
            />
            <Route
              path="/oauth"
              render={() => (
                <Route path="/oauth" component={OAuth} />
              )}
            />
          </Switch>
        </div>
      </Router>
    );
  }
}

export default Routes;
