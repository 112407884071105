// @flow weak
import * as actionConstants from '../actionConstants';

// Reducer
const DEFAULT_STATE = {
  users: [],
  loadingStats: false,
  loadedStats: false,
};

export default function (state = DEFAULT_STATE, action) {
  switch (action.type) {
    case actionConstants.LOADING_STATS:
      return {
        ...state, loadingStats: true, loadedStats: false
      };
    case actionConstants.LOADED_STATS:
      return {
        ...state, loadingStats: false, loadedStats: true, users: action.payload
      };
    default:
      return state;
  }
}

// Selectors (mapStateToP rops)
export const getStats = ({
  users, loadingStats, loadedStats,
}) => ({
  users,
  loadingStats,
  loadedStats,
});
