import * as feedbackActions from './feedbackActions';
import * as sheetActions from './sheetActions';
import * as registerActions from './registerActions';
import * as propertyActions from './propertyActions';
import * as userActions from './userActions';
import * as statsActions from './statsActions';
import * as portalActions from './portalActions';
import * as bookerPortalActions from './bookerPortalActions';
import * as tourActions from './tourActions';

export const ActionCreators = {
  ...feedbackActions,
  ...sheetActions,
  ...registerActions,
  ...propertyActions,
  ...userActions,
  ...statsActions,
  ...portalActions,
  ...bookerPortalActions,
  ...tourActions,
};
