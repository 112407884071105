import firebase from 'firebase';
import * as actionConstants from '../actionConstants';

/**
 * Load all users by organization
 *
 * @returns {function(*, *)}
 */
export const getSheet = (id) => (dispatch) => {
  dispatch({ type: actionConstants.LOADING_SHEET });
  firebase.firestore().collection('smsPropertyList').where('textCode', '==', id.toUpperCase()).limit(1).get()
    .then((querySnapshot) => {
      if (!querySnapshot.empty) {
        let doc = querySnapshot.docs[0];
        const sheet = {id: doc.id, ...doc.data()};
        firebase.firestore().collection('users').doc(sheet.user).get()
          .then(doc => {
            sheet.senderAvatar = doc.data().avatar;
            sheet.primaryColor = doc.data().primaryConsumerColor;
            sheet.senderName = doc.data().name;
            sheet.senderPhoneNumber = doc.data().phoneNumber;
            sheet.companyName = doc.data().company;
            sheet.senderEmail = doc.data().email;
            dispatch({ type: actionConstants.LOADED_SHEET, payload: sheet })
          });
      } else {
        dispatch({ type: actionConstants.LOADING_FEEDBACK_FAILED })
      }
    }).catch((error) => { dispatch({ type: actionConstants.LOADING_FEEDBACK_FAILED, payload: error })});
};
