import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSmile, faMeh, faFrown } from '@fortawesome/free-solid-svg-icons'
import ActivitySpinner from '../../components/ActivitySpinner';
import '../../css/realhappy-feedback.scss';
import logo from '../../img/rh-logo-trans.png';
import {colors} from '../../styles';
import {WEBSITE_URL} from "../../environmentVariables";
import HeaderAvatar from '../../components/HeaderAvatar';
import Footer from '../../components/Footer';

const animationIn = 'flipInX';
const animationOut = 'flipOutX';

class Feedback extends Component {
  constructor(props) {
    super(props);
    this.feedbackId = this.props.match.params.key;
  }

  state = {
    feedback: {},
    currentQuestionIndex: 0,
    totalRating: 0,
    ratingCount: 0,
    rating: 0,
    answers: [],
    questionAnimation: animationIn,
    borrowingReferral: false,
    property: {},
    user: {},
    primaryConsumerColor: '#000000',
  };

  UNSAFE_componentWillMount() {
    this.props.getFeedback(this.feedbackId);
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.feedback && nextProps.feedback !== this.props.feedback) {
      this.setState({feedback: nextProps.feedback}, () => {
        this.props.getFeedbackProperty(nextProps.feedback.propertyUser, nextProps.feedback.property)
        this.props.getUser(nextProps.feedback.propertyUser)
      });
    }
    if (nextProps.property && nextProps.property !== this.props.property) {
      this.setState({property: nextProps.property});
    }
    if (nextProps.user && nextProps.user !== this.props.user){
      this.setState({
        user: nextProps.user,
        primaryConsumerColor: nextProps.user.primaryConsumerColor ? nextProps.user.primaryConsumerColor : '#000000',
      })
    }
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextState.currentQuestionIndex !== this.state.currentQuestionIndex) {
      this.setState({questionAnimation: animationIn})
    }
  }

  numberOfQuestions() {
    if (this.state.feedback.askBorrowingReferral) {
      return this.state.feedback.questions.length + 1;
    }
    return this.state.feedback.questions.length;
  }

  numberOfAnswers () {
    if (this.state.feedback.askBorrowingReferral) {
      return this.state.feedback.answers.length + 1;
    }
    return this.state.feedback.answers.length;
  }

  ratingAnswer(rating) {
    this.setState({questionAnimation: animationOut}, () => setTimeout(() => {
      let answers = this.state.answers;
      let totalRating = this.state.totalRating + rating;
      let ratingCount = this.state.ratingCount + 1;
      let avgRating = parseFloat((totalRating / ratingCount).toFixed(1));
      let currentQuestionIndex = this.state.currentQuestionIndex + 1;
      answers.push(rating);
      this.setState({
        answers,
        totalRating,
        ratingCount,
        avgRating,
        currentQuestionIndex
      }, () => this.checkAndSend(this.state))
    }, 500));
  }

  booleanAnswer(value) {
    this.setState({questionAnimation: animationOut}, () => setTimeout(() => {
      let answers = this.state.answers;
      let currentQuestionIndex = this.state.currentQuestionIndex + 1;
      answers.push(value);
      this.setState({
        answers,
        currentQuestionIndex
      }, () => this.checkAndSend(this.state))
    }, 500));
  }

  textAnswer(text) {
    this.setState({questionAnimation: animationOut}, () => setTimeout(() => {
      let answers = this.state.answers;
      let currentQuestionIndex = this.state.currentQuestionIndex + 1;
      answers.push(text);
      this.setState({
        answers,
        currentQuestionIndex
      }, () => this.checkAndSend(this.state))
    }, 500));
  }

  answerBorrowingReferral(value) {
    this.setState({questionAnimation: animationOut}, () => setTimeout(() => {
      let currentQuestionIndex = this.state.currentQuestionIndex + 1;
      this.setState({
        borrowingReferral: value,
        currentQuestionIndex
      }, () => this.checkAndSend(this.state))
    }, 500));
  }

  checkAndSend() {
    if (this.state.currentQuestionIndex === 1) {
      this.props.updateFeedback(this.state, this.feedbackId);
    } else {
      this.props.updateSubsequentFeedback(this.state, this.feedbackId);
    }
  }

  renderContent() {
    if (this.props.loadingFeedback) {
      return (
        <div className="content-holder">
          <ActivitySpinner />
        </div>
      );
    }

    if (this.props.loadedFeedback && this.state.user.id) {
      return (
        <div>
          <div style={{display: 'flex', alignItems: 'center', marginBottom: 20, justifyContent: 'center'}}>
            <HeaderAvatar user={this.state.user} />
          </div>
          <div style={{textAlign: 'center'}}>
            <p style={{fontSize: '16px', lineHeight: '18px'}}>
              {(this.state.feedback.openhouse) ? (
                <span></span>
              ) : (
                <span>{this.state.feedback.senderName} would love your feedback</span>
              )}
              <br />
              <span style={{fontWeight: 'bold'}}>{this.state.feedback.propertyAddress}</span>
            </p>
          </div>
          {!this.state.feedback.contactName ? (
            <div>
              {this.renderNameInput()}
            </div>
          ) : (
            !!this.state.feedback.receivedOn && this.numberOfAnswers() === this.numberOfQuestions() ? (
              <div>
                {this.renderSorry()}
              </div>
            ) : (
              this.state.currentQuestionIndex < this.numberOfQuestions() ? (
                <div>
                  <div>
                    {this.renderQuestions()}
                  </div>
                  <div>
                    {this.renderCounter()}
                  </div>
                </div>
              ) : (
                <div>
                  {this.renderThankyou()}
                </div>
              )
            )
          )}
        </div>
      );
    }

    return null;
  }

  renderNameInput() {
    return (
      <div className={'animated ' + this.state.questionAnimation} style={{marginTop: 20, marginBottom: 20, marginLeft: 10, marginRight: 10, padding: 20, backgroundColor: "#FFFFFF", borderRadius: 9}}>
        <div style={{marginBottom: 30}}>
          <p style={{fontSize: '18px', color: colors.question, textAlign: 'center'}}>Please enter your full name</p>
        </div>
        <div>
          <input
            type="text"
            style={{borderWidth: 1, borderColor: '#DEDEDE', width: "100%", borderRadius: 5, fontSize: 16, height: 30, textAlign: 'center' }}
            ref={(ref) => this.name = ref}
            autoComplete={'name'}
          />
          <div style={{display: 'flex', width: '100%', justifyContent: 'center', marginTop: 20}}>
            <div style={{display: 'flex', width: 100, justifyContent: 'center', height: 40, backgroundColor: this.state.primaryConsumerColor, borderRadius: 5, paddingTop: 11}}
                 onClick={() => this.setState({feedback: {...this.state.feedback, contactName: this.name.value }}, this.props.updateFeedbackName(this.state.feedback, this.feedbackId, this.name.value))}
            >
              <span style={{color: '#FFFFFF'}}>NEXT</span>
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderQuestions() {
    if (this.state.feedback.askBorrowingReferral && ((this.state.currentQuestionIndex + 1) === this.numberOfQuestions())) {
      return (
        <div className={'animated ' + this.state.questionAnimation} style={{marginTop: 20, marginBottom: 20, marginLeft: 10, marginRight: 10, padding: 20, backgroundColor: "#FFFFFF", borderRadius: 9}}>
          <div style={{marginBottom: 30}}>
            <p style={{fontSize: '18px', color: colors.question, textAlign: 'center'}}>{this.state.currentQuestionIndex + 1}. Would you like someone to contact you to help with finding a mortgage?</p>
          </div>
          {this.renderBorrowingReferralAnswers()}
        </div>
      )
    }
    return (
      <div className={'animated ' + this.state.questionAnimation} style={{marginTop: 20, marginBottom: 20, marginLeft: 10, marginRight: 10, padding: 20, backgroundColor: "#FFFFFF", borderRadius: 9}}>
        <div style={{marginBottom: 30}}>
          <p style={{fontSize: '18px', color: colors.question, textAlign: 'center'}}>{this.state.currentQuestionIndex + 1}. {this.state.feedback.questions[this.state.currentQuestionIndex].question}</p>
        </div>
        {this.renderAnswerChoices()}
      </div>
    )
  }

  renderAnswerChoices() {
    switch (this.state.feedback.questions[this.state.currentQuestionIndex].type) {
      case "rating":
        return (
          <div style={{display: 'flex', flexDirection: 'row'}}>
            <div style={{display: 'flex', width: '20%', justifyContent: 'center'}} onClick={() => this.ratingAnswer(5)}>
              <FontAwesomeIcon icon={faSmile} style={{fontSize: '40px', color: colors.vhappy}}/>
            </div>
            <div style={{display: 'flex', width: '20%', justifyContent: 'center'}} onClick={() => this.ratingAnswer(4)}>
              <FontAwesomeIcon icon={faSmile} style={{fontSize: '40px', color: colors.happy}}/>
            </div>
            <div style={{display: 'flex', width: '20%', justifyContent: 'center'}} onClick={() => this.ratingAnswer(3)}>
              <FontAwesomeIcon icon={faMeh} style={{fontSize: '40px', color: colors.neutral}}/>
            </div>
            <div style={{display: 'flex', width: '20%', justifyContent: 'center'}} onClick={() => this.ratingAnswer(2)}>
              <FontAwesomeIcon icon={faFrown} style={{fontSize: '40px', color: colors.sad}}/>
            </div>
            <div style={{display: 'flex', width: '20%', justifyContent: 'center'}} onClick={() => this.ratingAnswer(1)}>
              <FontAwesomeIcon icon={faFrown} style={{fontSize: '40px', color: colors.vsad}}/>
            </div>
          </div>
        );
      case "boolean":
        return (
          <div style={{display: 'flex', flexDirection: 'row'}}>
            <div style={{display: 'flex', width: '50%', justifyContent: 'center'}}>
              <div style={{display: 'flex', width: 100, justifyContent: 'center', height: 40, backgroundColor: colors.positive, borderRadius: 5, paddingTop: 11}}
                onClick={() => this.booleanAnswer(true)}
              >
                <span style={{color: '#FFFFFF'}}>YES</span>
              </div>
            </div>
            <div style={{display: 'flex', width: '50%', justifyContent: 'center'}}>
              <div style={{display: 'flex', width: 100, justifyContent: 'center', height: 40, backgroundColor: colors.negative, borderRadius: 5, paddingTop: 11}}
                onClick={() => this.booleanAnswer(false)}
              >
                <span style={{color: '#FFFFFF'}}>NO</span>
              </div>
            </div>
          </div>
        );
      case "text":
        this.text = [];
        return (
          <div>
            <textarea
              style={{borderWidth: 1, borderColor: '#DEDEDE', width: "100%", height: 80, borderRadius: 5, fontSize: 16 }}
              ref={(ref) => this.text = ref}
            ></textarea>
            <div style={{display: 'flex', width: '100%', justifyContent: 'center', marginTop: 20}}>
              <div style={{display: 'flex', width: 100, justifyContent: 'center', height: 40, backgroundColor: this.state.primaryConsumerColor, borderRadius: 5, paddingTop: 11}}
                   onClick={() => { this.textAnswer(this.text.value); this.text.value = ''}}
              >
                <span style={{color: '#FFFFFF'}}>NEXT</span>
              </div>
            </div>
          </div>
        );
      default:
        return null;
    }
  }

  renderBorrowingReferralAnswers() {
    return (
      <div style={{display: 'flex', flexDirection: 'row'}}>
        <div style={{display: 'flex', width: '50%', justifyContent: 'center'}}>
          <div style={{display: 'flex', width: 100, justifyContent: 'center', height: 40, backgroundColor: colors.positive, borderRadius: 5, paddingTop: 11}}
               onClick={() => this.answerBorrowingReferral(true)}
          >
            <span style={{color: '#FFFFFF'}}>YES</span>
          </div>
        </div>
        <div style={{display: 'flex', width: '50%', justifyContent: 'center'}}>
          <div style={{display: 'flex', width: 100, justifyContent: 'center', height: 40, backgroundColor: colors.negative, borderRadius: 5, paddingTop: 11}}
               onClick={() => this.answerBorrowingReferral(false)}
          >
            <span style={{color: '#FFFFFF'}}>NO</span>
          </div>
        </div>
      </div>
    )
  }

  renderCounter() {
    return (
      <div>
        <div style={{marginTop: 20, marginBottom: 20, marginLeft: 10, marginRight: 10, backgroundColor: "#FFFFFF", borderRadius: 9, height: 40}}>
          <div style={{display: 'flex', justifyContent: 'flex-end', paddingTop: 13, paddingRight: 10, backgroundColor: '#E9EAED', borderRadius: 9, height: 40, width: ((this.state.currentQuestionIndex + 1) / this.numberOfQuestions() * 100) + '%'}}>
            <span style={{fontSize: 12, fontWeight: 'bold', height: 15}}>{(this.state.currentQuestionIndex + 1)} of {this.numberOfQuestions()}</span>
          </div>
        </div>
      </div>
    )
  }

  renderThankyou() {
    return (
      <div style={{marginTop: 20, marginBottom: 20, marginLeft: 10, marginRight: 10, padding: 20, backgroundColor: "#FFFFFF", borderRadius: 9}}>
        <div style={{marginBottom: 30, paddingTop: 10}}>
          <p style={{fontSize: '18px', textAlign: 'center', fontWeight: 'bold', marginBottom: 20}}>Thank you!</p>
          <p style={{fontSize: '18px', color: '#8F8F8F', textAlign: 'center'}}>Your feedback is greatly appreciated. Thank you for your time today.</p>
        </div>
        {this.renderContentLink()}
      </div>
    )
  }

  renderSorry() {
    return (
      <div style={{marginTop: 20, marginBottom: 20, marginLeft: 10, marginRight: 10, padding: 20, backgroundColor: "#FFFFFF", borderRadius: 9}}>
        <div style={{marginBottom: 30, paddingTop: 10}}>
          <p style={{fontSize: '18px', textAlign: 'center', fontWeight: 'bold', marginBottom: 20}}>Oops!</p>
          <p style={{fontSize: '18px', color: '#8F8F8F', textAlign: 'center'}}>You have already completed the requested feedback on this address.</p>
        </div>
        {this.renderContentLink()}
      </div>
    )
  }

  renderContentLink() {
    if (this.state.feedback.openhouse && this.state.property && !!this.state.property.id && this.state.property.contentLink && this.state.property.contentLabel) {
      return (
        <div style={{display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center'}}>
          <div
            style={{height: 40, backgroundColor: this.state.primaryConsumerColor, borderRadius: 5, paddingTop: 11, paddingBottom: 11, paddingLeft: 20, paddingRight: 20}}
            onClick={() => window.location.href = this.state.property.contentLink}
          >
            <span style={{color: '#FFFFFF'}}>{this.state.property.contentLabel.toUpperCase()}</span>
          </div>
        </div>
      );
    }
    return null;
  }

  render() {
    return (
      <div>
        <div style={{paddingTop: 30, paddingBottom: 30, paddingLeft: 20, paddingRight: 20, marginLeft: 'auto', marginRight: 'auto', maxWidth: 500}}>
          {this.renderContent()}
          <Footer />
        </div>
      </div>
    );
  }
}

export default Feedback;
