import React, { PureComponent } from 'react';
import '../css/realhappy-feedback.css';

/**
 * The template for all the auth routes
 */
class FeedbackTemplate extends PureComponent {
  render() {
    return (
      <div>
        { this.props.children }
      </div>
    );
  }
}


export default FeedbackTemplate;
