import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faChevronLeft } from "@fortawesome/pro-light-svg-icons";
import { getUserLocale } from 'get-user-locale';
import getSymbolFromCurrency from 'currency-symbol-map';
import ReactFlagsSelect from 'react-flags-select';
import ActivitySpinner from '../../components/ActivitySpinner';
import '../../css/realhappy-booking.scss';
import 'react-flags-select/scss/react-flags-select.scss';
import {colors} from '../../styles';
import {translation, validatePhoneNumber, isTimeBlocked, formatPhoneNumber, validateEmail, displayAddressWithoutCountry} from "../../tools";
import {faFrown, faMeh, faSmile} from "@fortawesome/free-solid-svg-icons";
import AgentCard from "../../components/AgentCard";
import CompanyCard from "../../components/CompanyCard";
import {WEBSITE_URL} from '../../environmentVariables';
import HeaderAvatar from '../../components/HeaderAvatar';
import Footer from '../../components/Footer';

const qs = require('qs');

const moment = require('moment');
const LocaleCurrency = require('locale-currency');
const height = window.innerHeight;

const language = (getUserLocale() && getUserLocale().length === 5) ? getUserLocale() : 'en_US';
const locale = language.substr(-2).toLowerCase();

const animationIn = 'flipInX';
const animationOut = 'flipOutX';

// Create our number formatter.
const formatter = country => new Intl.NumberFormat(country, {
  maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

const viewingDaySettingsDefault = {
  'Monday': {'showings': true, start: '9.00 am', end: '7.00 pm'},
  'Tuesday': {'showings': true, start: '9.00 am', end: '7.00 pm'},
  'Wednesday': {'showings': true, start: '9.00 am', end: '7.00 pm'},
  'Thursday': {'showings': true, start: '9.00 am', end: '7.00 pm'},
  'Friday': {'showings': true, start: '9.00 am', end: '7.00 pm'},
  'Saturday': {'showings': true, start: '9.00 am', end: '7.00 pm'},
  'Sunday': {'showings': true, start: '9.00 am', end: '7.00 pm'},
};

class BookViewing extends Component {
  constructor(props) {
    super(props);
    this.userId = props.match.params.key;
    this.propId = props.match.params.prop;
    const params = qs.parse(props.location.search, { ignoreQueryPrefix: true });
    this.min = params.min;
    this.stepPageStyle = {
      paddingTop: 10,
      paddingBottom: 10,
      paddingLeft: this.min ? 0 : 20,
      paddingRight: this.min ? 0 : 20,
      marginLeft: 'auto',
      marginRight: 'auto',
      maxWidth: 500,
      backgroundColor: this.min ? '#FFFFFF' : '#F8F8F8'
    };
  }

  state = {
    user: {},
    properties: [],
    searchTerm: '',
    step: 1,
    selectedProperty: null,
    selectedDate: null,
    selectedTime: null,
    name: '',
    phoneNumber: '',
    email: '',
    iso2: locale,
    viewingDaySettings: viewingDaySettingsDefault,
    times: [],
    agent: 'agent',
    visitors: [],
    dre: '',
    currentQuestionIndex: 0,
    answers: [],
    questionAnimation: animationIn,
    feedback: null,
    addingQualificationFeedback: false,
    borrowingReferral: false,
    qualificationPassed: false,
    selectValue: '',
    textValue: '',
  };

  UNSAFE_componentWillMount() {
    this.props.getUser(this.userId);
    this.props.getPropertiesByUser(this.userId);
    this.props.getTeamsData(this.userId);
    this.props.getFeedbackFormsByUser(this.userId);
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.user && nextProps.user !== this.props.user){
      this.setState({
        user: nextProps.user,
        viewingDaySettings: nextProps.user.viewingDaySettings ? nextProps.user.viewingDaySettings : viewingDaySettingsDefault,
        primaryConsumerColor: nextProps.user.primaryConsumerColor ? nextProps.user.primaryConsumerColor : '#000000',
      })
    }
    if (nextProps.properties && nextProps.properties !== this.props.properties){
      this.setState({ properties: nextProps.properties }, () => {
        if (this.propId) {
          this.setState({
            selectedProperty: this.state.properties.find(prop => prop.id === this.propId),
            step: 2
          }, () => {
            if (this.state.selectedProperty.viewingDaySettings) {
              this.setState({
                viewingDaySettings: this.state.selectedProperty.viewingDaySettings
              })
            }
          })
        }
      })
    }
    if (nextProps.qualificationFeedback && nextProps.qualificationFeedback !== this.props.qualificationFeedback){
      this.setState({
        addingQualificationFeedback: nextProps.addingQualificationFeedback,
        feedback: nextProps.qualificationFeedback,
      })
    }
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextState.selectedProperty && nextState.selectedProperty !== this.state.selectedProperty) {
      this.props.getViewingsByProperty(nextState.selectedProperty)
    }
    if (nextState.selectedDate && nextState.selectedDate !== this.state.selectedDate) {
      const dayOfWeek = moment(nextState.selectedDate).format('dddd');
      const viewingDaySettings = this.state.viewingDaySettings[dayOfWeek];
      let time = moment(nextState.selectedDate.set('hour', moment(viewingDaySettings.start, 'h:mm a').format('H')).set('minute', moment(viewingDaySettings.start, 'h:mm a').format('m')));
      const endTime = moment(nextState.selectedDate.set('hour', moment(viewingDaySettings.end, 'h:mm a').format('H')).set('minute', moment(viewingDaySettings.end, 'h:mm a').format('m')));
      let times = [];
      while (time <= endTime) {
        times.push(time);
        time = moment(time).add(15, 'minutes');
      }
      this.setState({times})
    }
    if (nextState.currentQuestionIndex !== this.state.currentQuestionIndex) {
      if (nextState.currentQuestionIndex === this.numberOfQuestions()) {
        this.setState({step: 6, qualificationPassed: this.qualificationPassed()}, () => {
          if (this.qualificationPassed()) {
            this.props.addViewingRequest(this.state);
            this.props.updateQualification(this.state, true);
          } else {
            this.props.updateQualification(this.state, false);
          }
        });
      } else {
        this.setState({questionAnimation: animationIn});
      }
    }
  }

  areTimesAvailable(selectedDate) {
    let timesAvailable = false;
    this.state.times.map(time => {
      if (!isTimeBlocked(time, this.props.viewings, this.props.user, this.state.viewingDaySettings, selectedDate, this.state.selectedProperty)) {
        timesAvailable = true;
      }
    });
    return timesAvailable;
  }

  validate(field, value) {
    if (field === 'name') {
      return value.length > 3
    }
    if (field === 'phoneNumber') {
      return validatePhoneNumber(value, this.state.iso2)
    }
    if (field === 'email') {
      return validateEmail(value)
    }
    return false;
  }

  filterProperties() {
    let properties = this.state.properties;
    if (this.state.searchTerm.length > 0) {
      properties = properties.filter(property => property.address.toLowerCase().indexOf(this.state.searchTerm.toLowerCase()) !== -1)
    }
    properties = properties.sort(
      (a, b) =>
        a.address.localeCompare(b.address),
    );
    return properties
  }

  updateVisitorValue(index, key, value) {
    let visitors = this.state.visitors;
    visitors[index][key] = value;
    this.setState({visitors});
  }

  requiresQualification() {
    if (this.state.selectedProperty.requiresQualification !== undefined) {
      return this.state.selectedProperty.requiresQualification
    } else {
      if (this.state.user.requiresQualification !== undefined) {
        return this.state.user.requiresQualification
      }
    }

    return false;
  }

  requiresAgentOnly() {
    if (this.state.selectedProperty.agentOnlyViewing !== undefined) {
      return this.state.selectedProperty.agentOnlyViewing
    } else {
      if (this.state.user.agentOnlyViewing !== undefined) {
        return this.state.user.agentOnlyViewing
      }
    }
    if (this.state.selectedProperty.agentOnlyViewing === undefined && this.state.user.agentOnlyViewing === undefined) {
      return false
    }

    return true;
  }

  qualificationPassed() {
    let finalPass = true;
    this.state.feedback.questions.map((question, index) => {
      if (question.type === 'boolean') {
        if (question.pass !== this.state.answers[index]) {
          finalPass = false;
        }
      }
      if (question.type === 'text') {
        if (this.state.answers[index].length === 0) {
          finalPass = false;
        }
      }
      if (question.type === 'multipleChoice') {
        if (question.choices) {
          question.choices.map(choice => {
            if (choice.text === this.state.answers[index] && !choice.pass) {
              finalPass = false;
            }
          })
        }
      }
    });
    return finalPass;
  }

  numberOfQuestions() {
    return this.state.feedback.questions.length;
  }

  numberOfAnswers () {
    return this.state.feedback.answers.length;
  }

  ratingAnswer(rating) {
    this.setState({questionAnimation: animationOut}, () => setTimeout(() => {
      let answers = this.state.answers;
      let totalRating = this.state.totalRating + rating;
      let ratingCount = this.state.ratingCount + 1;
      let avgRating = parseFloat((totalRating / ratingCount).toFixed(1));
      let currentQuestionIndex = this.state.currentQuestionIndex + 1;
      answers.push(rating);
      this.setState({
        answers,
        totalRating,
        ratingCount,
        avgRating,
        currentQuestionIndex
      }, () => this.checkAndSend(this.state))
    }, 500));
  }

  booleanAnswer(value) {
    this.setState({questionAnimation: animationOut}, () => setTimeout(() => {
      let answers = this.state.answers;
      let currentQuestionIndex = this.state.currentQuestionIndex + 1;
      answers.push(value);
      this.setState({
        answers,
        currentQuestionIndex
      }, () => this.checkAndSend(this.state))
    }, 500));
  }

  textAnswer(text) {
    this.setState({questionAnimation: animationOut}, () => setTimeout(() => {
      let answers = this.state.answers;
      let currentQuestionIndex = this.state.currentQuestionIndex + 1;
      answers.push(text);
      this.setState({
        answers,
        currentQuestionIndex
      }, () => this.checkAndSend(this.state))
    }, 500));
  }

  choiceAnswer(value) {
    this.setState({questionAnimation: animationOut}, () => setTimeout(() => {
      let answers = this.state.answers;
      let currentQuestionIndex = this.state.currentQuestionIndex + 1;
      answers.push(value);
      this.setState({
        answers,
        currentQuestionIndex
      }, () => this.checkAndSend(this.state))
    }, 500));
  }

  checkAndSend() {
    if (this.state.currentQuestionIndex === 1) {
      this.props.updateFeedback(this.state, this.state.feedback.id);
    } else {
      this.props.updateSubsequentFeedback(this.state, this.state.feedback.id);
    }
  }

  renderProperties(properties) {
    return properties.map(property => {
      let price = isNaN(property.price) ? 0 : property.price
      return (
        <div
          onClick={() => window.location.href = '/b/' + this.userId + '/' + property.id}
          style={{display: 'flex', flex: 1, paddingTop: 20, paddingBottom: 20, borderBottom: '1px solid #EEEEEE', width: '100%', cursor: 'pointer'}}>
          <div style={{display: 'flex', flex: 1, flexDirection: 'row'}}>
            <div style={{display: 'flex', flex: 6, flexDirection: 'column'}}>
              {(!!price || !!property.beds || !!property.baths || !!property.sqFootage) && (
                <div style={{display: 'flex', flexDirection: 'row', flex: 1, marginBottom: 10}}>
                  {!!price && (
                    <div style={{backgroundColor: '#F8F8F8', borderRadius: 5, fontSize: 19, fontWeight: 'bold', marginRight: 12, paddingTop: 0}}>{getSymbolFromCurrency(LocaleCurrency.getCurrency(this.state.user.iso2)) + formatter(this.state.user.iso2).format(price)}</div>
                  )}
                  {!!property.beds && !isNaN(property.beds) && (
                    <div style={{marginRight: 12}}>
                      <div style={{backgroundColor: '#EEEEEE', borderRadius: 5, paddingTop: 3, paddingBottom: 3, paddingLeft: 4, paddingRight: 4, justifyContent: 'center', textAlign: 'center'}}>{property.beds} Bd</div>
                    </div>
                  )}
                  {!!property.baths && !isNaN(property.baths) && (
                    <div style={{marginRight: 12}}>
                      <div style={{backgroundColor: '#EEEEEE', borderRadius: 5, paddingTop: 3, paddingBottom: 3, paddingLeft: 4, paddingRight: 4, justifyContent: 'center', textAlign: 'center'}}>{property.baths} Ba</div>
                    </div>
                  )}
                  {!!property.sqFootage && !isNaN(property.sqFootage) && (
                    <div style={{marginRight: 0}}>
                      <div style={{backgroundColor: '#EEEEEE', borderRadius: 5, paddingTop: 3, paddingBottom: 3, paddingLeft: 4, paddingRight: 4, justifyContent: 'center', textAlign: 'center'}}>{property.sqFootage} Sq {translation('ft', this.state.iso2, false)}</div>
                    </div>
                  )}
                </div>
              )}
              <div>{property.address}</div>
            </div>
            <div style={{display: 'flex', flex: 1, justifyContent: 'flex-end', paddingTop: 8}}>
              <FontAwesomeIcon icon={faChevronRight} style={{fontSize: '18px', color: '#3D435B'}}/>
            </div>
          </div>
        </div>
      )
    })
  }

  renderDates() {
    let dateArray = [];
    let currentDate = moment();
    let endDate = moment().add(6, 'months');
    while (currentDate <= endDate) {
      dateArray.push(currentDate);
      currentDate = moment(currentDate).add(1, 'days');
    }
    return dateArray.map(date => {
      return (
        <div
          onClick={() => this.setState({selectedDate: date, selectedTime: null})}
          style={{
            height: 80,
            border: moment(this.state.selectedDate).isSame(moment(date), 'day') ? '1px solid ' + this.state.primaryConsumerColor : '1px solid #DDDDDD',
            backgroundColor: moment(this.state.selectedDate).isSame(moment(date), 'day') ? this.state.primaryConsumerColor : colors.white,
            borderRadius: 5,
            marginRight: 10,
            textAlign: 'center',
            justifyContent: 'center',
            paddingTop: 6,
            minWidth: 80,
            cursor: 'pointer',
          }}>
          <p
            style={{
              fontSize: 18,
              lineHeight: '22px',
              color: moment(this.state.selectedDate).isSame(moment(date), 'day') ? colors.white : this.state.primaryConsumerColor,
            }}
          >
            {date.format('ddd').toUpperCase()}
          </p>
          <p
            style={{
              fontSize: 18,
              fontWeight: 'bold',
              lineHeight: '24px',
              color: moment(this.state.selectedDate).isSame(moment(date), 'day') ? colors.white : this.state.primaryConsumerColor,
            }}
          >
            {date.format('D')}
          </p>
          <p
            style={{
              fontSize: 14,
              lineHeight: '20px',
              color: moment(this.state.selectedDate).isSame(moment(date), 'day') ? colors.white : this.state.primaryConsumerColor,
            }}
          >
            {date.format('MMM').toUpperCase()}
          </p>
        </div>
      )
    })
  }

  renderTimes() {
    if (this.props.loadingViewings) {
      return (
        <div className="content-holder">
          <ActivitySpinner />
        </div>
      );
    }

    if (this.props.loadedViewings) {
      return this.state.times.map(time => {
        let unavailable = isTimeBlocked(time, this.props.viewings, this.props.user, this.state.viewingDaySettings, this.state.selectedDate, this.state.selectedProperty);
        return (
          <div
            onClick={() => unavailable ? {} : this.setState({selectedTime: time})}
            style={{
              minWidth: height < 569 ? 80 : 100,
              display: 'flex',
              height: height < 569 ? 25 : 35,
              border: moment(this.state.selectedTime).isSame(moment(time), 'minute') ? '1px solid ' + this.state.primaryConsumerColor : '1px solid #DDDDDD',
              backgroundColor: moment(this.state.selectedTime).isSame(moment(time), 'minute') ? this.state.primaryConsumerColor : unavailable ? '#DDDDDD' : colors.white,
              borderRadius: 5,
              alignItems: 'center',
              justifyContent: 'center',
              marginBottom: 8,
              cursor: 'pointer',
            }}
          >
            <span style={{color: moment(this.state.selectedTime).isSame(moment(time), 'minute') ? colors.white : unavailable ? '#AAAAAA' : this.state.primaryConsumerColor}}>
              {moment(time).format('h:mm a')}
            </span>
          </div>
        )
      })
    }

    return null;
  }

  renderUserInput() {
    return (
      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', width: '100%'}}>
        <div style={{marginBottom: 15, width: '80%', display: 'flex', flexDirection: 'column'}}>
          <div style={{marginBottom: 5}}>
            <span style={{fontSize: 16}}>Your full name *</span>
          </div>
          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <input
              type={'text'}
              style={{width: '100%', height: 40, fontSize: 16, borderRadius: 5, border: '1px solid #DDDDDD', paddingLeft: 10}}
              placeholder={'Full name'}
              onChange={event => this.setState({name: event.target.value})}
              onBlur={() => this.mobileInput.focus()}
            />
          </div>
        </div>
        <div style={{marginBottom: 15, width: '80%', display: 'flex', flexDirection: 'column'}}>
          <div style={{marginBottom: 5}}>
            <span style={{fontSize: 16}}>Your mobile number *</span>
          </div>
          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <div style={{borderRadius: 5, border: '1px solid #DDDDDD', backgroundColor: colors.white, height: 44, marginRight: 4}}>
              <ReactFlagsSelect
                defaultCountry={this.state.iso2.toUpperCase()}
                showSelectedLabel={false}
                onSelect={(countryCode) => this.setState({iso2: countryCode.toLowerCase()}, () => console.log(this.state))}
                searchable={true}
                selectedSize={24}
              />
            </div>
            <input
              ref={ref => this.mobileInput = ref}
              type={'tel'}
              style={{width: '100%', height: 40, fontSize: 16, borderRadius: 5, border: '1px solid #DDDDDD', paddingLeft: 10}}
              placeholder={'Mobile number'}
              onChange={event => this.setState({phoneNumber: event.target.value.replace(/[^\d\+\(\)\-\.\s]/gim, '')})}
              value={this.state.phoneNumber}
              autoComplete={'tel'}
              onBlur={() => this.emailInput.focus()}
            />
          </div>
        </div>
        <div style={{marginBottom: 15, width: '80%', display: 'flex', flexDirection: 'column'}}>
          <div style={{marginBottom: 5}}>
            <span style={{fontSize: 16}}>Your email address *</span>
          </div>
          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <input
              ref={ref => this.emailInput = ref}
              type={'email'}
              style={{width: '100%', height: 40, fontSize: 16, borderRadius: 5, border: '1px solid #DDDDDD', paddingLeft: 10}}
              placeholder={'Email'}
              onChange={event => this.setState({email: event.target.value})}
            />
          </div>
        </div>
      </div>
    )
  }

  renderAgentQuestion() {
    return (
      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', width: '100%'}}>
        <div style={{marginBottom: 15, width: '80%', display: 'flex', flexDirection: 'column'}}>
          <div style={{marginBottom: 5}}>
            <span style={{fontSize: 16}}>Which best describes you?</span>
          </div>
          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <select
              style={{width: '100%', height: 40, fontSize: 16, borderRadius: 5, border: '1px solid #DDDDDD', paddingLeft: 10}}
              onChange={(event) => this.setState({agent: event.target.value})}
            >
              <option value={'agent'}>Agent</option>
              <option value={'lead'}>Buyer/Tenant</option>
              <option value={'other'}>Other</option>
            </select>
          </div>
        </div>
      </div>
    )
  }

  renderVisitorSection() {
    let visitors = this.state.visitors;
    return (
      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', width: '100%'}}>
        <div style={{marginBottom: 15, width: '80%', display: 'flex', flexDirection: 'column'}}>
          <div style={{marginBottom: 5}}>
            <span style={{fontSize: 16}}>Full name *</span>
          </div>
          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <input
              type={'text'}
              style={{width: '100%', height: 40, fontSize: 16, borderRadius: 5, border: '1px solid #DDDDDD', paddingLeft: 10}}
              placeholder={'Full name'}
              onChange={event => this.setState({name: event.target.value})}
              value={this.state.name}
            />
          </div>
        </div>
        {(this.state.agent === 'agent' || this.state.agent === 'broker') && (
          <div style={{marginBottom: 15, width: '80%', display: 'flex', flexDirection: 'column'}}>
            <div style={{marginBottom: 5}}>
              <span style={{fontSize: 16}}>DRE License #</span>
            </div>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
              <input
                type={'text'}
                style={{width: '100%', height: 40, fontSize: 16, borderRadius: 5, border: '1px solid #DDDDDD', paddingLeft: 10}}
                placeholder={'DRE License #'}
                onChange={event => this.setState({dre: event.target.value})}
              />
            </div>
          </div>
        )}
        <div style={{marginBottom: 15, width: '80%', display: 'flex', flexDirection: 'column'}}>
          <div style={{marginBottom: 5}}>
            <span style={{fontSize: 16}}>Your email address *</span>
          </div>
          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <input
              type={'text'}
              style={{width: '100%', height: 40, fontSize: 16, borderRadius: 5, border: '1px solid #DDDDDD', paddingLeft: 10}}
              placeholder={'Email address'}
              onChange={event => this.setState({email: event.target.value})}
            />
          </div>
        </div>
        {this.renderVisitors()}
        <div>
          <span
            style={{cursor: 'pointer'}}
            onClick={() => {
              visitors.push({name: '', phoneNumber: '', iso2: 'us'});
              this.setState({visitors})
            }}>+ Add another visitor</span>
        </div>
      </div>
    )
  }

  renderVisitors() {
    return this.state.visitors.map((visitor, index) => {
      return (
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', width: '100%'}}>
          <div style={{marginBottom: 15, width: '80%', display: 'flex', flexDirection: 'column', borderTop: '1px solid #EEEEEE', paddingTop: 20}}>
            <div style={{marginBottom: 5}}>
              <span style={{fontSize: 16}}>Visitor {index+1} Full name *</span>
            </div>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
              <input
                type={'text'}
                style={{width: '100%', height: 40, fontSize: 16, borderRadius: 5, border: '1px solid #DDDDDD', paddingLeft: 10}}
                placeholder={'Full name'}
                onChange={event => this.updateVisitorValue(index, 'name', event.target.value)}
                value={visitor.name}
              />
            </div>
          </div>
          <div style={{marginBottom: 15, width: '80%', display: 'flex', flexDirection: 'column'}}>
            <div style={{marginBottom: 5}}>
              <span style={{fontSize: 16}}>Visitor {index+1} Email address *</span>
            </div>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
              <input
                type={'text'}
                style={{width: '100%', height: 40, fontSize: 16, borderRadius: 5, border: '1px solid #DDDDDD', paddingLeft: 10}}
                placeholder={'Email address'}
                onChange={event => this.updateVisitorValue(index, 'email', event.target.value)}
                value={visitor.email}
              />
            </div>
          </div>
          <div style={{marginBottom: 15, width: '80%', display: 'flex', flexDirection: 'column'}}>
            <div style={{marginBottom: 5}}>
              <span style={{fontSize: 16}}>Visitor {index+1} DRE license # (if agent/broker)</span>
            </div>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
              <input
                type={'text'}
                style={{width: '100%', height: 40, fontSize: 16, borderRadius: 5, border: '1px solid #DDDDDD', paddingLeft: 10}}
                placeholder={'DRE License #'}
                onChange={event => this.updateVisitorValue(index, 'dre', event.target.value)}
                value={visitor.dre}
              />
            </div>
          </div>
          <div style={{marginBottom: 15, width: '80%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end'}}>
            <span
              style={{color: colors.negative, textAlign: 'right', cursor: 'pointer'}}
              onClick={() => {
                let visitors = this.state.visitors;
                visitors.splice(index, 1);
                this.setState({visitors})
              }}>- Delete</span>
          </div>
        </div>
      )
    })
  }

  renderQuestions() {
    return (
      <div className={'animated ' + this.state.questionAnimation} style={{marginTop: 20, marginBottom: 20, marginLeft: 10, marginRight: 10, padding: 20, backgroundColor: "#FFFFFF", borderRadius: 9}}>
        <div style={{marginBottom: 30}}>
          <p style={{fontSize: '18px', color: colors.question, textAlign: 'center'}}>{this.state.currentQuestionIndex + 1}. {this.state.feedback.questions[this.state.currentQuestionIndex].question}</p>
        </div>
        {this.renderAnswerChoices()}
      </div>
    )
  }

  renderAnswerChoices() {
    let thisQuestion = this.state.feedback.questions[this.state.currentQuestionIndex];
    switch (thisQuestion.type) {
      case "rating":
        return (
          <div style={{display: 'flex', flexDirection: 'row'}}>
            <div style={{display: 'flex', width: '20%', justifyContent: 'center'}} onClick={() => this.ratingAnswer(5)}>
              <FontAwesomeIcon icon={faSmile} style={{fontSize: '40px', color: colors.vhappy}}/>
            </div>
            <div style={{display: 'flex', width: '20%', justifyContent: 'center'}} onClick={() => this.ratingAnswer(4)}>
              <FontAwesomeIcon icon={faSmile} style={{fontSize: '40px', color: colors.happy}}/>
            </div>
            <div style={{display: 'flex', width: '20%', justifyContent: 'center'}} onClick={() => this.ratingAnswer(3)}>
              <FontAwesomeIcon icon={faMeh} style={{fontSize: '40px', color: colors.neutral}}/>
            </div>
            <div style={{display: 'flex', width: '20%', justifyContent: 'center'}} onClick={() => this.ratingAnswer(2)}>
              <FontAwesomeIcon icon={faFrown} style={{fontSize: '40px', color: colors.sad}}/>
            </div>
            <div style={{display: 'flex', width: '20%', justifyContent: 'center'}} onClick={() => this.ratingAnswer(1)}>
              <FontAwesomeIcon icon={faFrown} style={{fontSize: '40px', color: colors.vsad}}/>
            </div>
          </div>
        );
      case "boolean":
        return (
          <div style={{display: 'flex', flexDirection: 'row'}}>
            <div style={{display: 'flex', width: '50%', justifyContent: 'center'}}>
              <div style={{display: 'flex', width: 100, justifyContent: 'center', height: 40, backgroundColor: colors.positive, borderRadius: 5, paddingTop: 11}}
                   onClick={() => this.booleanAnswer(true)}
              >
                <span style={{color: '#FFFFFF'}}>YES</span>
              </div>
            </div>
            <div style={{display: 'flex', width: '50%', justifyContent: 'center'}}>
              <div style={{display: 'flex', width: 100, justifyContent: 'center', height: 40, backgroundColor: colors.negative, borderRadius: 5, paddingTop: 11}}
                   onClick={() => this.booleanAnswer(false)}
              >
                <span style={{color: '#FFFFFF'}}>NO</span>
              </div>
            </div>
          </div>
        );
      case "text":
        this.text = [];
        return (
          <div>
            <textarea
              style={{borderWidth: 1, borderColor: '#DEDEDE', width: "100%", height: 80, borderRadius: 5, fontSize: 16 }}
              ref={(ref) => this.text = ref}
              onChange={event => this.setState({textValue: event.target.value})}
            ></textarea>
            <div style={{display: 'flex', width: '100%', justifyContent: 'center', marginTop: 20}}>
              {this.state.textValue.length > 0 && (
                <div style={{display: 'flex', width: 100, justifyContent: 'center', height: 40, backgroundColor: this.state.primaryConsumerColor, borderRadius: 5, paddingTop: 11}}
                     onClick={() => { this.textAnswer(this.text.value); this.text.value = ''; this.setState({textValue: ''})}}
                >
                  <span style={{color: '#FFFFFF'}}>NEXT</span>
                </div>
              )}
            </div>
          </div>
        );
      case "multipleChoice":
        return (
          <div style={{display: 'flex', flexDirection: 'column'}}>
            <select
              style={{width: '100%', height: 40, fontSize: 16, borderRadius: 5, border: '1px solid #DDDDDD', paddingLeft: 10}}
              onChange={(event) => this.setState({selectValue: event.target.value})}
            >
              <option value={''}>Select</option>
              {thisQuestion.choices.map(choice => (
                <option value={choice.text}>{choice.text}</option>
              ))}
            </select>
            {this.state.selectValue.length > 0 && (
              <div style={{display: 'flex', justifyContent: 'center'}}>
                <div style={{display: 'flex', width: 100, justifyContent: 'center', height: 40, backgroundColor: this.state.primaryConsumerColor, borderRadius: 5, paddingTop: 11, marginTop: 10}}
                     onClick={() => { this.choiceAnswer(this.state.selectValue); this.setState({selectValue: ''})}}
                >
                  <span style={{color: '#FFFFFF'}}>NEXT</span>
                </div>
              </div>
            )}
          </div>
        );
      default:
        return null;
    }
  }

  renderCounter() {
    return (
      <div>
        <div style={{marginTop: 20, marginBottom: 20, marginLeft: 10, marginRight: 10, backgroundColor: "#FFFFFF", borderRadius: 9, height: 40}}>
          <div style={{display: 'flex', justifyContent: 'flex-end', paddingTop: 13, paddingRight: 10, backgroundColor: '#E9EAED', borderRadius: 9, height: 40, width: ((this.state.currentQuestionIndex + 1) / this.numberOfQuestions() * 100) + '%'}}>
            <span style={{fontSize: 12, fontWeight: 'bold', height: 15}}>{(this.state.currentQuestionIndex + 1)} of {this.numberOfQuestions()}</span>
          </div>
        </div>
      </div>
    )
  }

  renderHeader() {
    if (this.min) {
      return null;
    }
    return (
      <div style={{height: (this.state.step === 1 || this.state.step === 2) ? 150 : (this.state.step === 3 || this.state.step === 5)? 10 : 110}}>
        {this.state.step !== 3 && this.state.step !== 5 && (
          <div style={{display: 'flex', alignItems: 'center', marginBottom: 15, justifyContent: 'center'}}>
            <HeaderAvatar user={this.state.user} />
          </div>
        )}
        {(this.state.step === 1) && (
          <div style={{display: 'flex', alignItems: 'center', marginBottom: 15, justifyContent: 'center'}}>
            <span style={{fontSize: 22}}>Select a property</span>
          </div>
        )}
        {(this.state.step === 2) && (
          <div style={{display: 'flex', alignItems: 'center', marginBottom: 15, justifyContent: 'center'}}>
            <span style={{fontSize: 22}}>Request a {translation('viewing', this.state.iso2, false)}</span>
          </div>
        )}
      </div>
    )
  }

  renderPropertiesLink() {
    if (this.min) {
      return null;
    }
    return (
      <div
        style={{display: 'flex', justifyContent: 'center', height: 90, paddingTop: 40, fontSize: 14, cursor: 'pointer'}}
        onClick={() => window.location.href = '/b/' + this.state.user.id}
      >
        VIEW ALL PROPERTIES&nbsp;&nbsp;{'>'}
      </div>
    )
  }

  renderStepOne() {
    document.title = (this.state.user.detailsToShow && this.state.user.detailsToShow === 'company' && this.state.user.company ? this.state.user.company : (this.state.user.companyName || this.state.user.name)) + ' | Properties';

    if (this.props.loadingProperties && this.props.properties.length === 0) {
      return (
        <div className="content-holder">
          <ActivitySpinner />
        </div>
      );
    }

    if (this.props.loadedProperties && this.props.properties.length === 0) {
      return (
        <div>
          <div style={this.stepPageStyle}>
            {this.renderHeader()}
            <div>
              <div style={{height: 100}}>
                <div style={{display: 'flex', alignItems: 'center', marginBottom: 15, justifyContent: 'center'}}>
                  <span style={{fontSize: 14, fontWeight: 'bold'}}>{this.state.user.detailsToShow && this.state.user.detailsToShow === 'company' && this.state.user.company ? this.state.user.company : this.props.teamName ? this.props.companyName + ' - ' + this.props.teamName : this.state.user.name}</span>
                </div>
                <div style={{display: 'flex', alignItems: 'center', marginBottom: 15, justifyContent: 'center'}}>
                  <input
                    type={'text'}
                    name={'search'}
                    id={'search'}
                    style={{
                      height: 40,
                      width: '80%',
                      backgroundColor: '#EEEEEE',
                      textAlign: 'center',
                      border: 'none',
                      fontSize: 16
                    }}
                    placeholder={'Search properties'}
                    onChange={event => this.setState({'searchTerm': event.target.value})}
                    value={this.state.searchTerm}
                  />
                </div>
              </div>
              <div style={{
                flexDirection: 'column',
                alignItems: 'center',
                marginBottom: 20,
                justifyContent: 'center',
                paddingBottom: 50,
                borderTop: '1px solid #EEEEEE',
              }}>
                <div style={{alignItems: 'center', paddingTop: 20, textAlign: 'center'}}>
                  Sorry, currently there are no properties available
                </div>
              </div>
            </div>
          </div>
          <div>
            <Footer min={this.min}/>
          </div>
        </div>
      )
    }

    if (this.props.loadedProperties && this.props.properties.length > 0 && this.props.user && this.props.user.id) {
      return (
        <div>
          <div style={this.stepPageStyle}>
            {this.renderHeader()}
            <div>
              <div style={{height: 100}}>
                <div style={{display: 'flex', alignItems: 'center', marginBottom: 15, justifyContent: 'center'}}>
                  <span style={{fontSize: 14, fontWeight: 'bold'}}>{this.state.user.detailsToShow && this.state.user.detailsToShow === 'company' && this.state.user.company ? this.state.user.company : this.props.teamName ? this.props.companyName + ' - ' + this.props.teamName : this.state.user.name}</span>
                </div>
                <div style={{display: 'flex', alignItems: 'center', marginBottom: 15, justifyContent: 'center'}}>
                  <input
                    type={'text'}
                    name={'search'}
                    id={'search'}
                    style={{
                      height: 40,
                      width: '80%',
                      backgroundColor: '#EEEEEE',
                      textAlign: 'center',
                      border: 'none',
                      fontSize: 16
                    }}
                    placeholder={'Search properties'}
                    onChange={event => this.setState({'searchTerm': event.target.value})}
                    value={this.state.searchTerm}
                  />
                </div>
              </div>
              <div style={{
                flexDirection: 'column',
                alignItems: 'center',
                marginBottom: 20,
                justifyContent: 'center',
                paddingBottom: 50,
                borderTop: '1px solid #EEEEEE',
              }}>
                {this.renderProperties(this.filterProperties())}
              </div>
              <div style={{marginBottom: 10}}>
                <AgentCard user={this.state.user} color={this.state.primaryConsumerColor} />
                <CompanyCard user={this.state.user} />
              </div>
            </div>
          </div>
          <div>
            <Footer min={this.min}/>
          </div>
        </div>
      );
    }

    return null;
  }

  renderStepTwo() {
    if (this.state.selectedProperty) {
      document.title = this.state.selectedProperty.address + ' | ' + translation('viewing', this.state.iso2, true) + ' booking | Step 2';
    } else {
      document.title = 'Property not available | ' + translation('viewing', this.state.iso2, true) + ' booking | Step 2';
    }

    let timesAvailable = this.areTimesAvailable(this.state.selectedDate);

    return (
      <div>
        <div style={this.stepPageStyle}>
          {this.renderHeader()}
          <div>
            <div style={{height: this.min ? 30 : 75}}>
              {this.state.selectedProperty ? (
                <div>
                  <div style={{display: 'flex', alignItems: 'center', marginBottom: 15, justifyContent: 'center', textAlign: 'center'}}>
                    <span style={{fontSize: 16, fontWeight: 'bold'}}>{displayAddressWithoutCountry(this.state.selectedProperty.address)}</span>
                  </div>
                  {!this.min && (
                    <div style={{display: 'flex', alignItems: 'center', marginBottom: 15, justifyContent: 'center'}}>
                      <span style={{cursor: 'pointer'}} onClick={() => this.setState({step: 1})}>VIEW ALL PROPERTIES {'>'}</span>
                    </div>
                  )}
                </div>
              ) : (
                <div>
                  <div style={{display: 'flex', alignItems: 'center', marginBottom: 15, justifyContent: 'center', textAlign: 'center'}}>
                    <span style={{fontSize: 14, fontWeight: 'bold'}}>Property no longer available</span>
                  </div>
                  {!this.min && (
                    <div style={{display: 'flex', alignItems: 'center', marginBottom: 15, justifyContent: 'center'}}>
                      <span style={{cursor: 'pointer'}} onClick={() => this.setState({step: 1})}>VIEW ALL PROPERTIES {'>'}</span>
                    </div>
                  )}
                </div>
              )}
            </div>
            {this.state.selectedProperty && (
              <div>
                <div style={{alignItems: 'center', justifyContent: 'center', borderTop: '1px solid #EEEEEE', paddingTop: 15}}>
                  <div style={{display: 'flex', alignItems: 'center', marginBottom: 15, justifyContent: 'center'}}>
                    <span>Select your preferred date</span>
                  </div>
                  <div style={{display: 'flex', justifyContent: 'center'}}>
                    <div
                      onClick={() => document.getElementById('dates').scrollLeft = document.getElementById('dates').scrollLeft - 90}
                      style={{display: 'flex', paddingTop: 30, paddingBottom: 30, paddingLeft: 10, paddingRight: 10, minWidth: 18, textAlign: 'left', justifyContent: 'center', cursor: 'pointer'}}>
                      <FontAwesomeIcon icon={faChevronLeft} style={{fontSize: '18px', color: '#3D435B'}}/>
                    </div>
                    <div
                      id={'dates'}
                      style={{
                        display: 'flex',
                        width: 260,
                        overflow: 'hidden',
                      }}
                    >
                      {this.renderDates()}
                    </div>
                    <div
                      onClick={() => document.getElementById('dates').scrollLeft = document.getElementById('dates').scrollLeft + 90}
                      style={{display: 'flex', paddingTop: 30, paddingBottom: 30, paddingLeft: 10, paddingRight: 10, minWidth: 18, textAlign: 'right', justifyContent: 'center'}}>
                      <FontAwesomeIcon icon={faChevronRight} style={{fontSize: '18px', color: '#3D435B'}}/>
                    </div>
                  </div>
                </div>
                <div style={{marginTop: 20, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                  {timesAvailable ? (
                    /*<div
                      onClick={() => this.state.selectedDate ? this.setState({step: 3}) : {}}
                      style={{display: 'flex', width: 150, height: 40, justifyContent: 'center', borderRadius: 5, backgroundColor: this.state.selectedDate ? this.state.primaryConsumerColor : '#DDDDDD', alignItems: 'center', cursor: 'pointer'}}
                    >
                      <span style={{color: colors.white, textAlign: 'center'}}>CONTINUE</span>
                    </div>*/
                    <div>
                      <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'space-evenly', paddingBottom: 80}}>
                        {this.renderTimes()}
                      </div>
                      <div style={{display: 'flex', flex: 1, position: 'sticky', bottom: 0, backgroundColor: 'white', paddingTop: 6, paddingBottom: 8}}>
                        <div style={{marginTop: 10, display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: 'auto', marginLeft: 'auto'}}>
                          <div
                            onClick={() => this.state.selectedTime ? this.setState({step: 4}) : {}}
                            style={{display: 'flex', width: 150, height: 40, justifyContent: 'center', borderRadius: 5, backgroundColor: this.state.selectedTime ? this.state.primaryConsumerColor : '#DDDDDD', alignItems: 'center', cursor: 'pointer'}}
                          >
                            <span style={{color: colors.white, textAlign: 'center'}}>CONTINUE</span>
                          </div>
                        </div>
                      </div>
                      
                    </div>
                  ) : this.state.selectedDate && (
                    <p style={{textAlign: 'center'}}>Sorry there are no times available on this date</p>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
        <Footer min={this.min}/>
      </div>
    );
  }

  renderStepThree() {
    document.title = displayAddressWithoutCountry(this.state.selectedProperty.address) + ' | ' + translation('viewing', this.state.iso2, true) + ' booking | Step 3';

    return (
      <div>
        <div style={this.stepPageStyle}>
          {this.renderHeader()}
          <div>
            <div style={{height: 75}}>
              <div style={{display: 'flex', alignItems: 'center', marginBottom: 15, justifyContent: 'center', textAlign: 'center'}}>
                <span style={{fontSize: 16, fontWeight: 'bold'}}>{displayAddressWithoutCountry(this.state.selectedProperty.address)}</span>
              </div>
              <div
                onClick={() => this.setState({step: 2})}
                style={{display: 'flex', alignItems: 'center', marginBottom: 15, justifyContent: 'center', cursor: 'pointer'}}
              >
                <span style={{fontSize: 16}}>{'<'}&nbsp;&nbsp;</span>
                <span style={{fontSize: 16}}>{this.state.selectedDate.format('dddd').toUpperCase()}&nbsp;</span>
                <span style={{fontSize: 16, fontWeight: 'bold'}}>{this.state.selectedDate.format('D').toUpperCase()}&nbsp;</span>
                <span style={{fontSize: 16}}>{this.state.selectedDate.format('MMMM').toUpperCase()}</span>
              </div>
            </div>
            <div style={{alignItems: 'center', justifyContent: 'center', borderTop: '1px solid #EEEEEE', paddingTop: 15}}>
              {height > 568 && (
                <div style={{display: 'flex', alignItems: 'center', marginBottom: 15, justifyContent: 'center'}}>
                  <span>Select your preferred time</span>
                </div>
              )}
              <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'space-evenly'}}>
                {this.renderTimes()}
              </div>
            </div>
            <div style={{marginTop: 10, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
              <div
                onClick={() => this.state.selectedTime ? this.setState({step: 4}) : {}}
                style={{display: 'flex', width: 150, height: 40, justifyContent: 'center', borderRadius: 5, backgroundColor: this.state.selectedTime ? this.state.primaryConsumerColor : '#DDDDDD', alignItems: 'center', cursor: 'pointer'}}
              >
                <span style={{color: colors.white, textAlign: 'center'}}>CONTINUE</span>
              </div>
            </div>
          </div>
          <Footer min={this.min}/>
        </div>
      </div>
    );
  }

  renderStepFour() {
    document.title = displayAddressWithoutCountry(this.state.selectedProperty.address) + ' | ' + translation('viewing', this.state.iso2, true) + ' booking | Step 4';

    return (
      <div>
        <div style={this.stepPageStyle}>
          {this.renderHeader()}
          <div>
            <div style={{height: 105}}>
              <div style={{display: 'flex', alignItems: 'center', marginBottom: 15, justifyContent: 'center', textAlign: 'center'}}>
                <span style={{fontSize: 16, fontWeight: 'bold'}}>{displayAddressWithoutCountry(this.state.selectedProperty.address)}</span>
              </div>
              <div
                style={{display: 'flex', alignItems: 'center', marginBottom: 10, justifyContent: 'center'}}
              >
                <span style={{fontSize: 16}}>{this.state.selectedDate.format('dddd').toUpperCase()}&nbsp;</span>
                <span style={{fontSize: 16, fontWeight: 'bold'}}>{this.state.selectedDate.format('D').toUpperCase()}&nbsp;</span>
                <span style={{fontSize: 16}}>{this.state.selectedDate.format('MMMM').toUpperCase()}</span>
              </div>
              <div
                onClick={() => this.setState({step: 2})}
                style={{display: 'flex', alignItems: 'center', marginBottom: 15, justifyContent: 'center', textAlign: 'center', cursor: 'pointer'}}
              >
                <span style={{fontSize: 16}}>{'<'}&nbsp;&nbsp;</span>
                <span style={{fontSize: 16, fontWeight: 'bold'}}>{moment(this.state.selectedTime).format('h:mm a')}</span>
              </div>
            </div>
            <div style={{alignItems: 'center', justifyContent: 'center', borderTop: '1px solid #EEEEEE', paddingTop: 15}}>
              {this.renderUserInput()}
            </div>
            <div style={{alignItems: 'center', justifyContent: 'center', borderTop: '1px solid #EEEEEE', paddingTop: 15}}>
              {this.renderAgentQuestion()}
            </div>
            <div style={{marginTop: height < 569 ? 10 : 20, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
              {this.state.user && this.requiresAgentOnly() && this.state.agent !== 'agent' ? (
                <div
                  onClick={() => (this.validate('name', this.state.name) && this.validate('phoneNumber', this.state.phoneNumber) && this.validate('email', this.state.email)) ? this.setState({step: 6}) : {}}
                  style={{display: 'flex', width: 150, height: 40, justifyContent: 'center', borderRadius: 5, backgroundColor: (this.validate('name', this.state.name) && this.validate('phoneNumber', this.state.phoneNumber) && this.validate('email', this.state.email)) ? this.state.primaryConsumerColor : '#DDDDDD', alignItems: 'center', cursor: 'pointer'}}
                >
                  <span style={{color: colors.white, textAlign: 'center'}}>CONTINUE</span>
                </div>
              ) : (this.state.user && this.requiresQualification() ? (
                <div
                  onClick={() => (this.validate('name', this.state.name) && this.validate('phoneNumber', this.state.phoneNumber) && this.validate('email', this.state.email)) ? this.setState({step: 7, addingQualificationFeedback: true}, () => this.props.addQualificationFeedback(this.state.user, this.state)) : {}}
                  style={{display: 'flex', width: 150, height: 40, justifyContent: 'center', borderRadius: 5, backgroundColor: (this.validate('name', this.state.name) && this.validate('phoneNumber', this.state.phoneNumber) && this.validate('email', this.state.email)) ? this.state.primaryConsumerColor : '#DDDDDD', alignItems: 'center', cursor: 'pointer'}}
                >
                  <span style={{color: colors.white, textAlign: 'center'}}>CONTINUE</span>
                </div>
              ) : (
                <div
                  onClick={() => (this.validate('name', this.state.name) && this.validate('phoneNumber', this.state.phoneNumber) && this.validate('email', this.state.email)) ? this.setState({step: 6}, () => this.props.addViewingRequest(this.state)) : {}}
                  style={{display: 'flex', width: 150, height: 40, justifyContent: 'center', borderRadius: 5, backgroundColor: (this.validate('name', this.state.name) && this.validate('phoneNumber', this.state.phoneNumber) && this.validate('email', this.state.email)) ? this.state.primaryConsumerColor : '#DDDDDD', alignItems: 'center', cursor: 'pointer'}}
                >
                  <span style={{color: colors.white, textAlign: 'center'}}>REQUEST</span>
                </div>
              ))}
            </div>
          </div>
        </div>
        <Footer min={this.min}/>
      </div>
    );
  }

  renderStepFive() {
    document.title = this.state.selectedProperty.address + ' | ' + translation('viewing', this.state.iso2, true) + ' booking | Step 5';

    return (
      <div>
      </div>
    );
  }

  renderStepSix() {
    document.title = displayAddressWithoutCountry(this.state.selectedProperty.address) + ' | ' + translation('viewing', this.state.iso2, true) + ' booking | Step 6';

    return (
      <div>
        <div style={this.stepPageStyle}>
          {this.renderHeader()}
          <div style={this.min ? {paddingLeft: 20, paddingRight: 20} : {}}>
            <div style={{alignItems: 'center', justifyContent: 'center', borderTop: '1px solid #EEEEEE', paddingTop: 15}}>
              {(this.state.user && this.requiresQualification() && !this.qualificationPassed()) || (this.state.user && this.requiresAgentOnly() && this.state.agent !== 'agent') ? (
                <div>
                  <div style={{display: 'flex', alignItems: 'center', marginBottom: 15, justifyContent: 'center', textAlign: 'center'}}>
                    <div style={{backgroundColor: '#DF5163', borderRadius: 7, padding: 20}}>
                      <span style={{fontSize: 22, color: colors.white}}>We are sorry but you do not meet one or more criteria for this property</span>
                    </div>
                  </div>
                  {this.renderPropertiesLink()}
                  <div style={{display: 'flex', justifyContent: 'center'}}>
                    <div style={{height: 1, width: '80%', backgroundColor: '#EEEEEE'}} />
                  </div>
                  <AgentCard user={this.state.user} color={this.state.primaryConsumerColor} />
                  <CompanyCard user={this.state.user} />
                </div>
              ) : (
                <div>
                  <div style={{alignItems: 'center', marginBottom: 15, justifyContent: 'center', textAlign: 'center'}}>
                    <span style={{fontSize: 16}}>{this.state.selectedDate.format('dddd D MMMM Y')}</span><br />
                    <span style={{fontSize: 16}}>{this.state.selectedTime.format('h:mma')}</span>
                  </div>
                  <div style={{display: 'flex', alignItems: 'center', marginBottom: 15, justifyContent: 'center', textAlign: 'center'}}>
                    <span style={{fontSize: 16, width: '50%'}}>{displayAddressWithoutCountry(this.state.selectedProperty.address)}</span>
                  </div>
                  <div style={{display: 'flex', alignItems: 'center', marginBottom: 15, justifyContent: 'center', textAlign: 'center'}}>
                    <span style={{fontSize: 16}}>Your request has been sent, you will receive a text message as soon as it is confirmed.</span>
                  </div>
                  <div style={{display: 'flex', alignItems: 'center', marginBottom: 15, justifyContent: 'center', textAlign: 'center'}}>
                    <span style={{fontSize: 16, fontWeight: 'bold'}}>Please note: Your {translation('viewing', this.state.iso2, false)} is not yet confirmed. Do not attend the property without confirmation.</span>
                  </div>
                  {this.renderPropertiesLink()}
                  <div style={{display: 'flex', justifyContent: 'center'}}>
                    <div style={{height: 1, width: '80%', backgroundColor: '#EEEEEE'}} />
                  </div>
                  <AgentCard user={this.state.user} color={this.state.primaryConsumerColor} />
                  <CompanyCard user={this.state.user} />
                </div>
              )}
            </div>
          </div>
        </div>
        <Footer min={this.min}/>
      </div>
    );
  }

  renderStepSeven() {
    document.title = displayAddressWithoutCountry(this.state.selectedProperty.address) + ' | ' + translation('viewing', this.state.iso2, true) + ' booking | Qualification form';

    return (
      <div>
        <div style={this.stepPageStyle}>
          {this.renderHeader()}
          <div>
            {(this.state.addingQualificationFeedback && !this.state.feedback) || (this.state.currentQuestionIndex === this.numberOfQuestions()) ? (
              <div className="content-holder">
                <ActivitySpinner />
              </div>
            ) : (
              <div>
                <div style={{display: 'flex', alignItems: 'center', marginBottom: 15, marginTop: 15, justifyContent: 'center', textAlign: 'center'}}>
                  <span style={{fontSize: 16, fontWeight: 'bold'}}>{displayAddressWithoutCountry(this.state.selectedProperty.address)}</span>
                </div>
                <div>
                  {this.renderQuestions()}
                </div>
                <div>
                  {this.renderCounter()}
                </div>
              </div>
            )}
          </div>
        </div>
        <Footer min={this.min}/>
      </div>
    );
  }

  render() {
    switch (this.state.step) {
      case 1:
        return this.renderStepOne();
      case 2:
        return this.renderStepTwo();
      case 3:
        return this.renderStepThree();
      case 4:
        return this.renderStepFour();
      case 5:
        return this.renderStepFive();
      case 6:
        return this.renderStepSix();
      case 7:
        return this.renderStepSeven();
    }
    return null;
  }
}

export default BookViewing;
