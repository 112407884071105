import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope, faPhone} from "@fortawesome/pro-light-svg-icons";
import {formatPhoneNumber} from "../tools";
import logoColor from '../img/avatar.png';


const renderImage = user => {
  if (user.avatar) {
    return (<img src={user.avatar} alt={'Real Happy'}
                 style={{height: 60, width: 60, alignSelf: 'center', borderRadius: 30}}/>)
  } else {
    return (
      <img src={logoColor} alt={'Real Happy'}
           style={{height: 60, width: 60, alignSelf: 'center', borderRadius: 30}}/>
    )
  }
};

const renderPhoneNumber = (user, color) => {
  let phoneNumber = null;
  if (!!user.phoneNumber) {
    phoneNumber = formatPhoneNumber(user.phoneNumber, user.senderIso2);
  }
  if (phoneNumber) {
    return (
      <div>
        <p style={{fontSize: 14, lineHeight: 1.5}}><a href={'tel:' + phoneNumber} style={{color: '#000000',textDecoration: 'underline'}}>{phoneNumber}</a></p>
      </div>
    )
  }
};

const renderEmail = (user, color) => {
  let email = null;
  if (!!user.email) {
    email = user.email;
  }
  if (email) {
    return (
      <div>
        <p style={{fontSize: 14, lineHeight: 1.5}}><a href={'mailto:' + email} style={{color: '#000000', textDecoration: 'underline'}}>{email}</a></p>
      </div>
    )
  }
};

const AgentCard = (props) => (
  <div style={{display: 'flex', paddingLeft: 15, paddingRight: 15, paddingTop: 30}}>
    {props.user && props.user.id && props.user.detailsToShow !== 'company' && (
      <div style={{width: '100%', paddingTop: 20, paddingBottom: 20, paddingLeft: 16, paddingRight: 16, flexDirection: 'row', display: 'flex', borderRadius: 7, backgroundColor: '#FFFFFF'}}>
        <div style={{width: 80}}>
          {renderImage(props.user)}
        </div>
        <div style={{flex: 1, paddingTop: 4}}>
          <div style={{marginBottom: 8}}>
            <p style={{fontSize: 18, color: '#000000', fontWeight: 500}}>{props.user.name}</p>
          </div>
          {renderPhoneNumber(props.user, props.color)}
          {renderEmail(props.user, props.color)}
        </div>
      </div>
    )}
  </div>
);

export default AgentCard;
