import firebase from 'firebase';
import * as actionConstants from '../actionConstants';
import {getE164} from "../tools";
import moment from  'moment';

/**
 * Load all showings by phone number
 *
 * @returns {function(*, *)}
 */
export const getShowingsForBooker = (phoneNumber) => async(dispatch) => {
  dispatch({ type: actionConstants.LOADING_SHOWINGS });
  let showings = await firebase
    .firestore()
    .collectionGroup('viewings')
    .where('phoneNumber', '==', phoneNumber)
    .get()
    .then((querySnapshot) => {
      let results = [];
      querySnapshot.forEach(doc => {
        if (moment(doc.data().viewingDateTime) > moment().startOf('day')) {
          results.push({id: doc.id, ...doc.data(), status: doc.data().cancelled ? 'cancelled' : 'confirmed'})
        }
      });
      return results;
    }).catch((error) => {
      console.log(error);
      return [];
    });

  let requestedShowings = await firebase
    .firestore()
    .collectionGroup('requestedViewings')
    .where('phoneNumber', '==', phoneNumber).get()
    .then((querySnapshot) => {
      let results = [];
      querySnapshot.forEach(doc => {
        if (moment(doc.data().viewingDateTime) > moment().startOf('day') && !doc.data().declined) {
          results.push({id: doc.id, ...doc.data(), status: doc.data().declined ? 'declined' : 'requested'})
        }
      });
      return results;
    }).catch((error) => {
      console.log(error);
      return [];
    });

  showings = showings.concat(requestedShowings).sort((a, b) => moment(a.viewingDateTime).format('x') - moment(b.viewingDateTime).format('x'));
  dispatch({type: actionConstants.LOADED_AGENT, payload: {}});
  dispatch({ type: actionConstants.LOADED_SHOWINGS, payload: showings });
};
