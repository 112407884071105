import React from 'react';
import logo from '../img/rh-logo-trans.png';
import logoColor from '../img/avatar.png';

const HeaderAvatar = (props) => {
  return (
    <div>
      {props.user.detailsToShow && props.user.detailsToShow === 'company' && props.user.companyLogo ? (
        <img src={props.user.companyLogo} alt={'Real Happy'} style={{height: 100, alignSelf: 'center'}} />
      ) : (
        !!props.user.avatar ? (
          <img src={props.user.avatar} alt={'Real Happy'} style={{height: 100, width: 100, alignSelf: 'center', borderRadius: 50}} />
        ) : (
          <img src={logoColor} alt={'Real Happy'} style={{height: 100, width: 100, alignSelf: 'center', borderRadius: 50}} />
        )
      )}
    </div>
  )
}

export default HeaderAvatar;