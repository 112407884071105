import { GOOGLE_CLIENT_ID, GOOGLE_CLIENT_SECRET, GOOGLE_AUTH_REDIRECT_URL } from '../environmentVariables';
const axios = require('axios');

export const tokenExchange = async code => {
  const params = new URLSearchParams();
  params.append('code', code)
  params.append('client_id', GOOGLE_CLIENT_ID)
  params.append('client_secret', GOOGLE_CLIENT_SECRET)
  params.append('redirect_uri', GOOGLE_AUTH_REDIRECT_URL)
  params.append('grant_type', 'authorization_code')

  return await axios.post('https://oauth2.googleapis.com/token', params, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
  })
}