import React, { Component } from 'react';
import { getUserLocale } from 'get-user-locale';
import '../../css/realhappy-sheet.scss';
import {colors} from "../../styles";
import logo from "../../img/rh-logo-trans.png";
import logoColor from '../../img/avatar.png';
import ActivitySpinner from "../../components/ActivitySpinner";
import {getE164} from "../../tools";
import {WEB_APP_URL, WEBSITE_URL} from "../../environmentVariables";
import HeaderAvatar from '../../components/HeaderAvatar';
import Footer from '../../components/Footer';

const language = (getUserLocale() && getUserLocale().length === 5) ? getUserLocale() : 'en_US';
const locale = language.substr(-2).toLowerCase();

class Register extends Component {
  constructor(props) {
    super(props);
    this.sheetId = this.props.match.params.key;
  }

  state = {
    sheet: {},
    user: {},
    phoneNumber: '',
    iso2: locale,
  };

  UNSAFE_componentWillMount() {
    this.props.getSheet(this.sheetId);
    this.props.generateIncomingSMSWakeUp();
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.sheet && nextProps.sheet !== this.props.sheet) {
      this.setState({
        sheet: nextProps.sheet
      }, () => this.props.getUser(nextProps.sheet.user))
    }
    if (nextProps.generatedSMS && nextProps.generatedSMS !== this.props.generatedSMS) {
      let phoneNumber = getE164(this.state.phoneNumber.replace(/[^0-9]+/g, ''), this.state.iso2);
      setTimeout(() => this.props.getLatestFeedback(phoneNumber, this.state.iso2, this.state.user.id), 500);
    }
    if (nextProps.latestFeedbackId && nextProps.latestFeedbackId !== this.props.latestFeedbackId) {
      this.setState({latestFeedbackId: nextProps.latestFeedbackId})
    }
    if (nextProps.user && nextProps.user !== this.props.user) {
      this.setState({
        user: nextProps.user,
        primaryConsumerColor: nextProps.user.primaryConsumerColor ? nextProps.user.primaryConsumerColor : '#000000',
      })
    }
  }

  renderPhoneNumberInput() {
    if (this.props.generatingSMS) {
      return (<ActivitySpinner />);
    }

    if (this.props.generatedSMS) {
      return (
        <div style={{marginTop: 20, marginBottom: 20, marginLeft: 10, marginRight: 10, padding: 20, backgroundColor: "#FFFFFF", borderRadius: 9}}>
          <div style={{marginBottom: 30, paddingTop: 10}}>
            <p style={{fontSize: '18px', textAlign: 'center', fontWeight: 'bold', marginBottom: 20}}>Thank you!</p>
            <p style={{fontSize: '18px', color: '#8F8F8F', textAlign: 'center'}}>A text message is on it's way to you so you can continue registration</p>
            <p style={{fontSize: '18px', color: '#8F8F8F', textAlign: 'center'}}>&nbsp;</p>
            {this.state.latestFeedbackId && (
              <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                <div>
                  <p style={{fontSize: '14px', color: '#8F8F8F', textAlign: 'center'}}>Didn't get the text message?</p>
                </div>
                <div
                  style={{display: 'flex', width: 100, justifyContent: 'center', height: 40, backgroundColor: this.state.primaryConsumerColor, borderRadius: 5, paddingTop: 11, marginTop: 20}}
                  onClick={() => window.location.href = WEB_APP_URL + '/f/' + this.state.latestFeedbackId}
                >
                  <span style={{color: '#FFFFFF'}}>CONTINUE</span>
                </div>
              </div>
            )}
          </div>
        </div>
      )
    }
    return (
      <div className={'animated ' + this.state.questionAnimation} style={{marginTop: 20, marginBottom: 20, marginLeft: 10, marginRight: 10, padding: 20, backgroundColor: "#FFFFFF", borderRadius: 9}}>
        <div style={{marginBottom: 30}}>
          <p style={{fontSize: '18px', color: colors.question, textAlign: 'center'}}>Please enter your mobile number</p>
        </div>
        <div>
          <input
            type="tel"
            style={{borderWidth: 1, borderColor: '#DEDEDE', width: "100%", borderRadius: 5, fontSize: 16, height: 30, textAlign: 'center' }}
            ref={(ref) => this.phoneNumber = ref}
            onChange={(event) => this.setState({ phoneNumber: event.target.value})}
            autoComplete={'tel'}
          />
          <div style={{display: 'flex', width: '100%', justifyContent: 'center', marginTop: 20}}>
            {this.state.phoneNumber && this.state.phoneNumber.length >= 10 ? (
              <div
                style={{display: 'flex', width: 100, justifyContent: 'center', height: 40, backgroundColor: this.state.primaryConsumerColor, borderRadius: 5, paddingTop: 11}}
                onClick={() => this.props.generateIncomingSMS(this.state.phoneNumber, this.state.sheet.textCode, this.state.iso2)}
              >
                <span style={{color: '#FFFFFF'}}>CONTINUE</span>
              </div>
            ) : (
              <div
                style={{display: 'flex', width: 100, justifyContent: 'center', height: 40, backgroundColor: '#CCCCCC', borderRadius: 5, paddingTop: 11}}
              >
                <span style={{color: '#FFFFFF'}}>CONTINUE</span>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }

  renderContent() {
    if (this.props.loadingSheet || !this.state.user.id) {
      return (
        <div className="content-holder">
          <ActivitySpinner />
        </div>
      );
    }

    if (this.props.loadedSheet && this.state.user && this.state.user.id) {
      return (
        <div>
          <div style={{display: 'flex', alignItems: 'center', marginBottom: 20, justifyContent: 'center'}}>
            <HeaderAvatar user={this.state.user} />
          </div>
          <div>
            <div style={{textAlign: 'center'}}>
              <p style={{fontSize: '16px', lineHeight: '18px'}}>
                <span></span>
                <br/>
                <span style={{fontWeight: 'bold'}}>{this.state.sheet.propertyAddress}</span>
              </p>
            </div>
            <div>
              {this.renderPhoneNumberInput()}
            </div>
          </div>
        </div>
      );
    }
  }

  render() {
    return (
      <div>
        <div style={{paddingTop: 30, paddingBottom: 30, paddingLeft: 20, paddingRight: 20, marginLeft: 'auto', marginRight: 'auto', maxWidth: 500}}>
          {this.renderContent()}
          <Footer />
        </div>
      </div>
    );
  }
}

export default Register;
