import React from 'react';
import { WEBSITE_URL } from '../environmentVariables';
import logo from '../img/powered-by-rh.png';

const Footer = (props) => {
  if (props.min) {
    return null
  }

  return (
    <div style={{width: '100%', display: 'flex', flexDirection: 'column', paddingBottom: 50, paddingTop: 50}}>
      <div>
        <img src={logo} style={{height: 60}} alt={'Powered by Real Happy'}/>
      </div>
      <div style={{paddingLeft: 10, paddingTop: 10}}>
        <a style={{color: '#000000', fontSize: 16, textDecoration: 'none'}} href={WEBSITE_URL + '/privacy/'} target={'_blank'}>Privacy Policy</a> / <a style={{color: '#000000', fontSize: 16, textDecoration: 'none'}} href={WEBSITE_URL + '/terms/'} target={'_blank'}>Terms</a>
      </div>
    </div>
  )
}

export default Footer;