import React, { Component } from 'react';
import '../../css/realhappy-booking.scss';

const qs = require('qs');


class DocuSignAuth extends Component {
  constructor(props) {
    super(props);
    const params = qs.parse(props.location.search, { ignoreQueryPrefix: true });
    this.code = params.code;
    this.userId = params.state;
  }

  componentDidMount() {
    this.props.setDocuSignAccessToken(this.code, this.userId);
  }

  render() {
    return (
      <div>Thank you. You can now close this browser and go back to the Real Happy app.</div>
    );
  }
}

export default DocuSignAuth;
