import React, { useEffect, useState } from 'react';
import firebase from 'firebase';
import { tokenExchange } from '../../tools/googleAPI';

const qs = require('qs');

const OAuth = (props) => {
  const [tokens, setTokens] = useState({})
  const params = qs.parse(props.location.search, { ignoreQueryPrefix: true });

  useEffect(() => {
    const getTokens = async () => {
      let tokenCall = await tokenExchange(params.code).then(data => data.data)
      setTokens({
        access_token: tokenCall.access_token,
        refresh_token: tokenCall.refresh_token
      })
    }
    getTokens()
  }, [])

  useEffect(() => {
    props.setGoogleAuthTokens(params.state, tokens)
  }, [tokens])

  return (
    <div style={{padding: 50}}>
      <div style={{textAlign: 'center', fontSize: 24}}>{tokens && tokens.access_token && tokens.refresh_token ? 'Thank you, you have successfully authorized. Please return to the Real Happy app' : 'Please wait...'}</div>
    </div>
    
  )
}

export default OAuth;