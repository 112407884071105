// For Google libnumber
const PNF = require('google-libphonenumber').PhoneNumberFormat;
// Get an instance of `PhoneNumberUtil`.
const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();
const moment = require('moment');

export const calculateColor = input => {
  let result = 'unused';
  if (Math.round(input) === 5) {
    result = 'vhappy';
  }
  if (Math.round(input) === 4) {
    result = 'happy';
  }
  if (Math.round(input) === 3) {
    result = 'neutral';
  }
  if (Math.round(input) === 2) {
    result = 'sad';
  }
  if (Math.round(input) === 1) {
    result = 'vsad';
  }
  if (Math.round(input) === 0) {
    result = 'unused';
  }
  return result;
};

/**
 * Return the phone number in E164 format
 *
 * @param phoneNumber
 * @param country
 * @returns {string|*}
 */
export const getE164 = (phoneNumber, country) =>
  phoneUtil.format(
    phoneUtil.parseAndKeepRawInput(phoneNumber, country),
    PNF.E164,
  );

/**
 * Format the given phone number to the country format
 *
 * @param phoneNumber
 * @param country
 * @returns {string|*}
 */
export const formatPhoneNumber = (phoneNumber, country) => {
  try {
    phoneUtil.parse(phoneNumber, country);
  } catch (err) {
    return '';
  }
  return phoneUtil.formatInOriginalFormat(
    phoneUtil.parse(phoneNumber, country),
    country,
  );
};

/**
 * Get ISO2 from phone number
 *
 * @param phoneNumber
 * @returns {null}
 */
export const getIso2 = phoneNumber => {
  return phoneUtil.getRegionCodeForNumber(phoneNumber).toLowerCase();
};

/**
 * Return the incoming text number based on country
 *
 * @param data
 * @returns {string}
 */
export const getIncomingTextCode = data => {
  if (data) {
    let iso2 = data.iso2 || data
    switch (iso2) {
      case 'us':
        return '(646) 846 7755';
      case 'gb':
        return '07723 459860';
    }
  }
};

/**
 * Translation table
 *
 * @param word
 * @param locale
 * @param capitalise
 * @returns {*}
 */
export const translation = (word, locale, capitalise) => {
  let translationTable = {
    viewing: {
      gb: 'viewing',
      us: 'showing',
    },
    vendor: {
      gb: 'vendor',
      us: 'owner',
    },
    enquire: {
      gb: 'enquire',
      us: 'inquire',
    },
    town: {
      gb: 'town',
      us: 'city',
    },
    county: {
      gb: 'county',
      us: 'state',
    },
    postcode: {
      gb: 'postcode',
      us: 'zip',
    },
    colour: {
      gb: 'colour',
      us: 'color',
    },
    ft: {
      au: 'm',
      nz: 'm',
      es: 'm',
    },
  };
  if (translationTable[word] && translationTable[word][locale]) {
    word = translationTable[word][locale];
  }
  return capitalise ? word.substr(0, 1).toUpperCase() + word.substring(1) : word;
};

export const validatePhoneNumber = (phoneNumber, iso2) => {
  let number = phoneNumber;
  try {
    number = phoneUtil.parseAndKeepRawInput(number, iso2.toUpperCase());
  } catch(err) {
    return false;
  }

  return phoneUtil.isValidNumberForRegion(number, iso2.toUpperCase());
};

export const getViewingLength = (user, property) => {
  if (property.defaultViewingLength) {
    return property.defaultViewingLength;
  } else if (user.defaultViewingLength) {
    return user.defaultViewingLength;
  } else {
    return 20;
  }
};

export const getViewingNotice = (user, property) => {
  if (property.defaultViewingNotice) {
    return property.defaultViewingNotice;
  } else if (user.defaultViewingNotice) {
    return user.defaultViewingNotice;
  } else {
    return 1;
  }
};

export const getConfirmBy = (user, property) => {
  if (property.confirmBy) {
    return property.confirmBy;
  } else if (user.confirmBy) {
    return user.confirmBy;
  } else {
    return 'agent';
  }
};

export const getDefaultFeedbackForm = (user, property, feedbackForms, viewing) => {
  if (viewing && viewing.feedbackForm) {
    return viewing.feedbackForm;
  }
  if (property && property.defaultFeedbackForm) {
    return feedbackForms.find(form => form.id === property.defaultFeedbackForm).id;
  }
  if (user && user.defaultFeedbackForm) {
    return feedbackForms.find(form => form.id === user.defaultFeedbackForm).id;
  }
  if (feedbackForms && feedbackForms.length > 0) {
    return feedbackForms.filter(form => !form.openhouse)[0].id;
  }
  return null;
};

export const getDefaultFeedbackFormName = (user, property, feedbackForms, viewing) => {
  if (viewing && viewing.feedbackForm) {
    return viewing.feedbackFormName;
  }
  if (property && property.defaultFeedbackForm) {
    return feedbackForms.find(form => form.id === property.defaultFeedbackForm).name;
  }
  if (user && user.defaultFeedbackForm) {
    return feedbackForms.find(form => form.id === user.defaultFeedbackForm).name;
  }
  if (feedbackForms && feedbackForms.length > 0) {
    return feedbackForms.filter(form => !form.openhouse)[0].name;
  }
  return null;
};

export const isTimeBlocked = (time, viewings, user, viewingDateSettings, selectedDate, property) => {
  return viewings.find(viewing => {
    return moment(viewing.viewingDateTime).isSame(moment(time), 'minute')
      || (moment(viewing.viewingDateTime).subtract(getViewingLength(user, property), 'minutes').format('Y-MM-DD HH:mm:00') < moment(time).format('Y-MM-DD HH:mm:00') && moment(viewing.viewingDateTime).add(getViewingLength(user, property), 'minutes').format('Y-MM-DD HH:mm:00') > moment(time).format('Y-MM-DD HH:mm:00'))
  })
  || (moment(time).isSame(moment(), 'day') && moment(time).format('Y-MM-DD HH:mm:00') <= moment().add(getViewingNotice(user, property), 'hours').format('Y-MM-DD HH:mm:00'))
  || !viewingDateSettings[moment(selectedDate).format('dddd')].showings
  || isTimeManuallyBlocked(time, property)
};

const isTimeManuallyBlocked = (time, property) => {
  let blocked = false;
  if (!property.timeBlocks || property.timeBlocks.length === 0) {
    return false;
  }
  if (property.timeBlocks) {
    property.timeBlocks.map(block => {
      if (!block.multipleDay) {
        if (moment(block.dateTimeFrom) <= moment(time) && moment(block.dateTimeTo) >= moment(time)) {
          blocked = true;
        }
      } else {
        let newTime = moment(time).set({
          'year': moment().format('Y'),
          'month': moment().format('M')-1,
          'date': moment().format('D'),
        });
        if (block.frequency === 'Daily') {
          if (moment(newTime).isBetween(moment(block.timeFrom, "h:mm a"), moment(block.timeTo, "h:mm a"), 'minute', [])) {
            blocked = true;
          }
        }
        if (block.frequency === 'Every Monday') {
          if (moment(time).format('dddd') === 'Monday' && moment(newTime).isBetween(moment(block.timeFrom, "h:mm a"), moment(block.timeTo, "h:mm a"), 'minute', [])) {
            blocked = true;
          }
        }
        if (block.frequency === 'Every Tuesday') {
          if (moment(time).format('dddd') === 'Tuesday' && moment(newTime).isBetween(moment(block.timeFrom, "h:mm a"), moment(block.timeTo, "h:mm a"), 'minute', [])) {
            blocked = true;
          }
        }
        if (block.frequency === 'Every Wednesday') {
          if (moment(time).format('dddd') === 'Wednesday' && moment(newTime).isBetween(moment(block.timeFrom, "h:mm a"), moment(block.timeTo, "h:mm a"), 'minute', [])) {
            blocked = true;
          }
        }
        if (block.frequency === 'Every Thursday') {
          if (moment(time).format('dddd') === 'Thursday' && moment(newTime).isBetween(moment(block.timeFrom, "h:mm a"), moment(block.timeTo, "h:mm a"), 'minute', [])) {
            blocked = true;
          }
        }
        if (block.frequency === 'Every Friday') {
          if (moment(time).format('dddd') === 'Friday' && moment(newTime).isBetween(moment(block.timeFrom, "h:mm a"), moment(block.timeTo, "h:mm a"), 'minute', [])) {
            blocked = true;
          }
        }
        if (block.frequency === 'Every Saturday') {
          if (moment(time).format('dddd') === 'Saturday' && moment(newTime).isBetween(moment(block.timeFrom, "h:mm a"), moment(block.timeTo, "h:mm a"), 'minute', [])) {
            blocked = true;
          }
        }
        if (block.frequency === 'Every Sunday') {
          if (moment(time).format('dddd') === 'Sunday' && moment(newTime).isBetween(moment(block.timeFrom, "h:mm a"), moment(block.timeTo, "h:mm a"), 'minute', [])) {
            blocked = true;
          }
        }
        if (block.frequency === 'Weekdays only') {
          if (['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'].indexOf(moment(time).format('dddd')) !== -1 && moment(newTime).isBetween(moment(block.timeFrom, "h:mm a"), moment(block.timeTo, "h:mm a"), 'minute', [])) {
            blocked = true;
          }
        }
        if (block.frequency === 'Weekends only') {
          if (['Saturday', 'Sunday'].indexOf(moment(time).format('dddd')) !== -1 && moment(newTime).isBetween(moment(block.timeFrom, "h:mm a"), moment(block.timeTo, "h:mm a"), 'minute', [])) {
            blocked = true;
          }
        }
      }
    });
    return blocked;
  }
};

export const displayAddressWithoutCountry = address => {
  return address.replace(', USA', '').replace(', UK', '');
};

export const displayDescriptionAsHtml = description => {
  return description.replace(/(?:\r\n|\r|\n)/g, '<br />')
};

export const validateEmail = email => {
  return /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/.test(email);
};
