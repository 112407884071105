import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSmile, faMeh, faFrown } from '@fortawesome/free-solid-svg-icons'
import ActivitySpinner from '../../components/ActivitySpinner';
import '../../css/realhappy-feedback.scss';
import logo from '../../img/rh-logo-trans.png';
import logoColor from '../../img/avatar.png';
import {colors} from '../../styles';
import {calculateColor} from "../../tools";
import {WEBSITE_URL} from '../../environmentVariables';
import Footer from '../../components/Footer';

class ViewFeedback extends Component {
  constructor(props) {
    super(props);
    this.feedbackId = this.props.match.params.key;
  }

  state = {
    feedback: {},
    currentQuestionIndex: 0,
    totalRating: 0,
    ratingCount: 0,
    rating: 0,
    answers: [],
  };

  UNSAFE_componentWillMount() {
    this.props.getFeedbackOnly(this.feedbackId);
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.feedback && nextProps.feedback !== this.props.feedback) {
      this.setState({feedback: nextProps.feedback})
    }
  }

  renderContent() {
    if (this.props.loadingFeedback) {
      return (
        <div className="content-holder">
          <ActivitySpinner />
        </div>
      );
    }

    if (this.props.loadedFeedback) {
      return (
        <div>
          <div style={{display: 'flex', alignItems: 'center', marginBottom: 20, justifyContent: 'center'}}>
            {!!this.state.feedback.senderAvatar ? (
              <img src={this.state.feedback.senderAvatar} alt={'Real Happy'} style={{height: 100, width: 100, alignSelf: 'center', borderRadius: 50}} />
            ) : (
              <img src={logoColor} alt={'Real Happy'} style={{height: 100, width: 100, alignSelf: 'center', borderRadius: 50}} />
            )}
          </div>
          <div style={{textAlign: 'center'}}>
            <p style={{fontSize: '16px', lineHeight: '18px'}}>
              <span>You have received feedback for</span><br />
              <span style={{fontWeight: 'bold'}}>{this.state.feedback.propertyAddress}</span>
            </p>
          </div>
          <div style={{textAlign: 'center', marginTop: 20}}>
            <p style={{fontSize: '16px', lineHeight: '18px'}}>
              <span style={{fontWeight: 'bold'}}>Overall Rating</span>
            </p>
          </div>
          <div style={{textAlign: 'center', marginTop: 20}}>
            {this.renderOverallRating()}
          </div>
          <div style={{textAlign: 'center', marginTop: 10}}>
            <p style={{color: colors[calculateColor(this.state.feedback.rating)], fontSize: '18px', fontWeight: 'bold'}}>{this.state.feedback.rating.toFixed(1)}</p>
          </div>
          <div>
            {this.renderResults()}
          </div>
        </div>
      );
    }

    return null;
  }

  renderOverallRating() {
    switch (Math.round(this.state.feedback.rating)) {
      case 5:
        return <FontAwesomeIcon icon={faSmile} style={{fontSize: '50px', color: colors.vhappy}}/>;
      case 4:
        return <FontAwesomeIcon icon={faSmile} style={{fontSize: '50px', color: colors.happy}}/>;
      case 3:
        return <FontAwesomeIcon icon={faMeh} style={{fontSize: '50px', color: colors.neutral}}/>;
      case 2:
        return <FontAwesomeIcon icon={faFrown} style={{fontSize: '50px', color: colors.sad}}/>;
      case 1:
        return <FontAwesomeIcon icon={faFrown} style={{fontSize: '50px', color: colors.vsad}}/>;
      default:
        return <FontAwesomeIcon icon={faMeh} style={{fontSize: '50px', color: colors.neutral}}/>;
    }
  }

  renderResults() {
    return this.state.feedback.questions.map((question, index) => {
      return (
        <div style={{marginTop: 20, marginBottom: 20, marginLeft: 10, marginRight: 10, padding: 20, backgroundColor: "#FFFFFF", borderRadius: 9}}>
          <div style={{marginBottom: 20}}>
            <p style={{fontSize: '18px', color: '#8F8F8F', textAlign: 'left'}}>{index + 1}. {question.question}</p>
          </div>
          <div style={{textAlign: 'center'}}>
            <div style={{display: 'flex', flexDirection: 'row'}}>
              <div style={{display: 'flex', width: '100%', justifyContent: 'flex-end'}}>
                {this.renderAnswer(index)}
              </div>
            </div>
          </div>
        </div>
      )
    })
  }

  renderRatingAnswer(rating) {
    if (!rating) {
      return <span style={{fontSize: '16px', color: colors.primary}}>Not answered</span>;
    }
    switch (rating) {
      case 5:
        return <FontAwesomeIcon icon={faSmile} style={{fontSize: '35px', color: colors.vhappy}}/>;
      case 4:
        return <FontAwesomeIcon icon={faSmile} style={{fontSize: '35px', color: colors.happy}}/>;
      case 3:
        return <FontAwesomeIcon icon={faMeh} style={{fontSize: '35px', color: colors.neutral}}/>;
      case 2:
        return <FontAwesomeIcon icon={faFrown} style={{fontSize: '35px', color: colors.sad}}/>;
      case 1:
        return <FontAwesomeIcon icon={faFrown} style={{fontSize: '35px', color: colors.vsad}}/>;
      default:
        return <FontAwesomeIcon icon={faMeh} style={{fontSize: '35px', color: colors.neutral}}/>;
    }
  }

  renderBooleanAnswer(value) {
    if (value) {
      return (
        <div style={{display: 'flex', justifyContent: 'center'}}>
          <span style={{fontSize: '20px', fontWeight: 'bold', color: colors.positive}}>YES</span>
        </div>
      )
    }
    if (value === false) {
      return (
        <div style={{display: 'flex', justifyContent: 'center'}}>
          <span style={{fontSize: '20px', fontWeight: 'bold', color: colors.positive}}>NO</span>
        </div>
      )
    }
    return <span style={{fontSize: '16px', color: colors.primary}}>Not answered</span>;
  }

  renderTextAnswer(text) {
    if (text && text.length > 0) {
      return (
        <span style={{fontSize: '16px', fontStyle: 'italic', color: colors.primary}}>"{text}"</span>
      )
    }
    return (
      <span style={{fontSize: '16px', color: colors.primary}}>Not answered</span>
    )
  }

  renderAnswer(index) {
    switch (this.state.feedback.questions[index].type) {
      case "rating":
        return this.renderRatingAnswer(this.state.feedback.answers[index]);
      case "boolean":
        return this.renderBooleanAnswer(this.state.feedback.answers[index]);
      case "text":
        return this.renderTextAnswer(this.state.feedback.answers[index]);
      default:
        return null;
    }
  }

  render() {
    return (
      <div>
        <div style={{paddingTop: 30, paddingBottom: 30, paddingLeft: 20, paddingRight: 20}}>
          {this.renderContent()}
          <Footer />
        </div>
      </div>
    );
  }
}

export default ViewFeedback;
