import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Sheet from './Sheet';
import { ActionCreators } from '../../actions/index';
import { getSheet } from '../../reducers/rootReducer';

const mapStateToProps = (state) => ({
  ...getSheet(state)
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators(ActionCreators, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Sheet);
