import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import Modal from 'react-modal';
import {faMapMarked} from "@fortawesome/pro-solid-svg-icons/faMapMarked";
import {faTimes} from "@fortawesome/pro-solid-svg-icons/faTimes";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";
import { Container, Row, Col } from "react-bootstrap";
import getSymbolFromCurrency from 'currency-symbol-map';
import { getUserLocale } from 'get-user-locale';

import ActivitySpinner from '../../components/ActivitySpinner';
import ImageGallery from '../../components/ImageGallery';
import AgentCard from '../../components/AgentCard';
import CompanyCard from '../../components/CompanyCard';

import '../../css/realhappy-booking.scss';
import '../../css/image-gallery.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import logo from '../../img/rh-logo-trans.png';
import logoColor from '../../img/avatar.png';
import {colors} from '../../styles';
import {translation, displayAddressWithoutCountry, displayDescriptionAsHtml} from "../../tools";
import {WEB_APP_URL, WEBSITE_URL} from "../../environmentVariables";
import Footer from '../../components/Footer';

const width = window.outerWidth;

const LocaleCurrency = require('locale-currency');

const modalStyles = {
  content : {
    top                   : 0,
    left                  : 0,
    right                 : 'auto',
    bottom                : 'auto',
    width                 : '100%',
    height                : window.outerHeight,
    //transform             : 'translate(-50%, -50%)'
    backgroundColor       : '#000000',
    color                 : colors.white,
    zIndex                : 200,
  }
};

const bookingModalStyles = {
  content : {
    top                   : 0,
    left                  : 0,
    right                 : 0,
    bottom                : 'auto',
    width                 : '100%',
    height                : window.outerHeight,
    //transform             : 'translate(-50%, -50%)'
    backgroundColor       : 'rgba(0, 0, 0, 0.5)',
    color                 : colors.white,
    zIndex                : 200,
  }
};

const language = (getUserLocale() && getUserLocale().length === 5) ? getUserLocale() : 'en_US';
const locale = language.substr(-2).toLowerCase();

const Marker = ({ text }) => <div style={{backgroundColor: colors.negative, width: 10, height: 10, borderRadius: 5}} />;

// Create our number formatter.
const formatter = country => new Intl.NumberFormat(country, {
  maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

class Property extends Component {
  constructor(props) {
    super(props);
    this.userId = this.props.match.params.key;
    this.propId = this.props.match.params.prop;
  }

  state = {
    user: {},
    selectedProperty: null,
    properties: [],
    showDetails: this.props.match.path.indexOf('/details') !== -1,
    showMore: false,
    media: [],
    showMapModal: false,
    primaryConsumerColor: '#000000',
    showBookingModal: false,
  };

  UNSAFE_componentWillMount() {
    this.props.getUser(this.userId);
    this.props.getPropertiesByUser(this.userId);
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.user && nextProps.user !== this.props.user){
      this.setState({
        user: nextProps.user,
        primaryConsumerColor: nextProps.user.primaryConsumerColor ? nextProps.user.primaryConsumerColor : '#000000'
      })
    }
    if (nextProps.properties && nextProps.properties !== this.props.properties) {
      this.setState({ properties: nextProps.properties }, () => {
        if (this.propId) {
          this.setState({
            selectedProperty: this.state.properties.find(prop => prop.id === this.propId),
            step: 2,
          }, () => {
            if (this.state.selectedProperty) {
              this.setState({
                showRegistrationButton: this.state.selectedProperty.showRegistrationButton !== undefined ? this.state.selectedProperty.showRegistrationButton : this.state.user.showRegistrationButton !== undefined ? this.state.user.showRegistrationButton : false,
                registrationButtonText: this.state.selectedProperty.registrationButtonText !== undefined ? this.state.selectedProperty.registrationButtonText : this.state.user.registrationButtonText !== undefined ? this.state.user.registrationButtonText : 'REGISTER / ' + translation('enquire', locale, false).toUpperCase(),
                showBookingButton: this.state.selectedProperty.showBookingButton !== undefined ? this.state.selectedProperty.showBookingButton : this.state.user.showBookingButton !== undefined ? this.state.user.showBookingButton : true,
                bookingButtonText: this.state.selectedProperty.bookingButtonText !== undefined ? this.state.selectedProperty.bookingButtonText : this.state.user.bookingButtonText !== undefined ? this.state.user.bookingButtonText : 'BOOK ' + translation('viewing', locale, false).toUpperCase(),
              }, () => {
                this.props.getPropertyMedia(this.userId, this.state.selectedProperty.id);
                this.props.addVisitRecord(this.userId, this.state.selectedProperty.id);
              })
            }
          })
        }
      })
    }
    if (nextProps.media && nextProps.media !== this.props.media) {
      this.setState({
        media: nextProps.media,
      })
    }
  }

  renderButtons() {
    return (
      <div style={{display: 'flex', marginBottom: 16}}>
        {this.state.showRegistrationButton && (
          <div
            onClick={() => window.open('/r/' + this.state.selectedProperty.textCode)}
            style={{display: 'flex', flex: 1, alignItems: this.state.showBookingButton ? 'flex-end' : 'center', justifyContent: this.state.showBookingButton ? 'flex-end' : 'center', paddingRight: 6, cursor: 'pointer'}}>
            <div style={{display: 'table', width: this.state.showBookingButton ? 150 : 300, height: 46, border: '1px solid ' + this.state.primaryConsumerColor, borderRadius: 7, textAlign: 'center', justifyContent: 'center', backgroundColor: colors.white, fontSize: this.state.showBookingButton ? 13 : 18, color: this.state.primaryConsumerColor}}><div style={{display: 'table-cell', verticalAlign: 'middle', height: 46, width: '100%'}}>{this.state.registrationButtonText.toUpperCase()}</div></div>
          </div>
        )}
        {this.state.showBookingButton && (
          <div
            //onClick={() => window.location.href = '/b/' + this.state.user.id + '/' + this.state.selectedProperty.id + '/2'}
            onClick={() => this.setState({showBookingModal: true})}
            style={{display: 'flex', flex: 1, alignItems: this.state.showRegistrationButton ? 'flex-start' : 'center', justifyContent: this.state.showRegistrationButton ? 'flex-start' : 'center', paddingLeft: 6, cursor: 'pointer'}}>
            <div style={{display: 'table', width: this.state.showRegistrationButton ? 150 : 300, height: 46, border: '1px solid ' + this.state.primaryConsumerColor, borderRadius: 7, textAlign: 'center', justifyContent: 'center', backgroundColor: this.state.primaryConsumerColor, fontSize: this.state.showRegistrationButton ? 13 : 18, color: colors.white}}><div style={{display: 'table-cell', verticalAlign: 'middle', height: 46, width: '100%'}}>{this.state.bookingButtonText.toUpperCase()}</div></div>
          </div>
        )}
      </div>
    )
  }

  renderPropertiesLink() {
    return (
      <Row
        style={{paddingTop: 30, paddingBottom: 30}}
      >
        <Col
          style={{textAlign: 'center', cursor: 'pointer'}}
          onClick={() => window.location.href = '/b/' + this.state.user.id}
        >
          <span style={{fontSize: 14}}>VIEW ALL PROPERTIES&nbsp;&nbsp;{'>'}</span>
        </Col>
      </Row>
    )
  }

  renderDetails() {
    return (
      <Container>
        <Row style={{marginBottom: 16}}>
          <Col style={{backgroundColor: colors.white, paddingTop: 16, paddingBottom: 16}}>
            <span style={{fontWeight: 'bold'}}>{translation('viewing', locale, true)} instructions:</span><br/>
            <span>{this.state.selectedProperty.viewingDetails}</span>
          </Col>
        </Row>
      </Container>
    )
  }

  renderAddressAndDetails() {
    return (
      <div>
        <Row style={{paddingTop: 4, paddingBottom: 16}}>
          <Col>
            <span style={{fontSize: 20}}>{displayAddressWithoutCountry(this.state.selectedProperty.address)}</span>
          </Col>
        </Row>
        <Row style={{paddingBottom: 16}}>
          <Col xs={8}>
            {!!this.state.selectedProperty.beds && !isNaN(this.state.selectedProperty.beds) && (
              <span style={{backgroundColor: '#EEEEEE', borderRadius: 5, paddingTop: 5, paddingBottom: 5, paddingLeft: 8, paddingRight: 8, justifyContent: 'center', textAlign: 'center', marginRight: 12}}>{this.state.selectedProperty.beds} Bd</span>
            )}
            {!!this.state.selectedProperty.baths && !isNaN(this.state.selectedProperty.baths) && (
              <span style={{backgroundColor: '#EEEEEE', borderRadius: 5, paddingTop: 5, paddingBottom: 5, paddingLeft: 8, paddingRight: 8, justifyContent: 'center', textAlign: 'center', marginRight: 12}}>{this.state.selectedProperty.baths} Ba</span>
            )}
            {!!this.state.selectedProperty.sqFootage && !isNaN(this.state.selectedProperty.sqFootage) && (
              <span style={{backgroundColor: '#EEEEEE', borderRadius: 5, paddingTop: 5, paddingBottom: 5, paddingLeft: 8, paddingRight: 8, justifyContent: 'center', textAlign: 'center'}}>{this.state.selectedProperty.sqFootage} Sq {translation('ft', locale, false)}</span>
            )}
          </Col>
          <Col xs={4}>
            <Row noGutters>
              <Col style={{textAlign: 'right'}}>
                <FacebookShareButton
                  url={window.location.href.replace('http://localhost:3000', WEB_APP_URL)}
                  quote={displayAddressWithoutCountry(this.state.selectedProperty.address) + ' on Real Happy'}
                >
                  <FacebookIcon
                    size={24}
                    round={true}
                  />
                </FacebookShareButton>
              </Col>
              <Col style={{textAlign: 'right'}}>
                <TwitterShareButton
                  url={window.location.href.replace('http://localhost:3000', WEB_APP_URL)}
                  title={displayAddressWithoutCountry(this.state.selectedProperty.address) + ' on Real Happy'}
                >
                  <TwitterIcon
                    size={24}
                    round={true}
                  />
                </TwitterShareButton>
              </Col>
              <Col style={{textAlign: 'right'}}>
                <WhatsappShareButton
                  url={window.location.href.replace('http://localhost:3000', WEB_APP_URL)}
                  title={displayAddressWithoutCountry(this.state.selectedProperty.address) + ' on Real Happy'}
                >
                  <WhatsappIcon
                    size={24}
                    round={true}
                  />
                </WhatsappShareButton>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row style={{paddingBottom: 24}}>
          {!!this.state.selectedProperty.price && !isNaN(this.state.selectedProperty.price) && (
            <Col>
              <span style={{fontSize: 19, fontWeight: 'bold'}}>{getSymbolFromCurrency(LocaleCurrency.getCurrency(this.state.user.iso2)) + formatter(this.state.user.iso2).format((this.state.selectedProperty.price).replace(',', ''))}</span>
            </Col>
          )}
          {!!this.state.selectedProperty.propertyListingId && (
            <Col style={{textAlign: 'right'}}>
              <span style={{fontSize: 16}}><span style={{fontWeight: 'bold'}}>ID#:</span> {this.state.selectedProperty.propertyListingId}</span>
            </Col>
          )}
        </Row>
        {!!this.state.selectedProperty.description && !this.state.showDetails && (
          <Row>
            <Col>
              <Container>
                <Row>
                  <Col style={{backgroundColor: colors.white, paddingTop: 16, paddingBottom: 16}}>
                    <div dangerouslySetInnerHTML={{ __html: displayDescriptionAsHtml(this.state.selectedProperty.description)}}></div>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        )}
      </div>
    )
  }

  renderMapOrImages() {
    if (this.props.gettingMedia && this.state.media.length === 0) {
      return (
        <ActivitySpinner />
      )
    }
    if (this.props.gotMedia && this.state.media.length === 0) {
      return (
        <div style={{width: '100%', height: width/7*5, maxHeight: 300, backgroundColor: colors.white, marginBottom: 18}}>
          <GoogleMapReact
            bootstrapURLKeys={{ key: 'AIzaSyAs4SS_IliPH7OVWUd5rz57mMGQOm3aswQ' }}
            defaultCenter={{
              lat: this.state.selectedProperty.location.latitude,
              lng: this.state.selectedProperty.location.longitude,
            }}
            defaultZoom={17}
          >
            <Marker
              lat={this.state.selectedProperty.location.latitude}
              lng={this.state.selectedProperty.location.longitude}
            />
          </GoogleMapReact>
        </div>
      )
    }
    if (this.props.gotMedia && this.state.media.length > 0) {
      let images = this.state.media.sort((a, b) => b.cover-a.cover).map(media => {
        return {
          original: media.url,
        }
      });
      return (
        <div style={{width: '100%', height: width/7*5, marginBottom: 18, maxHeight: 300, backgroundColor: '#000000'}}>
          {!this.state.showMapModal && !this.state.showBookingModal && (
            <ImageGallery
              items={images}
              showIndex
              showFullscreenButton={false}
              showThumbnails={false}
              renderItem={item => <img src={item.original} style={{width: '100%', height: width/7*5, maxHeight: 300, maxWidth: 420}} />}
            />
          )}
          <div
            onClick={() => this.setState({showMapModal: true})}
            style={{position: 'absolute', top: ((width/7*5)-64) > 300 ? 270 : (width/7*5)-64, right: 19, width: 60, height: 60, backgroundColor: colors.white, borderRadius: 30, borderWidth: 5, borderColor: colors.white, textAlign: 'center', paddingTop: 14}}>
            <FontAwesomeIcon icon={faMapMarked} style={{fontSize: '28px', color: '#666666'}} />
          </div>
        </div>
      )
    }
  }

  renderMapModal() {
    return (
      <Modal
        isOpen={this.state.showMapModal}
        onRequestClose={() => this.setState({showMapModal: false})}
        style={modalStyles}
        contentLabel="Map and directions"
      >

        <div style={{width: '100%', backgroundColor: '#000000', zIndex: 999}}>
          <div style={{textAlign: 'right', fontSize: 18, color: colors.white, marginTop: 4, marginBottom: 4, marginRight: 10}}>
            <div
              onClick={() => this.setState({showMapModal: false})}
              style={{color: colors.white}}
            >
              <FontAwesomeIcon icon={faTimes} style={{fontSize: 28, color: colors.white}} />
            </div>
          </div>
          <div style={{textAlign: 'center', fontSize: 18, color: colors.white, marginTop: 4, marginBottom: 4}}>
            {displayAddressWithoutCountry(this.state.selectedProperty.address)}
          </div>

          <div style={{width: '100%', height: width/7*5, backgroundColor: colors.white}}>
            <GoogleMapReact
              bootstrapURLKeys={{ key: 'AIzaSyAs4SS_IliPH7OVWUd5rz57mMGQOm3aswQ' }}
              defaultCenter={{
                lat: this.state.selectedProperty.location.latitude,
                lng: this.state.selectedProperty.location.longitude,
              }}
              defaultZoom={17}
            >
              <Marker
                lat={this.state.selectedProperty.location.latitude}
                lng={this.state.selectedProperty.location.longitude}
              />
            </GoogleMapReact>
            <div style={{marginRight: 'auto', marginLeft: 'auto', height: 50, width: 200, textAlign: 'center', paddingTop: 16, backgroundColor: colors.white, borderRadius: 7, marginTop: 20, marginBottom: 20}}>
              <a style={{color: '#000000', textDecoration: 'none'}} href={'https://www.google.com/maps/dir/?api=1&destination=' + encodeURI(this.state.selectedProperty.address)}>GET DIRECTIONS</a>
            </div>
          </div>
        </div>
      </Modal>
    )
  }

  renderBookingModal() {
    return (
      <Modal
        isOpen={this.state.showBookingModal}
        onRequestClose={() => this.setState({showBookingModal: false})}
        style={bookingModalStyles}
        contentLabel="Map and directions"
      >
        <div style={{padding: 0}}>
          <div style={{textAlign: 'right', fontSize: 18, color: colors.white, marginTop: 0, marginBottom: 0, marginRight: 0}}>
            <div
              onClick={() => this.setState({showBookingModal: false})}
              style={{color: '#000000', backgroundColor: colors.white, paddingRight: 10, paddingTop: 6, paddingBottom: 4}}
            >
              <FontAwesomeIcon icon={faTimes} style={{fontSize: 28, color: '#000000'}} />
            </div>
          </div>
          <div style={{width: '100%', minHeight: 600, border: 0, backgroundColor: colors.white }}>
            <iframe src={window.location.href + '/2?min=true'} frameBorder={0} style={{width: '100%', minHeight: 600, border: 0}} />
          </div>
        </div>
      </Modal>
    )
  }

  render() {
    if (!this.state.selectedProperty && !this.props.loadedProperties) {
      return (
        <div className="content-holder">
          <ActivitySpinner />
        </div>
      );
    }

    if (!this.state.selectedProperty && this.props.loadedProperties) {
      return (
        <div>
          <div style={{display: 'flex', alignItems: 'center', marginBottom: 15, marginTop: 25, justifyContent: 'center', textAlign: 'center'}}>
            <span style={{fontSize: 22}}>Sorry, this property is no longer available.</span>
          </div>
          {this.renderPropertiesLink()}
          <div>
            <AgentCard user={this.state.user} color={this.state.primaryConsumerColor} />
            <CompanyCard user={this.state.user} />
          </div>
          <Footer />
        </div>
      )
    }

    document.title = displayAddressWithoutCountry(this.state.selectedProperty.address);
    document
      .getElementsByTagName('meta')
      .namedItem('ogTitle')
      .setAttribute('content', displayAddressWithoutCountry(this.state.selectedProperty.address))
    document
      .getElementsByTagName('meta')
      .namedItem('ogUrl')
      .setAttribute('content', window.location.href.replace('http://localhost:3000', WEB_APP_URL))

    return (
      <div style={{width: '100%', height: window.outerHeight, backgroundColor: '#EDEDED'}}>
        <Container style={{backgroundColor: '#F8F8F8'}}>
          <Row style={{paddingTop: 16}}>
            <Col md={{order: 12}}>
              {this.renderMapOrImages()}
              {!this.state.showDetails && (
                this.renderButtons()
              )}
            </Col>
            <Col md={{order: 1}}>
              {this.renderAddressAndDetails()}
              {this.state.showDetails ? (
                <div>
                  {this.renderDetails()}
                  <AgentCard user={this.state.user} color={this.state.primaryConsumerColor} />
                  <CompanyCard user={this.state.user} />
                </div>
              ) : (
                <Row>
                  <Col>
                    <AgentCard user={this.state.user} color={this.state.primaryConsumerColor} />
                    <CompanyCard user={this.state.user} />
                    {this.renderPropertiesLink()}
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
          <Footer />
          {this.renderMapModal()}
          {this.renderBookingModal()}
        </Container>
      </div>
    )
  }
}

export default Property;
