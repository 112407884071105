import { combineReducers } from 'redux';
import feedback, * as fromFeedback from './feedbackReducer';
import sheet, * as fromSheet from './sheetReducer';
import property, * as fromProperty from './propertyReducer';
import user, * as fromUser from './userReducer';
import stats, * as fromStats from './statsReducer';
import portal, * as fromPortal from './portalReducer';
import tour, * as fromTour from './tourReducer';

export default combineReducers({
  feedback,
  sheet,
  property,
  user,
  stats,
  portal,
  tour,
});

export const getFeedback = (state) => fromFeedback.getFeedback(state.feedback);
export const getSheet = (state) => fromSheet.getSheet(state.sheet);
export const getProperty = (state) => fromProperty.getProperty(state.property);
export const getUser = (state) => fromUser.getUser(state.user);
export const getStats = (state) => fromStats.getStats(state.stats);
export const getPortal = (state) => fromPortal.getPortal(state.portal);
export const getTour = (state) => fromTour.getTour(state.tour);
