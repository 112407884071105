import React, { Component } from 'react';
import QRCode from "react-qr-code";
import Pdf from 'react-to-pdf';
import imageDataURI from 'image-data-uri';
import '../../css/realhappy-sheet.scss';
import {formatPhoneNumber, getIncomingTextCode, translation} from "../../tools";
import logoColor from "../../img/avatar.png";
import ActivitySpinner from "../../components/ActivitySpinner";
import {WEB_APP_URL} from "../../environmentVariables";

const fileReader = new FileReader();

const ref = React.createRef();

class Sheet extends Component {
  constructor(props) {
    super(props);
    this.sheetId = this.props.match.params.key;
  }

  state = {
    sheet: {}
  };

  UNSAFE_componentWillMount() {
    this.props.getSheet(this.sheetId);
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.sheet && nextProps.sheet !== this.props.sheet) {
      this.setState({
        sheet: nextProps.sheet
      })
    }
  }

  render() {
    if (this.props.loadingSheet) {
      return (
        <div className="content-holder">
          <ActivitySpinner />
        </div>
      );
    }

    if (this.props.loadedSheet) {
      document.title = this.state.sheet.propertyAddress;
      return (
        <div>
          <Pdf targetRef={ref} filename={this.sheetId + '.pdf'} x={5} y={1} scale={1}>
            {({ toPdf }) => (
              <div style={{position: 'absolute', top: 10, left: 10}}>
                <div
                  style={{cursor: 'pointer', paddingTop: 10, paddingBottom: 10, paddingRight: 20, paddingLeft: 20, backgroundColor: '#000000', borderRadius: 5, color: '#FFFFFF'}}
                  onClick={toPdf}>
                  Tap to generate PDF
                </div>
              </div>
            )}
          </Pdf>
          <div ref={ref}>
            <div style={{alignItems: 'center', paddingTop: 30, paddingBottom: 20}}>
              <p style={{textAlign: 'center'}}>
                {!!this.state.sheet.senderAvatar ? (
                  <img src={this.state.sheet.senderAvatar} alt={'Real Happy'}
                       style={{height: 100, width: 100, alignSelf: 'center', borderRadius: 50}}/>
                ) : (
                  <img src={logoColor} alt={'Real Happy'}
                       style={{height: 100, width: 100, alignSelf: 'center', borderRadius: 50}}/>
                )}
              </p>
            </div>
            <div style={{alignItems: 'center', paddingTop: 30, paddingBottom: 10}}>
              <p style={{textAlign: 'center', color: '#000000', fontSize: 44}}>REGISTER</p>
            </div>
            <div style={{alignItems: 'center', paddingTop: 10, paddingBottom: 50}}>
              <p style={{textAlign: 'center', color: '#000000', fontSize: 24}}>{this.state.sheet.propertyAddress}</p>
            </div>
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', paddingLeft: 50, paddingRight: 50}}>
              <div style={{flex: 1, alignItems: 'center', textAlign: 'center', alignSelf: 'flex-start'}}>
                <div style={{alignItems: 'center', paddingTop: 5, paddingBottom: 40, paddingLeft: 30, paddingRight: 30}}>
                  <p style={{
                    textAlign: 'center',
                    color: '#000000',
                    fontSize: 44,
                    marginBottom: 8,
                    fontWeight: 'bold'
                  }}>SCAN</p>
                  <p style={{textAlign: 'center', color: '#000000', fontSize: 24}}>Simply point your camera at this code
                    and tap the link that appears.</p>
                </div>
                <QRCode value={WEB_APP_URL + '/r/' + this.state.sheet.textCode} size={260}/>
              </div>
            </div>
            <div style={{clear: 'both'}}/>
            <div style={{paddingTop: 100, paddingBottom: 50, paddingLeft: 50, flexDirection: 'row', display: 'flex'}}>
              <div style={{width: 80}}>
                {!!this.state.sheet.senderAvatar ? (
                  <img src={this.state.sheet.senderAvatar} alt={'Real Happy'}
                       style={{height: 60, width: 60, alignSelf: 'center', borderRadius: 30}}/>
                ) : (
                  <img src={logoColor} alt={'Real Happy'}
                       style={{height: 60, width: 60, alignSelf: 'center', borderRadius: 30}}/>
                )}
              </div>
              <div style={{flex: 1}}>
                <p style={{fontSize: 24}}>Hosted by {this.state.sheet.senderName}</p>
                <p style={{fontSize: 24}}>{this.state.sheet.companyName}</p>
                <p
                  style={{fontSize: 24}}>{this.state.sheet.senderPhoneNumber && formatPhoneNumber(this.state.sheet.senderPhoneNumber, this.state.sheet.senderIso2)}</p>
                <p style={{fontSize: 24}}>{this.state.sheet.senderEmail}</p>
              </div>
            </div>
            <div className={"footer"} style={{backgroundColor: '#FFFFFF', color: '#000000'}}>
              <p style={{color: '#000000'}}>realhappy.net/terms</p>
            </div>
          </div>
        </div>
      );
    }

    return null;
  }
}

export default Sheet;
