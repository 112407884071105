import firebase from 'firebase';
import * as actionConstants from '../actionConstants';

/**
 * Get user
 *
 * @param userId
 * @returns {Function}
 */
export const getUser = userId => {
  return (dispatch, getState) => {
    dispatch({
      type: actionConstants.LOADING_USER,
    });
    firebase.firestore()
      .collection('users')
      .doc(userId)
      .get()
      .then(doc => {
        dispatch({
          type: actionConstants.LOADED_USER,
          payload: {id: doc.id, ...doc.data()},
        });
      });
  };
};

/**
 * Update user docuSign Auth details
 *
 * @param code
 * @param userId
 * @returns {Function}
 */
export const setDocuSignAccessToken = (code, userId) => {
  return async (dispatch, getState) => {
    dispatch({
      type: actionConstants.SETTING_AUTH,
    });

    const docuSignResponse = await firebase.functions().httpsCallable('getDocuSignAccessToken')({code});
    const docuSignData = docuSignResponse.data;
    const accessToken = docuSignData.access_token;
    const refreshToken = docuSignData.refresh_token;

    const docuSignBaseUrlResponse = await firebase.functions().httpsCallable('getDocuSignBaseUrl')({accessToken});
    const docuSignBaseUrlData = docuSignBaseUrlResponse.data;

    let baseUrl = docuSignBaseUrlData.accounts[0].base_uri;

    firebase.firestore()
      .collection('users')
      .doc(userId)
      .update({
        docuSignAuth: {
          accessToken: accessToken || null,
          refreshToken: refreshToken || null,
          baseUrl,
        }
      })
      .then(doc => {
        dispatch({
          type: actionConstants.SET_AUTH,
        });
      });
  };
};

export const setGoogleAuthTokens = (userId, tokens) => dispatch => {
  dispatch({ type: actionConstants.SETTING_AUTH });
  firebase.firestore()
    .collection('users')
    .doc(userId)
    .update({
      googleApi: {
        accessToken: tokens.access_token || null,
        refreshToken: tokens.refresh_token || null,
      }
    })
    .then(doc => {
      dispatch({ type: actionConstants.SET_AUTH });
    });
}
