import React, {Component} from 'react';
import {Widget, WidgetContent, Button} from "@duik/it";
import Datetime from 'react-datetime';
import moment from "moment";
import AddToCalendar from '@culturehq/add-to-calendar';
import {translation} from "../tools";
import "react-datetime/css/react-datetime.css";
import "@culturehq/add-to-calendar/dist/styles.css";
import {WEB_APP_URL} from "../environmentVariables";

const tickIcon = require('../img/tick.png');
const blockIcon = require('../img/block.png');
const warningIcon = require('../img/warning.png');
const googleMaps = require('../img/google-maps.png');

class ShowingCard extends Component {
  checkConfirmation(callback) {
    if (window.confirm('Are you sure you want to confirm this ' + translation('viewing', this.props.locale, false) + ' request?')) {
      callback()
    } else {
      return false;
    }
  }

  checkDecline(callback) {
    if (window.confirm('Are you sure you want to decline this ' + translation('viewing', this.props.locale, false) + ' request?')) {
      callback()
    } else {
      return false;
    }
  }

  checkCancel(callback, source, showing) {
    if (window.confirm('Are you sure you want to cancel this ' + translation('viewing', this.props.locale, false) + '? We will automatically notify all parties.')) {
      callback();
      if (source === 'booker') {
        if (window.confirm('Do you want to reschedule this ' + translation('viewing', this.props.locale, false) + '?')) {
          window.location.href = WEB_APP_URL + '/b/' + showing.sender + '/' + showing.property + '/2'
        } else {
          return false;
        }
      }
    } else {
      return false;
    }
  }

  checkReschedule(callback) {
    if (window.confirm('Are you sure you want to reschedule this ' + translation('viewing', this.props.locale, false) + '? We will cancel this ' + translation('viewing', this.props.locale, false) + ' and automatically notify all parties.')) {
      callback()
    } else {
      return false;
    }
  }

  renderShowingButtons(showing) {
    if (showing.status === 'declined') {
      return (
        <div style={{paddingTop: 16}}>
          <div>
            <img src={blockIcon} style={{height: 30}}/>
          </div>
          <div style={{paddingTop: 6, paddingLeft: 8}}>
            <span style={{color: '#FF0000', fontSize: 16, fontWeight: 600}}>CANCELLED</span>
          </div>
        </div>
      )
    }
    if (showing.status === 'cancelled') {
      return (
        <div style={{paddingTop: 16, display: 'flex', flexDirection: 'row'}}>
          <div>
            <img src={blockIcon} style={{height: 30}}/>
          </div>
          <div style={{paddingTop: 6, paddingLeft: 8}}>
            <span style={{color: '#FF0000', fontSize: 16, fontWeight: 600}}>CANCELLED</span>
          </div>
        </div>
      )
    }
    if (showing.status === 'requested' && showing.confirmBy === 'agent') {
      return (
        <div style={{paddingTop: 16, display: 'flex', flexDirection: 'row'}}>
          <div>
            <img src={warningIcon} style={{height: 30}}/>
          </div>
          <div style={{paddingTop: 6, paddingLeft: 8}}>
            <span style={{color: '#F5A623', fontSize: 16, fontWeight: 600}}>PENDING</span>
          </div>
        </div>
      )
    }
    if (showing.status === 'requested' && (showing.confirmBy === 'owner' || showing.confirmBy === 'agent and owner') && this.props.source === 'owner') {
      return null
    }
    if (showing.status === 'requested') {
      return (
        <div style={{paddingTop: 16, display: 'flex', flexDirection: 'row'}}>
          <div>
            <img src={warningIcon} style={{height: 30}}/>
          </div>
          <div style={{paddingTop: 6, paddingLeft: 8}}>
            <span style={{color: '#F5A623', fontSize: 16, fontWeight: 600}}>PENDING</span>
          </div>
        </div>
      )
    }
    return (
      <div style={{paddingTop: 16, display: 'flex', flexDirection: 'row'}}>
        <div>
          <img src={tickIcon} style={{height: 30}}/>
        </div>
        <div style={{paddingTop: 6, paddingLeft: 8}}>
          <span style={{color: '#46B821', fontSize: 16, fontWeight: 600}}>CONFIRMED</span>
        </div>
      </div>
    )
  }

  renderReschedule(showing) {
    if (showing.status === 'requested' && (showing.confirmBy === 'owner')) {
      return null;
    }
    return (
      <div style={{justifyContent: 'space-evenly', display: 'flex', marginTop: 10, marginBottom: 0}}>
        <Button style={{color: '#343342', borderColor: '#343342', width: '100%'}} transparent onClick={() => this.checkCancel(() => this.props.cancelShowing(showing, this.props.phoneNumber, this.props.source), this.props.source, showing)}>CANCEL</Button>
      </div>
    )
  }

  renderShowingStatus(showing) {
    let text = '';
    switch (showing.status) {
      case 'confirmed':
        text = translation('viewing', this.props.locale, true) + ' Confirmed';
        break;
      case 'declined':
        text = translation('viewing', this.props.locale, true) + ' Declined';
        break;
      case 'cancelled':
        text = translation('viewing', this.props.locale, true) + ' Cancelled';
        break;
      default:
        text = translation('viewing', this.props.locale, true) + ' Request';
        break;
    }
    return (
      <div style={{fontSize: 14, marginBottom: 10, fontWeight: 'bold'}}>{text}</div>
    )
  }

  render() {
    let showing = this.props.showing;
    return (
      <Widget style={{marginBottom: 15, border: 'none', borderRadius: 7}}>
        <WidgetContent>
          <div style={{fontSize: 24, fontWeight: 'bold', marginBottom: 12}}>{moment(showing.viewingDateTime).format('h:mma MMMM D Y')}</div>
          <div style={{flexDirection: 'row', display: 'flex', marginBottom: 12, fontSize: 16}}>
            <div style={{flex: 5, display: 'flex', paddingRight: 10}}>{showing.propertyAddress}</div>
            <div style={{flex: 1, display: 'flex', textAlign: 'right'}}>
              <a href={'https://www.google.com/maps/place/' + encodeURI(showing.propertyAddress)} target='_blank'><img src={googleMaps} style={{height: 30}} /></a>
            </div>
          </div>
          {showing.status !== 'requested' && showing.status !== 'cancelled' && showing.status !== 'declined' && (
            <div style={{marginBottom: 6}}>
              <AddToCalendar
                event={{
                  name: 'Confirmed showing',
                  details: "Property showing at " + showing.propertyAddress,
                  location: showing.propertyAddress,
                  startsAt: moment(showing.viewingDateTime).toISOString(),
                  endsAt: moment(showing.viewingDateTime).add(25, 'minutes').toISOString()
                }}
              />
            </div>
          )}
          {this.props.showInstructions && showing.viewingDetails && this.props.source === 'booker' && showing.status === 'confirmed' && (
            <div>
              <div style={{fontSize: 18, marginBottom: 6, fontWeight: 'bold'}}>{translation('viewing', this.props.locale, true)} Instructions:</div>
              <div style={{fontSize: 16, marginBottom: 12}}>{showing.viewingDetails}</div>
            </div>
          )}
          {this.renderShowingStatus(showing)}
          {(showing.status === 'requested' && (showing.confirmBy === 'owner' || showing.confirmBy === 'agent and owner') && this.props.source === 'owner') && (
            <div style={{justifyContent: 'space-evenly', display: 'flex', marginTop: 30, marginBottom: 0}}>
              <div style={{flex: 1, paddingRight: 5}}>
                <Button style={{color: '#FFFFFF', backgroundColor: '#343342', borderColor: '#343342', width: '100%'}}  onClick={() => this.checkConfirmation(() => this.props.confirmShowing(showing, this.props.phoneNumber, this.props.locale))} primary>CONFIRM</Button>
              </div>
              <div style={{flex: 1, paddingLeft: 5}}>
                <Button style={{color: '#343342', borderColor: '#343342', width: '100%'}} transparent onClick={() => this.checkDecline(() => this.props.declineShowing(showing, this.props.phoneNumber))}>DECLINE</Button>
              </div>
            </div>
          )}
          <div style={{display: 'flex', flexDirection: 'row'}}>
            <div style={{width: '50%'}}>
              {this.renderShowingButtons(showing)}
            </div>
            <div style={{width: '50%'}}>
              {showing.status !== 'cancelled' && showing.status !== 'declined' && (
                this.renderReschedule(showing)
              )}
            </div>
          </div>
        </WidgetContent>
      </Widget>
    )
  }
}

export default ShowingCard;
