import firebase from 'firebase';
import * as actionConstants from '../actionConstants';
import {getE164, translation} from "../tools";
import {getShowingsForBooker} from './bookerPortalActions';
import moment from  'moment';
import { orderBy } from 'lodash';

export const getUserFromPhoneNumber = (phoneNumber) => (dispatch) => {
  dispatch({type: actionConstants.LOADING_PORTAL_USER});
  firebase
    .firestore()
    .collectionGroup('contacts')
    .where('phoneNumber', '==', phoneNumber)
    .limit(1)
    .get()
    .then(querySnapshot => {
      if (querySnapshot.size > 0) {
        let doc = querySnapshot.docs[0];
        let portalUser = {id: doc.id, ...doc.data()};
        dispatch({type: actionConstants.LOADED_PORTAL_USER, payload: portalUser});
      }
    })
    .catch(error => dispatch({ type: actionConstants.LOADING_PORTAL_USER_FAILED, payload: error }))
};

/**
 * Load all showings by phone number
 *
 * @returns {function(*, *)}
 */
export const getShowings = (phoneNumber) => async(dispatch) => {
  dispatch({ type: actionConstants.LOADING_SHOWINGS });
  let latestCreation;
  let showings = await firebase
    .firestore()
    .collectionGroup('viewings')
    .where('propertyOwners', 'array-contains', phoneNumber)
    .orderBy('createdAt', 'asc')
    .get()
    .then(async (querySnapshot) => {
      let results = [];
      let agent = {};
      if (querySnapshot.size > 0) {
        querySnapshot.forEach(doc => {
          if (moment(doc.data().viewingDateTime) > moment().startOf('day')) {
            results.push({id: doc.id, ...doc.data(), status: doc.data().cancelled ? 'cancelled' : 'confirmed'})
          }
        });
        let userId = querySnapshot.docs[querySnapshot.size - 1].ref.parent.parent.id
        latestCreation = querySnapshot.docs[querySnapshot.size - 1].createdAt
        agent = await firebase.firestore().collection('users').doc(userId).get().then(doc => ({id: doc.id, ...doc.data()}))
      }
      dispatch({type: actionConstants.LOADED_AGENT, payload: agent});
      return results;
    }).catch((error) => {
      console.log(error);
      return [];
    });

  let requestedShowings = await firebase
    .firestore()
    .collectionGroup('requestedViewings')
    .where('propertyOwners', 'array-contains', phoneNumber)
    .orderBy('createdAt', 'asc')
    .get()
    .then(async (querySnapshot) => {
      let results = [];
      let agent = {};
      if (querySnapshot.size > 0) {
        querySnapshot.forEach(doc => {
          if (moment(doc.data().viewingDateTime) > moment().startOf('day') && !doc.data().declined) {
            results.push({id: doc.id, ...doc.data(), status: doc.data().declined ? 'declined' : 'requested'})
          }
        });
        if (latestCreation && moment(latestCreation).isBefore(moment(querySnapshot.docs[querySnapshot.size - 1].createdAt))) {
          let userId = querySnapshot.docs[querySnapshot.size - 1].ref.parent.parent.id
          agent = await firebase.firestore().collection('users').doc(userId).get().then(doc => ({id: doc.id, ...doc.data()}))
          dispatch({type: actionConstants.LOADED_AGENT, payload: agent});
        }
      }
      return results;
    }).catch((error) => {
      console.log(error);
      return [];
    });

  showings = showings.concat(requestedShowings).sort((a, b) => moment(a.viewingDateTime).format('x') - moment(b.viewingDateTime).format('x'));

  dispatch({ type: actionConstants.LOADED_SHOWINGS, payload: showings });
};

export const confirmShowing = (showing, phoneNumber, locale) => async dispatch => {

  let viewings = await firebase
    .firestore()
    .collection('users')
    .doc(showing.sender)
    .collection('viewings')
    .where('phoneNumber', '==', phoneNumber)
    .get()
    .then(querySnapshot => {
      let results = [];
      querySnapshot.forEach(doc => {
        if (
          doc.data().property === showing.property &&
          doc.data().feedbackForm === showing.feedbackForm
        ) {
          results.push({id: doc.id, ...doc.data(), status: doc.data().declined ? 'declined' : 'requested'})
        }
      });
      return results;
    });

  let viewingCount = viewings.length;

  let hasExistingViewing = viewings.filter(viewing => viewing.property === showing.property && moment(viewing.viewingDateTime).format('Y-M-D H:mm') === moment(showing.viewingDateTime).format('Y-M-D H:mm')).length > 0

  if (hasExistingViewing) {
    if (!window.confirm('You already have an accepted ' + translation('viewing', locale, false) + ' at this time. Are you sure you want to accept this?')) {
      return false;
    }
  }

  firebase
    .firestore()
    .collection('users')
    .doc(showing.sender)
    .collection('requestedViewings')
    .doc(showing.id)
    .get()
    .then(doc => {
      if (showing.confirmBy === 'owner') {
        firebase
          .firestore()
          .collection('users')
          .doc(showing.sender)
          .collection('viewings')
          .add({...doc.data(), viewingCount})
          .then(() => {
            doc.ref.delete()
            dispatch(getShowings(phoneNumber))
          })
      } else {
        doc.ref.update({
          confirmBy: 'agent'
        })
          .then(() => {
            dispatch(getShowings(phoneNumber))
          })
      }
    })

};

export const declineShowing = (showing, phoneNumber) => dispatch => {
  firebase
    .firestore()
    .collection('users')
    .doc(showing.sender)
    .collection('requestedViewings')
    .doc(showing.id)
    .update({
      declined: true,
    })
    .then(() => {
      dispatch(getShowings(phoneNumber))
    })
};

export const cancelShowing = (showing, phoneNumber, source) => dispatch => {
  let collection = showing.status === 'requested' ? 'requestedViewings' : 'viewings';
  let newStatus = showing.status === 'requested' ? 'declined' : 'cancelled';
  firebase
    .firestore()
    .collection('users')
    .doc(showing.sender)
    .collection(collection)
    .doc(showing.id)
    .update({
      [newStatus]: true,
    })
    .then(() => {
      if (source === 'booker') {
        dispatch(getShowingsForBooker(phoneNumber))
      } else {
        dispatch(getShowings(phoneNumber))
      }
    })
};
