import React, {useState, useMemo, useEffect, useRef} from 'react';
import {useSelector} from 'react-redux';
import ActivitySpinner from '../../components/ActivitySpinner';
import { useTable, useSortBy, useResizeColumns } from 'react-table';
import '../../css/realhappy-feedback.scss';

const DataStats = (props) => {
  const passCode = props.match.params.key;

  useEffect(() => {
    if (passCode === '8bfbe3862661e3b38fe825529e48ae08') {
      props.getDataStats();
    }
  }, []);

  const [search, setSearch] = useState('');
  const {users} = useSelector(state => state.stats);

  const searchInput = useRef(null)

  const filterUsers = users => {
    users = users.filter(user => user.phoneNumber && user.phoneNumber.indexOf('708555') === -1)
    if (search.length > 0) {
      return users.filter(user => (user.name && user.name.toLowerCase().indexOf(search.toLowerCase()) !== -1) || (user.email && user.email.toLowerCase().indexOf(search.toLowerCase()) !== -1) || (user.phoneNumber && user.phoneNumber.indexOf(search) !== -1))
    }
    return users;
  };

  const data = useMemo(() => filterUsers(users) || [], [users, search]);

  const columns = useMemo(() => [
    {
      Header: 'Name',
      accessor: 'name', // accessor is the "key" in the data
    },
    {
      Header: 'Phone Number',
      accessor: 'phoneNumber',
    },
    {
      Header: 'Email',
      accessor: 'email',
    },
    {
      Header: 'Country',
      accessor: 'iso2',
    },
    {
      Header: 'Account',
      accessor: 'accountType',
    },
    {
      Header: 'Properties',
      accessor: 'properties',
    },
    {
      Header: 'Contacts',
      accessor: 'contacts',
    },
    {
      Header: 'Forms',
      accessor: 'forms',
    },
    {
      Header: 'Feedback Sent',
      accessor: 'feedbackSent',
    },
    {
      Header: 'Feedback Received',
      accessor: 'feedbackReceived',
    },
    {
      Header: 'Viewings',
      accessor: 'viewings',
    },
  ], []);

  const debounce = (callback, delay) => {
    const timerClear = () => clear = true;
    let clear = true;
    return event => {
      if (clear) {
        clear = false;
        setTimeout(timerClear, delay);
        callback(event);
      }
    };
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data }, useSortBy, useResizeColumns);

  const renderContent = () => {

    if (props.loadingStats) {
      return (
        <div className="content-holder">
          <ActivitySpinner />
        </div>
      );
    }

    if (props.loadedStats) {
      return (
        <div>
          <div style={{marginBottom: 20, }}>
            <input type={'text'} ref={searchInput} name={'search'} onBlur={(event) => setSearch(event.target.value)} placeholder={'Search'} /> <button>Search</button> <button onClick={() => { setSearch(''); searchInput.current.value = '';}}>Reset</button>
          </div>
          <table {...getTableProps()}>
            <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render('Header')}
                    {/* Add a sort direction indicator */}
                    <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? ' 🔽'
                        : ' 🔼'
                      : ''}
                  </span>
                  </th>
                ))}
              </tr>
            ))}
            </thead>
            <tbody {...getTableBodyProps()}>
            {rows.map(row => {
              prepareRow(row)
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map(cell => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        style={{
                          padding: '10px',
                          background: '#CCCCCC',
                        }}
                      >
                        {cell.render('Cell')}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
            </tbody>
          </table>
        </div>
      );
    }

    return null;
  };

  // renderUsers() {
  //   return this.state.users.map(user => {
  //     return (
  //       <tr key={user.id}>
  //         <td>{user.name}</td>
  //         <td>{user.phoneNumber}</td>
  //         <td>{user.email}</td>
  //         <td>{user.iso2 && user.iso2.toUpperCase()}</td>
  //         <td>{user.accountType}</td>
  //         <td>{user.properties}</td>
  //         <td>{user.contacts}</td>
  //         <td>{user.forms}</td>
  //         <td>{user.feedback}</td>
  //         <td>{user.viewings}</td>
  //       </tr>
  //     )
  //   })
  // }

  return (
    <div>
      <div style={{paddingTop: 30, paddingBottom: 30, paddingLeft: 20, paddingRight: 20}}>
        {renderContent()}
      </div>
    </div>
  );
};

export default DataStats;
