// @flow weak
import * as actionConstants from '../actionConstants';

// Reducer
const DEFAULT_STATE = {
  loadingShowings: false,
  loadedShowings: false,
  loadingShowingsFailed: false,
  showings: [],
  loadingPortalUser: false,
  loadedPortalUser: false,
  loadingPortalUserFailed: false,
  portalUser: {},
  loadingAgent: false,
  loadedAgent: false,
  agent: {},
};

export default function (state = DEFAULT_STATE, action) {
  switch (action.type) {
    case actionConstants.LOADING_SHOWINGS:
      return {
        ...state, loadingShowings: true, loadedShowings: false, loadingShowingsFailed: false
      };
    case actionConstants.LOADED_SHOWINGS:
      return {
        ...state, loadingShowings: false, loadedShowings: true, loadingShowingsFailed: false, showings: action.payload
      };
    case actionConstants.LOADING_SHOWINGS_FAILED:
      return {
        ...state, loadingShowings: false, loadedShowings: false, loadingShowingsFailed: true
      };
    case actionConstants.LOADING_PORTAL_USER:
      return {
        ...state, loadingPortalUser: true, loadedPortalUser: false, loadingPortalUserFailed: false
      };
    case actionConstants.LOADED_PORTAL_USER:
      return {
        ...state, loadingPortalUser: false, loadedPortalUser: true, loadingPortalUserFailed: false, portalUser: action.payload
      };
    case actionConstants.LOADING_PORTAL_USER_FAILED:
      return {
        ...state, loadingPortalUser: false, loadedPortalUser: false, loadingPortalUserFailed: true
      };
    case actionConstants.LOADING_USER:
      return {
        ...state, loadingUser: true, loadedUser: false
      };
    case actionConstants.LOADED_AGENT:
      return {
        ...state, loadingAgent: false, loadedAgent: true, agent: action.payload
      };  
    default:
      return state;
  }
}

// Selectors (mapStateToP rops)
export const getPortal = ({
  loadingShowings, loadedShowings, loadingShowingsFailed, showings, loadingPortalUser, loadedPortalUser, loadingPortalUserFailed, portalUser, loadingAgent, loadedAgent, agent
}) => ({
  loadingShowings, loadedShowings, loadingShowingsFailed, showings, loadingPortalUser, loadedPortalUser, loadingPortalUserFailed, portalUser, loadingAgent, loadedAgent, agent
});
