import React from 'react';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const ActivitySpinner = () => (
  <div style={{ padding: 10, textAlign: 'center' }}>
    <FontAwesomeIcon icon={faCircleNotch} spin style={{fontSize: '35px', color: '#3D435B'}}/>
  </div>
);

export default ActivitySpinner;
