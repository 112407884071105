import React, { Component } from 'react';
import { getUserLocale } from 'get-user-locale';
import {
  TopBar,
  TopBarSection,
  TopBarTitle,
  ContainerHorizontal,
  ScrollArea,
  Widget,
  WidgetContainer,
  WidgetHeader,
  WidgetContent,
  Button,
  Dropdown,
  DropdownItem,
  Avatar, TabContainer, TabItem, ContainerVertical
} from '@duik/it';
import '../../css/duikstyles.scss';
import '@duik/icon/dist/styles.css';
import Icon from '@duik/icon';
import classnames from "classnames";
import ActivitySpinner from '../../components/ActivitySpinner';

import moment from 'moment';
import {translation} from "../../tools";
import ShowingCard from "../../components/ShowingCard";
import AgentCard from "../../components/AgentCard";
import CompanyCard from "../../components/CompanyCard";
import {colors} from "../../styles";
import {WEBSITE_URL} from "../../environmentVariables";
import HeaderAvatar from '../../components/HeaderAvatar';
import Footer from '../../components/Footer';

const CryptoJS = require("crypto-js");
const _ = require('lodash');

const language = (getUserLocale() && getUserLocale().length === 5) ? getUserLocale() : 'en_US';
const locale = language.substr(-2).toLowerCase();

class OwnerPortal extends Component {
  constructor(props) {
    super(props);
    let phoneNumberEncrypted = props.match.params.key;
    if (phoneNumberEncrypted.indexOf('+') !== -1) {
      console.log(this.enc(phoneNumberEncrypted))
      return
    }
    this.phoneNumber = this.dec(phoneNumberEncrypted);
    props.getUserFromPhoneNumber(this.phoneNumber);
    props.getShowings(this.phoneNumber);
  }

  dec(cipherText) {
    let reb64 = CryptoJS.enc.Hex.parse(cipherText);
    let bytes = reb64.toString(CryptoJS.enc.Base64);
    let decrypt = CryptoJS.AES.decrypt(bytes, 'secret key RH79');
    let plain = decrypt.toString(CryptoJS.enc.Utf8);
    return plain;
  }

  enc(plainText) {
    let b64 = CryptoJS.AES.encrypt(plainText, 'secret key RH79').toString();
    let e64 = CryptoJS.enc.Base64.parse(b64);
    let eHex = e64.toString(CryptoJS.enc.Hex);
    return eHex;
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.showings && nextProps.showings !== this.props.showings) {
      this.setState({showings: this.sortShowings(nextProps.showings)})
    }
    if (nextProps.portalUser && nextProps.portalUser !== this.props.portalUser) {
      this.setState({portalUser: nextProps.portalUser})
    }
    if (nextProps.agent && nextProps.agent !== this.props.agent) {
      this.setState({agent: nextProps.agent})
    }
  }

  state = {
    showings: [],
    portalUser: {},
    agent: {},
  };

  sortShowings(showings) {
    let actionable = _.remove(showings, showing => (showing.confirmBy === 'owner' || showing.confirmBy === 'agent and owner') && showing.status === 'requested')
    showings.sort((a, b) => b.viewingDateTime - a.viewingDateTime);
    actionable.sort((a, b) => b.viewingDateTime - a.viewingDateTime);
    return actionable.concat(showings)
  }

  renderShowings() {
    return this.state.showings.map(showing => this.renderShowingCard(showing))
  }

  renderShowingCard(showing) {
    return (
      <ShowingCard
        key={showing.id}
        showing={showing}
        locale={locale}
        confirmShowing={this.props.confirmShowing}
        declineShowing={this.props.declineShowing}
        rescheduleShowing={this.props.rescheduleShowing}
        cancelShowing={this.props.cancelShowing}
        showInstructions
        phoneNumber={this.phoneNumber}
        source={'owner'}
      />
    )
  }

  renderContent() {
    if (this.props.loadedPortalUser && this.props.loadedAgent && this.props.loadedShowings && this.state.showings.length === 0) {
      return (
        <WidgetContainer style={{padding: 15}}>
          <Widget style={{marginBottom: 15, border: 'none', borderRadius: 7}}>
            <WidgetContent style={{padding: 15}}>
              <span style={{fontSize: 18, fontWeight: 'bold', color: '#000000'}}>Welcome to your personal showing portal.</span><br /><br />
              <span style={{fontSize: 18, color: '#000000'}}>
                Here you will be able to confirm showing requests and view upcoming showings.<br /><br />
                You can bookmark this link &amp; we will also message you when a new showing request is made.
              </span>
            </WidgetContent>
          </Widget>
        </WidgetContainer>
      )
    }
    if (this.props.loadedPortalUser && this.props.loadedAgent && this.props.loadedShowings && this.state.showings.length > 0) {
      return (
        <WidgetContainer style={{padding: 15}}>
          {this.renderShowings()}
        </WidgetContainer>
      )
    }
    return (
      <ActivitySpinner />
    )
  }

  render() {
    return (
      <div id={'duikStyles'} style={{color: '#000000'}}>
        <ContainerVertical style={{maxWidth: 420, alignItems: 'center', justifyContent: 'center', marginRight: 'auto', marginLeft: 'auto'}}>
          <ContainerVertical>
            <div style={{display: 'flex', alignItems: 'center', marginTop: 20, marginBottom: 15, justifyContent: 'center', width: '100%'}}>
              <HeaderAvatar user={this.state.agent} />
            </div>
            <div style={{display: 'flex', alignItems: 'center', marginTop: 10, marginBottom: 15, justifyContent: 'center', width: '100%', fontSize: 20, color: '#000000', fontWeight: 'bold'}}>
              Your showing portal
            </div>
            <div style={{display: 'flex', alignItems: 'center', marginTop: 0, marginBottom: 15, justifyContent: 'center', width: '100%', fontSize: 16, color: '#000000', fontWeight: 400, textAlign: 'center'}}>
              {this.state.agent && this.state.agent.id ? (
                <span>{this.state.portalUser.name} Provided<br />by {this.state.agent.detailsToShow && this.state.agent.detailsToShow === 'company' && this.state.agent.company ? this.state.agent.company : this.state.agent.name}</span>
              ) : (
                <span>{this.state.portalUser.name}</span>
              )}
            </div>
          </ContainerVertical>
          <ContainerHorizontal>
            <ScrollArea>
              {this.renderContent()}
            </ScrollArea>
          </ContainerHorizontal>
          <ContainerHorizontal>
            <div style={{flex: 1, marginBottom: 20}}>
              <AgentCard user={this.state.agent} color={this.state.agent.primaryConsumerColor} />
              <CompanyCard user={this.state.agent} />
            </div>
            
          </ContainerHorizontal>
          <ContainerHorizontal style={{paddingLeft: 10}}>
            <Footer />
          </ContainerHorizontal>
        </ContainerVertical>
      </div>
    )
  }
}

export default OwnerPortal;
