import React, { PureComponent } from 'react';
//import '../css/realhappy-sheet.css';

/**
 * The template for all the auth routes
 */
class SheetTemplate extends PureComponent {
  render() {
    return (
      <div style={{width: 750, margin: 8}}>
        { this.props.children }
      </div>
    );
  }
}


export default SheetTemplate;
