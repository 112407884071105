// @flow weak
import * as actionConstants from '../actionConstants';

// Reducer
const DEFAULT_STATE = {
  tour: {},
  loadingTour: false,
  loadedTour: false,
};

export default function (state = DEFAULT_STATE, action) {
  switch (action.type) {
    case actionConstants.LOADING_TOUR:
      return {
        ...state, loadingTour: true, loadedTour: false
      };
    case actionConstants.LOADED_TOUR:
      return {
        ...state, loadingTour: false, loadedTour: true, tour: action.payload
      };
    default:
      return state;
  }
}

// Selectors (mapStateToP rops)
export const getTour = ({
  tour, loadingTour, loadedTour,
}) => ({
  tour,
  loadingTour,
  loadedTour,
});
