import firebase from 'firebase';
import * as actionConstants from '../actionConstants';
import {getUser} from './userActions';

/**
 * Get tour
 *
 * @param tourId
 * @param userId
 * @returns {Function}
 */
export const getTour = (tourId, userId) => {
  return (dispatch, getState) => {
    dispatch({
      type: actionConstants.LOADING_TOUR,
    });
    firebase.firestore()
      .collection('users')
      .doc(userId)
      .collection('tours')
      .doc(tourId)
      .onSnapshot(doc => {
        dispatch({
          type: actionConstants.LOADED_TOUR,
          payload: {id: doc.id, ...doc.data()}
        })
        dispatch(getUser(userId))
      })
  };
};
