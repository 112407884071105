import React, { Component } from 'react';
import {faCoffeeTogo} from "@fortawesome/pro-solid-svg-icons/faCoffeeTogo";
import {faCheck} from "@fortawesome/pro-light-svg-icons/faCheck";
import {faMapMarkerExclamation} from "@fortawesome/pro-light-svg-icons/faMapMarkerExclamation";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import { Container, Row, Col } from "react-bootstrap";

import ActivitySpinner from '../../components/ActivitySpinner';
import AgentCard from '../../components/AgentCard';
import CompanyCard from '../../components/CompanyCard';

import '../../css/realhappy-booking.scss';
import '../../css/image-gallery.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import logo from '../../img/rh-logo-trans.png';
import logoColor from '../../img/avatar.png';
import {colors} from '../../styles';
import {WEB_APP_URL, WEBSITE_URL} from "../../environmentVariables";
import HeaderAvatar from '../../components/HeaderAvatar';
import Footer from '../../components/Footer';

const moment = require('moment');

class Tours extends Component {
  constructor(props) {
    super(props);
    this.userId = this.props.match.params.key;
    this.tourId = this.props.match.params.tour;
  }

  state = {
    user: null,
    tour: null,
    showMapModal: false,
  };

  UNSAFE_componentWillMount() {
    this.props.getTour(this.tourId, this.userId);
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.user && nextProps.user !== this.props.user){
      this.setState({
        user: nextProps.user,
        primaryConsumerColor: nextProps.user.primaryConsumerColor ? nextProps.user.primaryConsumerColor : '#000000'
      })
    }
    if (nextProps.tour && nextProps.tour !== this.props.tour) {
      this.setState({ tour: nextProps.tour })
    }
  }

  getIconColor(place, placeType) {
    if (placeType === 'establishment') {
      return colors.apptGreen;
    }
    if (place.status === 'Confirmed') {
      return colors.positive;
    }
    if (place.status === 'PendingOffPlatform') {
      return colors.negative
    }
    return colors.apptOrange;
  }

  buildGoogleDirectionsQuerystring() {
    let places = this.state.tour.selectedPlaces.sort((a, b) => moment(a.viewingDateTime).unix() - moment(b.viewingDateTime).unix())
    let querystring = '&origin=My+location&waypoints=';
    places.map((place, index) => {
      if (index === places.length - 1) {
        querystring = querystring + '&destination=' + encodeURI(place.address);
      } else {
        querystring = querystring + encodeURI(place.address) + '|';
      }
    })
    return querystring;
  }

  renderPlaceStatusText(status) {
    if (status === 'Pending') {
      return (<span style={{fontSize: 10, fontWeight: 'bold'}}>Pending</span>);
    }
    if (status === 'Confirmed') {
      return (<span style={{fontSize: 10, fontWeight: 'bold'}}>Confirmed</span>);
    }
    if (status === 'PendingOffPlatform') {
      return (
        <div>
          <p style={{fontSize: 10, fontWeight: 'bold', color: colors.negative}}>Book through</p>
          <p style={{fontSize: 10, fontWeight: 'bold', color: colors.negative, marginTop: -3}}>ShowingTime</p>
        </div>
      );
    }
  }

  renderButtons() {
    return (
      <div style={{display: 'flex', marginBottom: 16, marginTop: 20}}>
        <div
          onClick={() => window.open('https://www.google.com/maps/dir/?api=1' + this.buildGoogleDirectionsQuerystring())}
          style={{display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center', cursor: 'pointer'}}>
          <div style={{display: 'table', width: 300, height: 46, border: '1px solid ' + this.state.primaryConsumerColor, borderRadius: 7, textAlign: 'center', justifyContent: 'center', backgroundColor: this.state.primaryConsumerColor, fontSize: 16, color: colors.white, paddingTop: 4}}><div style={{display: 'table-cell', verticalAlign: 'middle', height: 46, width: '100%'}}>VIEW MAP WITH DIRECTIONS</div></div>
        </div>
        {/*<div
          onClick={() => window.open('https://www.google.com/maps/dir/?api=1' + this.buildGoogleDirectionsQuerystring())}
          style={{display: 'flex', flex: 1, alignItems: 'flex-end', justifyContent: 'flex-end', paddingLeft: 6, cursor: 'pointer'}}>
          <div style={{display: 'table', width: 150, height: 46, border: '1px solid ' + this.state.primaryConsumerColor, borderRadius: 7, textAlign: 'center', justifyContent: 'center', backgroundColor: colors.white, fontSize: 13, color: this.state.primaryConsumerColor}}><div style={{display: 'table-cell', verticalAlign: 'middle', height: 46, width: '100%'}}>GET DIRECTIONS</div></div>
        </div>*/}
      </div>
    )
  }

  renderPlaceIcon(place, placeType) {
    if (placeType === 'establishment') {
      return (<FontAwesomeIcon icon={faCoffeeTogo} style={{fontSize: 18, color: colors.white}} />)
    }
    if (place.status === 'Confirmed') {
      return (<FontAwesomeIcon icon={faCheck} style={{fontSize: 18, color: colors.white}} />)
    }
    return (<FontAwesomeIcon icon={faMapMarkerExclamation} style={{fontSize: 18, color: colors.white}} />)
  }

  renderSelectedPlaces() {
    return this.state.tour.selectedPlaces.sort((a, b) => moment(a.viewingDateTime).unix() - moment(b.viewingDateTime).unix()).map(place => {
      let placeType = 'address';
      if (place.types && place.types.length > 0 && place.types.indexOf('establishment') !== -1) {
        placeType = 'establishment'
      }
      return (
        <div>
          <Row style={{marginBottom: 0}}>
            <div style={{display: 'flex', paddingLeft: 15, paddingRight: 15}}>
              <div style={{width: 50, paddingRight: 20}}>
                <div style={{width: 30, height: 30, borderRadius: 15, paddingTop: 6, textAlign: 'center', justifyContent: 'center', alignItems: 'center', backgroundColor: this.getIconColor(place, placeType)}}>
                  {this.renderPlaceIcon(place, placeType)}
                </div>
              </div>
              <div style={{width: 100, paddingTop: 4, fontWeight: 'bold'}}>{moment(place.viewingDateTime).format('h:mm a')}<br /><span>{this.renderPlaceStatusText(place.status)}</span></div>
              <div style={{flex: 1}}>
                {placeType === 'establishment' ? (
                  <div>
                    <span>{place.name}</span><br />
                    <span style={{fontSize: 12}}>{place.address.replace(', USA', '')}</span>
                  </div>
                ) : (
                  <span>{place.address.replace(', USA', '')}</span>
                )}
              </div>
            </div>
          </Row>
          <Row style={{marginBottom: 10, paddingLeft: 18}}>
            <div style={{width: 8, marginLeft: 8, backgroundColor: colors.borders, height: 30}} />
          </Row>
        </div>
      )
    })
  }

  render() {
    if (!this.state.tour || !this.state.user) {
      return (
        <div className="content-holder">
          <ActivitySpinner />
        </div>
      );
    }

    document.title = 'Tour Itinerary';
    document
      .getElementsByTagName('meta')
      .namedItem('ogTitle')
      .setAttribute('content', 'Tour Itinerary')
    document
      .getElementsByTagName('meta')
      .namedItem('ogUrl')
      .setAttribute('content', window.location.href.replace('http://localhost:3000', WEB_APP_URL))

    return (
      <div style={{width: '100%', height: window.outerHeight, backgroundColor: '#EDEDED', fontFamily: 'Open Sans, sans-serif'}}>
        <Container style={{backgroundColor: '#F8F8F8'}}>
          <Row style={{paddingTop: 16}}>
            <Col md={6}>
              <Container>
                <Row>
                  <Col style={{textAlign: 'center'}}>
                    <HeaderAvatar user={this.state.user} />
                  </Col>
                </Row>
                <Row className={'mt-4'}>
                  <Col style={{textAlign: 'center'}}>
                    <p style={{fontSize: 22}}>{moment(this.state.tourDate).format('M/D/YY')} {this.state.tour.name}</p>
                    <p style={{fontSize: 16, fontWeight: 'bold', lineHeight: 2.5}}>Presented by {this.state.user.detailsToShow && this.state.user.detailsToShow === 'company' ? this.state.user.company : this.state.user.name}</p>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    {this.renderButtons()}
                  </Col>
                </Row>
              </Container>
            </Col>
            <Col md={6}>
              <Row>
                <Container>
                  <Col style={{borderRadius: 5, backgroundColor: colors.white, padding: 15, marginTop: 20}}>
                    {this.renderSelectedPlaces()}
                    <Row style={{marginBottom: 5}}>
                      <div style={{display: 'flex', paddingLeft: 15, paddingRight: 15}}>
                        <div style={{width: 50, paddingRight: 20}}>
                          <div style={{width: 30, height: 30, borderRadius: 15, paddingTop: 8, paddingLeft: 8, textAlign: 'center', justifyContent: 'center', alignItems: 'center', backgroundColor: colors.borders}}>
                            <div style={{width: 14, height: 14, borderRadius: 7, backgroundColor: '#b2b7bd', alignSelf: 'center'}} />
                          </div>
                        </div>
                        <div style={{width: 100, paddingTop: 4, fontWeight: 'bold'}} />
                        <div style={{flex: 1, paddingTop: 4}}>
                          <span>End of itinerary</span>
                        </div>
                      </div>
                    </Row>
                  </Col>
                </Container>
              </Row>
              <Row>
                <Col>
                  <AgentCard user={this.state.user} color={this.state.primaryConsumerColor} />
                  <CompanyCard user={this.state.user} />
                </Col>
              </Row>
            </Col>
          </Row>
          <Footer />
        </Container>
      </div>
    )
  }
}

export default Tours;
