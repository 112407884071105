export const colors = {
  //primary: '#00466E',
  primary: '#000000',
  negative: '#DF5264',
  positive: '#67D266',
  vhappy: '#67D266',
  happy: '#B2D267',
  neutral: '#FFC700',
  sad: '#DF8953',
  vsad: '#DF5264',
  unused: '#E8E8E8',
  question: '#8F8F8F',
  white: '#FFFFFF',
  borders: '#D8D8D8',
  apptOrange: '#F5A623',
  apptGreen: '#00ACA2',
};
