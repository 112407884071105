import * as actionConstants from '../actionConstants';
import axios from 'axios';
import { getE164 } from "../tools";
import {FUNCTIONS_URL} from "../environmentVariables";

/**
 * Load all users by organization
 *
 * @returns {function(*, *)}
 */
export const generateIncomingSMS = (phoneNumber, textCode, iso2) => (dispatch) => {
  dispatch({ type: actionConstants.GENERATING_SMS });
  phoneNumber = getE164(phoneNumber.replace(/[^0-9]+/g, ''), iso2);
  let hostName = FUNCTIONS_URL + '/incomingSMS';
  axios.get(hostName + '?sender=' + phoneNumber.replace(/[^0-9]+/g, '') + '&message=' + textCode + '&reg=true')
    .then(response => {
    // handle success
      dispatch({ type: actionConstants.GENERATED_SMS });
    })
    .catch(error => {
      dispatch({ type: actionConstants.GENERATED_SMS });
    })
};

/**
 * Load all users by organization
 *
 * @returns {function(*, *)}
 */
export const generateIncomingSMSWakeUp = () => (dispatch) => {
  let hostName = FUNCTIONS_URL + '/incomingSMS';
  axios.get(hostName + '?keepAlive=true')
    .then(response => console.log(response))
    .catch(error => console.log(error))
};
