// @flow weak
import * as actionConstants from '../actionConstants';

// Reducer
const DEFAULT_STATE = {
  properties: [],
  loadingProperties: false,
  loadedProperties: false,
  teamProperties: [],
  loadingTeamProperties: false,
  loadedTeamProperties: false,
  viewings: [],
  loadingViewings: false,
  loadedViewings: false,
  addingViewingRequest: false,
  addedViewingRequest: false,
  teamName: null,
  companyName: null,
  addingQualificationFeedback: false,
  addedQualificationFeedback: false,
  qualificationFeedback: null,
  gettingMedia: false,
  gotMedia: false,
  media: [],
};

export default function (state = DEFAULT_STATE, action) {
  switch (action.type) {
    case actionConstants.LOADING_PROPERTIES_BY_USER:
      return {
        ...state, loadingProperties: true, loadedProperties: false
      };
    case actionConstants.LOADED_PROPERTIES_BY_USER:
      return {
        ...state, loadingProperties: false, loadedProperties: true, properties: action.payload
      };
    case actionConstants.LOADING_TEAM_PROPERTIES_BY_USER:
      return {
        ...state, loadingTeamProperties: true, loadedTeamProperties: false
      };
    case actionConstants.LOADED_TEAM_PROPERTIES_BY_USER:
      return {
        ...state, loadingTeamProperties: false, loadedTeamProperties: true, teamProperties: action.payload
      };
    case actionConstants.LOADING_VIEWINGS_BY_PROPERTY:
      return {
        ...state, loadingViewings: true, loadedViewings: false
      };
    case actionConstants.LOADED_VIEWINGS_BY_PROPERTY:
      return {
        ...state, loadingViewings: false, loadedViewings: true, viewings: action.payload
      };
    case actionConstants.ADDING_VIEWING_REQUEST:
      return {
        ...state, addingViewingRequest: true, addedViewingRequest: false
      };
    case actionConstants.ADDED_VIEWING_REQUEST:
      return {
        ...state, addingViewingRequest: false, addedViewingRequest: true
      };
    case actionConstants.UPDATE_TEAM_AND_COMPANY:
      return {
        ...state, teamName: action.payload.teamName, companyName: action.payload.companyName
      };
    case actionConstants.ADDING_QUALIFICATION_FEEDBACK:
      return {
        ...state, addingQualificationFeedback: true, addedQualificationFeedback: false,
      };
    case actionConstants.ADDED_QUALIFICATION_FEEDBACK:
      return {
        ...state, addingQualificationFeedback: false, addedQualificationFeedback: true, qualificationFeedback: action.qualificationFeedback,
      };
    case actionConstants.GETTING_PROPERTY_MEDIA:
      return {
        ...state, gettingMedia: true, gotMedia: false, media: [],
      };
    case actionConstants.GOT_PROPERTY_MEDIA:
      return {
        ...state, gettingMedia: false, gotMedia: true, media: action.payload,
      };
    default:
      return state;
  }
}

// Selectors (mapStateToP rops)
export const getProperty = ({
  properties, loadingProperties, loadedProperties, teamProperties, loadingTeamProperties, loadedTeamProperties, viewings, loadingViewings, loadedViewings, addingViewingRequest, addedViewingRequest, teamName, companyName, addingQualificationFeedback, addedQualificationFeedback, qualificationFeedback, gettingMedia, gotMedia, media,
}) => {
  let concatenatedProperties = properties;
  if (teamProperties) {
    teamProperties.forEach(teamProperty => {
      let indivProperties = teamProperty.properties;
      indivProperties = indivProperties.map(indivProperty => ({
        user: teamProperty.id,
        ...indivProperty,
      }));
      concatenatedProperties = concatenatedProperties.concat(indivProperties);
    });
  }

  return {
    properties: concatenatedProperties,
    loadingProperties,
    loadedProperties,
    loadingTeamProperties,
    loadedTeamProperties,
    viewings,
    loadingViewings,
    loadedViewings,
    addingViewingRequest,
    addedViewingRequest,
    teamName,
    companyName,
    addingQualificationFeedback,
    addedQualificationFeedback,
    qualificationFeedback,
    gettingMedia,
    gotMedia,
    media,
  }
};
