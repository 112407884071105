import firebase from 'firebase';
import * as actionConstants from '../actionConstants';
import {getE164} from "../tools";

/**
 * Load all users by organization
 *
 * @returns {function(*, *)}
 */
export const getFeedback = (id) => (dispatch) => {
  dispatch({ type: actionConstants.LOADING_FEEDBACK });
  firebase.firestore().collection('sentFeedback').where('feedback', '==', id).limit(1).get()
    .then((querySnapshot) => {
      if (!querySnapshot.empty) {
        let doc = querySnapshot.docs[0];
        const userId = doc.data().sender;
        firebase.firestore().collection('users').doc(userId).collection('feedback').doc(id).get()
          .then((doc) => {
            let feedbackData = {id: doc.id, ...doc.data()};
            if (!feedbackData.feedbackForm) {
              firebase.firestore()
                .collection('users')
                .doc(userId)
                .collection('contacts')
                .where('phoneNumber', '==', feedbackData.phoneNumber)
                .limit(1)
                .get()
                .then(async(querySnapshot) => {
                  if (querySnapshot.empty) {
                    let iso2 = feedbackData.phoneNumber.indexOf('+44') !== -1 ? 'gb' : 'us';
                    let contactData = {
                      phoneNumber: feedbackData.phoneNumber,
                      contactType: 'lead',
                      origin: feedbackData.propertyAddress,
                      feedbackSent: 0,
                      iso2: iso2,
                      new: true,
                      createdAt: Date.now(),
                      updatedAt: Date.now(),
                    };
                    await firebase.firestore()
                      .collection('users')
                      .doc(userId)
                      .collection('contacts')
                      .add(contactData)
                      .then(doc => {
                        feedbackData.contact = doc.id
                      })
                  } else {
                    let doc = querySnapshot.docs[0];
                    feedbackData.contact = doc.id;
                    feedbackData.contactName = doc.data().name;
                    feedbackData.contactEmail = doc.data().email;
                  }
                  firebase.firestore()
                    .collection('users')
                    .doc(userId)
                    .collection('properties')
                    .doc(doc.data().property)
                    .get()
                    .then((doc) => {
                      const feedbackFormId = doc.data().defaultOpenhouseForm;
                      firebase.firestore()
                        .collection('users')
                        .doc(userId)
                        .collection('feedbackForms')
                        .doc(feedbackFormId)
                        .get()
                        .then((doc) => {
                          let feedback = doc.data();
                          feedbackData.feedbackForm = feedbackFormId;
                          feedbackData.feedbackFormName = feedback.name;
                          feedbackData.questions = feedback.questions;
                          feedbackData.questionCount = feedback.questionCount;
                          feedbackData.askBorrowingReferral = feedback.borrowingReferral || null;

                          const promises = [];
                          promises.push(firebase.firestore()
                            .collection('users')
                            .doc(userId)
                            .collection('feedback')
                            .doc(id)
                            .update({
                              contact: feedbackData.contact,
                              contactName: feedbackData.contactName || null,
                              contactEmail: feedbackData.contactEmail || null,
                              feedbackForm: feedbackFormId,
                              feedbackFormName: feedback.name,
                              questions: feedback.questions,
                              questionCount: feedback.questionCount,
                              askBorrowingReferral: feedback.borrowingReferral || null,
                            }));
                          promises.push(firebase.firestore()
                            .collection('users')
                            .doc(userId)
                            .collection('properties')
                            .doc(feedbackData.property)
                            .update({
                              feedbackSent: firebase.firestore.FieldValue.increment(1),
                              updatedAt: Date.now(),
                            }));
                          promises.push(firebase.firestore()
                            .collection('users')
                            .doc(userId)
                            .collection('feedbackForms')
                            .doc(feedbackFormId)
                            .update({
                              feedbackSent: firebase.firestore.FieldValue.increment(1),
                            }));
                          Promise.all(promises)
                            .then(() => {
                              dispatch({type: actionConstants.LOADED_FEEDBACK, payload: feedbackData});
                            })
                            .catch((error) => { dispatch({ type: actionConstants.LOADING_FEEDBACK_FAILED, payload: error })});
                        }).catch((error) => { dispatch({ type: actionConstants.LOADING_FEEDBACK_FAILED, payload: error })})
                    }).catch((error) => { dispatch({ type: actionConstants.LOADING_FEEDBACK_FAILED, payload: error })})
                })

            } else {
              firebase.firestore().collection('users').doc(userId).get()
                .then(doc => {
                  feedbackData.senderAvatar = doc.data().avatar;
                  dispatch({ type: actionConstants.LOADED_FEEDBACK, payload: feedbackData });
                });
            }
          }).catch((error) => { dispatch({ type: actionConstants.LOADING_FEEDBACK_FAILED, payload: error })});
      } else {
        dispatch({ type: actionConstants.LOADING_FEEDBACK_FAILED })
      }
    }).catch((error) => { dispatch({ type: actionConstants.LOADING_FEEDBACK_FAILED, payload: error })});
};

export const getFeedbackOnly = (id) => (dispatch) => {
  dispatch({ type: actionConstants.LOADING_FEEDBACK });
  firebase.firestore().collection('sentFeedback').where('feedback', '==', id).limit(1).get()
    .then((querySnapshot) => {
      if (!querySnapshot.empty) {
        let doc = querySnapshot.docs[0];
        const userId = doc.data().sender;
        firebase.firestore().collection('users').doc(userId).collection('feedback').doc(id).get()
          .then((doc) => {
            let feedbackData = {id: doc.id, ...doc.data()};
            firebase.firestore().collection('users').doc(userId).get()
              .then(doc => {
                feedbackData.senderAvatar = doc.data().avatar;
                dispatch({ type: actionConstants.LOADED_FEEDBACK, payload: feedbackData });
              });
          }).catch((error) => { dispatch({ type: actionConstants.LOADING_FEEDBACK_FAILED, payload: error })});
      } else {
        dispatch({ type: actionConstants.LOADING_FEEDBACK_FAILED })
      }
    }).catch((error) => { dispatch({ type: actionConstants.LOADING_FEEDBACK_FAILED, payload: error })});
};

/**
 * Get latest feedback id by phone number
 *
 * @param phoneNumber
 * @param iso2
 * @param user
 * @returns {Function}
 */
export const getLatestFeedback = (phoneNumber, iso2, user) => dispatch => {
  phoneNumber = getE164(phoneNumber.replace(/[^0-9]+/g, ''), iso2);
  firebase.firestore()
    .collection('users')
    .doc(user)
    .collection('feedback')
    .where('phoneNumber', '==', phoneNumber)
    .orderBy('sentOn', 'desc')
    .limit(1)
    .get()
    .then(querySnapshot => {
      if (!querySnapshot.empty) {
        let doc = querySnapshot.docs[0];
        let latestFeedbackId = doc.id;
        dispatch({ type: actionConstants.GET_LATEST_FEEDBACK_ID, payload: latestFeedbackId});
      }
    })
};

export const getFeedbackProperty = (userId, propertyId) => dispatch => {
  firebase.firestore()
    .collection('users')
    .doc(userId)
    .collection('properties')
    .doc(propertyId)
    .get()
    .then(doc => {
      dispatch({ type: actionConstants.GET_FEEDBACK_PROPERTY, payload: {id: doc.id, ...doc.data()}});
    })
};

/**
 * Update the initial feedback, contact and property once first feedback question is filled in
 *
 * @param data
 * @param id
 * @returns {Function}
 */
export const updateFeedback = (data, id) => (dispatch) => {
  let senderId = data.feedback.sender;
  let contactId = data.feedback.contact;
  let propertyId = data.feedback.property;
  let feedbackFormId = data.feedback.feedbackForm;
  let borrowingReferral = data.borrowingReferral;
  let email;
  if (data.answers && data.answers.length > 0) {
    data.answers.forEach(answer => {
      if (typeof answer === 'string' && answer.indexOf('@') !== -1) {
        let regex = /\S+[a-z0-9]@[a-z0-9.]+/img;
        let result = answer.match(regex);
        if (result.length > 0) {
          email = result[0];
        }
      }
    })
  }
  let contactUpdateDetails = {
    updatedAt: Date.now(),
  };
  if (email) {
    contactUpdateDetails['email'] = email;
  }
  Promise.all([
    firebase.firestore().collection('users').doc(senderId).collection('feedback').doc(id)
      .update({
        answers: data.answers,
        receivedOn: Date.now(),
        rating: data.avgRating || 0,
        borrowingReferral: borrowingReferral,
        phoneNumber: data.phoneNumber || null,
      }),
    firebase.firestore().collection('users').doc(data.feedback.contactUser).collection('contacts').doc(contactId)
      .update(contactUpdateDetails),
    firebase.firestore().collection('users').doc(data.feedback.propertyUser).collection('properties').doc(propertyId)
      .update({
        updatedAt: Date.now(),
        viewed: false,
      }),
    firebase.firestore().collection('users').doc(data.feedback.feedbackFormUser).collection('feedbackForms').doc(feedbackFormId)
      .update({
        feedbackReceived: firebase.firestore.FieldValue.increment(1),
      })
  ]).then(() => {
    dispatch(shareFeedback({property: propertyId, sender: data.feedback.propertyUser, feedback: id}));
    if (borrowingReferral) {
      dispatch(referralFeedback(senderId, data.feedback));
    }
  }).finally(() => {
      firebase.firestore().collection('smsQueue').where('feedbackId', '==', id)
        .get()
        .then(querySnapshot => {
          let promises = [];
          querySnapshot.forEach(doc => {
            promises.push(doc.ref.delete());
          });
          Promise.all(promises).then(() => true);
        })
    })
};

/**
 * Update contact name
 *
 * @param feedback
 * @param id
 * @param name
 * @returns {Function}
 */
export const updateFeedbackName = (feedback, id, name) => (dispatch) => {
  let senderId = feedback.sender;
  let contactUser = feedback.contactUser;
  let contactId = feedback.contact;
  let promises = [];
  promises.push(firebase.firestore().collection('users').doc(senderId).collection('feedback').doc(id).update({ contactName: name}));
  promises.push(firebase.firestore().collection('users').doc(contactUser).collection('contacts').doc(contactId).update({ name: name}));
  Promise.all(promises).then(() => {})
};

/**
 * Update the rest of the feedback
 *
 * @param data
 * @param id
 * @returns {Function}
 */
export const updateSubsequentFeedback = (data, id) => (dispatch) => {
  let senderId = data.feedback.sender;
  let contactId = data.feedback.contact;
  let propertyId = data.feedback.property;
  let borrowingReferral = data.borrowingReferral;
  let email;
  if (data.answers && data.answers.length > 0) {
    data.answers.forEach(answer => {
      if (typeof answer === 'string' && answer.indexOf('@') !== -1) {
        let regex = /\S+[a-z0-9]@[a-z0-9.]+/img;
        let result = answer.match(regex);
        if (result.length > 0) {
          email = result[0];
        }
      }
    })
  }
  let contactUpdateDetails = {
    updatedAt: Date.now(),
  };
  if (email) {
    contactUpdateDetails['email'] = email;
  }
  Promise.all([
    firebase.firestore().collection('users').doc(senderId).collection('feedback').doc(id)
      .update({
        answers: data.answers,
        rating: data.avgRating || 0,
        borrowingReferral: borrowingReferral,
      }),
    firebase.firestore().collection('users').doc(data.feedback.contactUser).collection('contacts').doc(contactId)
      .update(contactUpdateDetails),
    firebase.firestore().collection('users').doc(data.feedback.propertyUser).collection('properties').doc(propertyId)
      .update({
        updatedAt: Date.now(),
        viewed: false,
      }),
  ]).then(() => {
    if (borrowingReferral) {
      dispatch(referralFeedback(senderId, data.feedback));
    }
  }).finally(() => {
    firebase.firestore().collection('smsQueue').where('feedbackId', '==', id)
      .get()
      .then(querySnapshot => {
        let promises = [];
        querySnapshot.forEach(doc => {
          promises.push(doc.ref.delete());
        });
        Promise.all(promises).then(() => true);
      })
  })
};

/**
 * Share feedback with default contact
 *
 * @param data
 * @returns {Function}
 */
export const shareFeedback = (data) => {
  return dispatch => {
    firebase
      .firestore()
      .collection('users')
      .doc(data.sender)
      .collection('properties')
      .doc(data.property)
      .get()
      .then(doc => {
        if (doc.data().defaultContact && doc.data().autoShare) {
          firebase
            .firestore()
            .collection('shareFeedback')
            .add({
              feedback: data.feedback,
              sender: data.sender,
              phoneNumber: doc.data().defaultContact.phoneNumber,
              propertyAddress: doc.data().address,
            })
        }
      })
  }
};

/**
 * Setup referral feedback
 *
 * @param senderId
 * @param data
 * @returns {Function}
 */
export const referralFeedback = (senderId, data) => {
  return dispatch => {
    firebase
      .firestore()
      .collection('users')
      .doc(senderId)
      .get()
      .then(doc => {
        if (doc.data() && doc.data().referralEmail) {
          firebase
            .firestore()
            .collection('referFeedback')
            .add({
              to: doc.data().referralEmail || null,
              name: data.contactName,
              phoneNumber: data.phoneNumber,
              email: data.contactEmail || null,
              propertyAddress: data.propertyAddress,
              teamName: data.teamName || null,
              companyName: doc.data().company || null,
              senderName: doc.data().name || null,
              senderEmail: doc.data().email || null,
            })
        }
      })
  }
};

/**
 * Get forms by given user
 *
 * @param userId
 * @returns {function(*, *): Promise<FirebaseFirestoreTypes.QuerySnapshot>}
 */
export const getFeedbackFormsByUser = userId => {
  return (dispatch, getState) => {
    return firebase
      .firestore()
      .collection('users')
      .doc(userId)
      .collection('feedbackForms')
      .orderBy('createdAt', 'asc')
      .get()
      .then(querySnapshot => {
        let feedbackForms = [];
        querySnapshot.forEach(doc => {
          feedbackForms.push({
            id: doc.id,
            user: userId,
            ...doc.data(),
          });
        });
        dispatch({
          type: actionConstants.GOT_FEEDBACK_FORMS_BY_USER,
          payload: feedbackForms,
        });
      })
      .catch(error => {
        console.log(error);
      });
  };
};
