// @flow weak
import * as actionConstants from '../actionConstants';

// Reducer
const DEFAULT_STATE = {
  loadingSheet: false,
  loadedSheet: false,
  sheet: {},
  generatingSMS: false,
  generatedSMS: false,
};

export default function (state = DEFAULT_STATE, action) {
  switch (action.type) {
    case actionConstants.LOADING_SHEET:
      return {
        ...state, loadingSheet: true, loadedSheet: false
      };
    case actionConstants.LOADED_SHEET:
      return {
        ...state, loadingSheet: false, loadedSheet: true, sheet: action.payload
      };
    case actionConstants.GENERATING_SMS:
      return {
        ...state, generatedSMS: false, generatingSMS: true
      };
    case actionConstants.GENERATED_SMS:
      return {
        ...state, generatedSMS: true, generatingSMS: false
      };
    default:
      return state;
  }
}

// Selectors (mapStateToP rops)
export const getSheet = ({
  loadingSheet, loadedSheet, sheet, generatingSMS, generatedSMS,
}) => ({
  loadingSheet, loadedSheet, sheet, generatingSMS, generatedSMS,
});
