// @flow weak
import * as actionConstants from '../actionConstants';

// Reducer
const DEFAULT_STATE = {
  loadingFeedback: false,
  loadedFeedback: false,
  loadingFeedbackFailed: false,
  feedback: {},
  updatingFeedback: false,
  updatedFeedback: false,
  latestFeedbackId: null,
  property: {},
};

export default function (state = DEFAULT_STATE, action) {
  switch (action.type) {
    case actionConstants.LOADING_FEEDBACK:
      return {
        ...state, loadingFeedback: true, loadedFeedback: false, loadingFeedbackFailed: false
      };
    case actionConstants.LOADED_FEEDBACK:
      return {
        ...state, loadingFeedback: false, loadedFeedback: true, loadingFeedbackFailed: false, feedback: action.payload
      };
    case actionConstants.LOADING_FEEDBACK_FAILED:
      return {
        ...state, loadingFeedback: false, loadedFeedback: false, loadingFeedbackFailed: true
      };
    case actionConstants.UPDATING_FEEDBACK:
      return {
        ...state, updatingFeedback: true, updatedFeedback: false
      };
    case actionConstants.UPDATED_FEEDBACK:
      return {
        ...state, updatingFeedback: false, updatedFeedback: true
      };
    case actionConstants.GET_LATEST_FEEDBACK_ID:
      return {
        ...state, latestFeedbackId: action.payload
      };
    case actionConstants.GET_FEEDBACK_PROPERTY:
      return {
        ...state, property: action.payload
      };
    default:
      return state;
  }
}

// Selectors (mapStateToP rops)
export const getFeedback = ({
  loadingFeedback, loadedFeedback, loadingFeedbackFailed, feedback, updatingFeedback, updatedFeedback, latestFeedbackId, property,
}) => ({
  loadingFeedback, loadedFeedback, loadingFeedbackFailed, feedback, updatingFeedback, updatedFeedback, latestFeedbackId, property,
});
