import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import OwnerPortal from './OwnerPortal';
import { ActionCreators } from '../../actions/index';
import { getPortal } from '../../reducers/rootReducer';

const mapStateToProps = (state) => ({
  ...getPortal(state),
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators(ActionCreators, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(OwnerPortal);
